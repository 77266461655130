import {useUser} from "../../selector";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {navigate} from "@reach/router";
import HeaderBar from "../../components/HeaderBar";
import {showSuccessSnackbar} from "../../actions/ui";
import {Redirect} from "@reach/router";
import {useFetchProfile} from "../../actions";
import {updateContact} from "../../services/userService";
import {hasError} from "../../services/validators";
import {updateCatalog} from "../../services/catalogService";
import Auth from "./Auth";
import ajaxClient from "../../services/ajaxClient";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function MenuCatalog() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const fetchProfile = useFetchProfile();
    const user = useUser();

    React.useEffect(() => {
        fetchProfile();
    }, []);

    React.useEffect(() => {
        if (user && user.catalog) {
            setFormValues(user.catalog);
        }
    }, [user]);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        updateCatalog(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
            }

            setIsFormProcessed(false);
        });
    }

    return (
        <div>
            <HeaderBar routePath="/profile/phone" titleBar=""/>
            <Container component="main" maxWidth="xs">
                <Auth>
                    <CssBaseline/>
                    <div className={classes.paper}>
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <h1>Ustawienia restauracji</h1>

                            <p>tutaj pola name, description ( jako textrea ), checkBox aktywna, pobranie zdjęcia</p>

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="name"
                                label="Nazwa"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                value={formValues.name}
                                onChange={handleInputChange}
                                error={hasError('name', errors)}
                                helperText={
                                    hasError('name', errors) ? errors.name[0] : null
                                }
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="description"
                                label="Opis"
                                name="description"
                                autoComplete="description"
                                autoFocus
                                value={formValues.description}
                                onChange={handleInputChange}
                                error={hasError('description', errors)}
                                helperText={
                                    hasError('description', errors) ? errors.description[0] : null
                                }
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={isFormProcessed}
                            >
                                {!isFormProcessed ? <Box>Zapisz</Box> : <Box>Wysyłanie...</Box>}
                            </Button>

                        </form>
                    </div>
                </Auth>
            </Container>
        </div>
    );
}

export default MenuCatalog;
