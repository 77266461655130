import React from 'react';
import AddMenuItemDialog from "../Profile/Dialogs/AddMenuItemDialog";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Button from "@material-ui/core/es/Button/Button";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import BasicDateRangePicker from "../BasicDateRangePicker";
import {makeStyles} from "@material-ui/core/styles/index";
import Price from "../Price";
import BoxDietDatesCost from "../BoxDietDatesCost";


const useStyles = makeStyles((theme) => ({
    root: {

    },
    fullButtons: {
        display: 'flex',
        justifyContent: 'space-between',

        '& > *': {
            minWidth: 180,

            [theme.breakpoints.down('md')]: {
                minWidth: 140,
                width: 140,
            },
        },
    },

    calendarContent: {
        display: 'flex',
        width: '90%'

    },

    dateSelectError: {

    }
}));


function BoxDietDatesSelectDialog({isOpen, onDialogClosed, onDatesRangeSelected, currentDatesRange, daysOff, unitPrice}){


    const classes = useStyles();

    const [dateStart, setDateStart] = React.useState(null);

    const [dateEnd, setDateEnd] = React.useState(null);

    const [deliveryDates, setDeliveryDates] = React.useState([]);

    const [datesError, setDatesError] = React.useState(false);


    function handleDialogClose(){
        onDialogClosed();
    }


    function onDatesRangeChanged(dateRange, deliveryDays){
        console.log(dateRange);
        setDatesError(false);

        if(dateRange[0] === null){
            setDateStart(null);
        } else {
            setDateStart(dateRange[0]);
        }

        if(dateRange[1] === null){
            setDateEnd(null);
        } else {
            setDateEnd(dateRange[1]);
        }

        setDeliveryDates(deliveryDays);
    }

    function onDatesRangeAccepted(){

        if(validateDatesRange()){
            console.log('BoxDietDatesSelectDialog start: ', dateStart);
            console.log('BoxDietDatesSelectDialog end: ', dateEnd);
            onDatesRangeSelected(dateStart, dateEnd, deliveryDates);
            onDialogClosed();

        } else {
            setDatesError(true);
        }
    }

    function validateDatesRange(){
        return dateStart !== null && dateEnd !== null;
    }



    return (

            <Dialog
                fullScreen={false}
                fullWidth={false}
                maxWidth={'sm'}
                open={isOpen}
                onClose={handleDialogClose}
                aria-labelledby="responsive-dialog-title"
            >

                <DialogContent >

                    {datesError &&
                    <DialogContentText classes={classes.dateSelectError}>
                        Data początkowa i końcowa muszą być wybrane.
                    </DialogContentText>
                    }

                    <BasicDateRangePicker
                        selectedDate = {currentDatesRange}
                        onDatesRangeChanged = {onDatesRangeChanged}
                        daysOff={daysOff}
                    />
                    {/*{dateStart, dateEnd, numDays, numItems, unitPrice}*/}
                    <BoxDietDatesCost dateStart={dateStart} dateEnd={dateEnd}
                                      numDays={ deliveryDates === null || typeof(deliveryDates) === 'undefined'? 0 : deliveryDates.length}
                                      numItems={1} unitPrice={unitPrice}  />
                </DialogContent>
                <DialogActions className={classes.fullButtons}>
                    <Button autoFocus color="secondary" variant="contained" size="large" onClick={handleDialogClose}>
                        Anuluj
                    </Button>
                    <Button onClick={onDatesRangeAccepted} color="primary" variant="contained" autoFocus size="large">
                        Utwórz
                    </Button>
                </DialogActions>
            </Dialog>

    )

}


export default BoxDietDatesSelectDialog;
