import React, { useState } from 'react';

function TermsCheckbox() {
    const [isChecked, setIsChecked] = useState(false);

    function handleCheckboxChange() {
        setIsChecked(!isChecked);
    }
    const checkboxStyle = {
        marginTop:"24px",

    };
    let labelStyle;
    return (
        <div>
            <label htmlFor="termsCheckbox" style={labelStyle}>
                <input
                    type="checkbox"
                    id="termsCheckbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={checkboxStyle}
                />
                &nbsp;*Akceptuję&nbsp;
                <a href="/regulamin" target="_blank" rel="noopener noreferrer" style={{ color: '#2B93F3' }}><b>Regulamin</b></a>
                &nbsp;Teleporady
            </label>
        </div>
    );
}

export default TermsCheckbox;