import React, {useState} from "react";
import {useUser} from "../selector";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AdminInviteDialog from "./Profile/Dialogs/Admin/AdminInviteDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
    },
    button: {
        [theme.breakpoints.down('md')]: {
            width: "100%"
        },
    }
}));


function AdminInvite({catalog}) {
    const user = useUser();
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState({
        'message': {
            open: false,
            data: null
        },
    });

    function handleOpenDialog(name, data = null) {
        setOpenDialog({
            ...openDialog,
            [name]: {
                open: true,
                data: data
            }
        });

        console.log('openAddDialog', name, data);
    }

    function handleCloseDialog(name, result) {
        console.log('handleCloseDialog', name);

        setOpenDialog({
            ...openDialog,
            [name]: {
                ...openDialog[name],
                open: false
            }
        });
    }

    return (
        <>
            <AdminInviteDialog open={openDialog.message.open} data={openDialog.message.data} onClose={() => handleCloseDialog('message')}/>
            <Button color="primary" variant="contained" size="large" onClick={() => handleOpenDialog('message', {"catalog": catalog})} className={classes.button}>
                Wyślij zaproszenie
            </Button>
        </>
    );
}

export default AdminInvite;
