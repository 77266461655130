import Card from "@material-ui/core/Card";
import Price from "../Price";
import React from "react";

function OrderAmount({order}) {

    console.log('[OrderAmount]', order);

    return (
        <div
            css={{
                marginBottom: "1em"
            }}
        >
            <Card>Razem: <Price value={order.price}/></Card>
        </div>
    );
}

export default OrderAmount;
