import {initialState} from "./index";

function orderReducer(state = initialState.order, action) {
    switch (action.type) {
        case "ORDER": {

            console.log('order reducer', {
                ...state,
                [action.payload.id]: action.payload
            });

            return {
                ...state,
                currentOrder: action.payload
            };
        }
        case "ORDER_PAYMENT": {
            return {
                ...state,
                payment: action.payload
            };
        }
        case "ORDER_ERRORS": {
            return {
                ...state,
                errors: action.payload
            };
        }
        case "ORDER_RESET": {
            return {};
        }
        case "FETCH_ORDER": {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export default orderReducer;
