function increaseQuantity(id) {
    return {
        type: "INCREASE_PRODUCT",
        payload: {
            id
        }
    };
}

function decreaseQuantity(id) {
    return {
        type: "DECREASE_PRODUCT",
        payload: {
            id
        }
    };
}

function removeCart(id) {
    return {
        type: "REMOVE_PRODUCT",
        payload: {
            id
        }
    };
}

function resetCart() {
    return { type: "RESET_CART" };
}

function addCart(product) {
    console.log('adding product: ',  product);
    return {
        type: "ADD_PRODUCT",
        payload: {
            ...product,
            quantity: 1
        }
    };
}


function addCartBoxDiet(product, datesRange, deliveryDates) {
    return {
        type: "ADD_PRODUCT",
        payload: {
            ...product,
            quantity: 1,
            datesRange: datesRange,
            deliveryDates: deliveryDates
        }
    };
}

function modifyCardBoxDietDatesRange(orderItemId, datesRange, deliveryDates){
    console.log('mod cart item id: ' + orderItemId);
    return {
        type: "PRODUCT_DATES_RANGE",
        orderItemId: orderItemId,
        payload: {
            datesRange: datesRange,
            deliveryDates: deliveryDates
        }
    }


}

export {
    increaseQuantity,
    decreaseQuantity,
    resetCart,
    addCart,
    addCartBoxDiet,
    modifyCardBoxDietDatesRange,
    removeCart
};
