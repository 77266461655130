import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AvatarLetter from "./AvatarLetter";
import RatingInfo from "./RatingInfo/RatingInfo";
import moment from "moment";
import 'moment/locale/pl'
import {COLORS, DATE_FRIENDLY_FORMAT, FONTS} from "../Theme";

const useStyles = makeStyles({
    details: {
        borderRadius: 6,
        border: '1px solid #dee2e6!important',
        boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)!important',
        width: '100%',
        display: 'flex'
    },
    user_name: {
        color: COLORS.secondary,
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "150%",
        marginLeft: 6
    },
    rating: {
        marginBottom: 12
    }
});

function CommentItem({comment}) {
    const classes = useStyles();

    return (
        <Box className={classes.catalog_url} mb={4}>
            <Grid container className={classes.details}>
                <Grid xs={12}>
                    <Box p={3} display={"flex"} alignItems={"center"}>
                        <AvatarLetter name={comment.user.name}/>
                        &nbsp;
                        <Typography component="p" variant="body1" className={classes.user_name}>
                            {comment.user.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid xs={12}>
                    <Box p={3} pt={0}>
                        <Typography variant="subtitle2" color="textSecondary" className={classes.rating}>
                            {moment(comment.created_at).format(DATE_FRIENDLY_FORMAT)}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary" className={classes.rating}>
                            <Box display={"flex"} alignItems={"center"}>
                                <b>Ocena:</b>&nbsp;<RatingInfo rating={comment.rating} numVotes={-1}/>
                            </Box>
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {comment.body}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CommentItem;
