import {initialState} from "./index";


export function addCatalogIdReducer(state = initialState.catalogId, action) {
    switch (action.type) {
        case "ADD_RESTAURANT_ID": {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function catalogsReducer(state = initialState.catalogs, action = {}) {
    switch (action.type) {
        case "LIST_RESTAURANTS": {
            return action.payload.data;
        }

        case "FETCH_RESTAURANT": {
            return {
                ...state,
                catalog: action.payload
            };
        }

        default: {
            return state;
        }
    }
}