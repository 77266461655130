import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Price from "./Price";
import priceCalc from '../middlewares/priceCalc';

import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";


const useStyles = makeStyles((theme) => ({

}));


function CatalogAddress({catalog}){

    const classes = useStyles();


    return (
        <Grid container className={classes.address}>
            <Grid item xs={6}>
                <Typography variant='subtitle2'>Nazwa firmy:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant='caption'>{catalog.name}</Typography>
            </Grid>

            {catalog.company_taxt_id && (
                <Box>
                    <Grid item xs={6}>
                        <Typography variant='subtitle2'>NIP</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="caption">{catalog.company_tax_id}</Typography>
                    </Grid>
                </Box>
            )}

            <Grid item xs={6}>
                <Typography variant='subtitle2'>Adres:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant='caption'>{catalog.address}</Typography>
            </Grid>
        </Grid>
    );

}


export default CatalogAddress;