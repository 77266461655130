import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import GalleryForm from "./GalleryForm";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    root: {},
    fullButtons: {
        display: 'flex',
        justifyContent: 'space-between',

        '& > *': {
            minWidth: 180,

            [theme.breakpoints.down('md')]: {
                minWidth: 140,
                width: 140,
            },
        },
    },
}));

export default function GalleryDialog({open = false, data = null, onClose, onSuccess}) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    console.log('data', data);

    function onError(e) {
        console.log('images upload form error', e);
    }

    const handleClose = () => {
        onClose();
    };

    const handleError = () => {
    };

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    Galeria
                </DialogTitle>

                <Box my={1}>
                    <Divider variant="fullWidth"/>
                </Box>

                <DialogContent>
                    {/*<DialogContentText>
                        Ustawienia wyświetlania Twojej galerii zdjęć
                    </DialogContentText>*/}
                    <GalleryForm data={data} onError={onError} onSuccess={onSuccess}/>
                </DialogContent>
                <Box my={1}>
                    <Divider variant="fullWidth"/>
                </Box>
                <DialogActions className={classes.fullButtons}>

                    <Button autoFocus onClick={handleClose} color="secondary" variant="contained" size="large">
                        Zamknij
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
