import React from 'react';

export const toNumber = (number, digits = null) => {
    try {
        let tmp = parseFloat(number);

        if (digits) {
            return tmp.toFixed(digits);
        }

        return tmp;
    } catch (error) {
        return 0;
    }
}

export const assetsPath = (src) => {
    return process.env.PUBLIC_URL + "/assets/" + process.env.REACT_APP_SITE + "/" + src;
}
