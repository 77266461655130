export const hasError = (field, errors) => (errors && errors[field]) ? true : false;

export default function getErrors(errors) {

    if (errors && errors.error && Array.isArray(errors.error)) {
        return errors.error.join("<br/>")
    }

    if (errors && errors.error && !Array.isArray(errors.error)) {
        return errors.error;
    }

    return null;
}