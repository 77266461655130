import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Link, navigate} from "@reach/router";
import Box from "@material-ui/core/Box";
import {COLORS, FONTS} from "../../Theme";
import RatingInfo from "../../components/RatingInfo/RatingInfo";
import Divider from "@material-ui/core/Divider";
import CatalogPremiumPhonePrice from "../../components/Catalogs/CatalogPremiumPhonePrice";
import CatalogPremiumPhone from "../../components/Catalogs/CatalogPremiumPhone";
import Button from "@material-ui/core/Button";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 6,
        border: '1px solid #dee2e6!important',
        boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)!important',
        width: '100%',
        display: 'flex',
        position: 'relative',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',

            '& > *': {
                width: '100% !important'


            },
        },
    },
    cardBox: {
        display: 'flex',
        width: '100%'
    },
    catalog_url: {
        textDecoration: 'none',
        color: '#12AC62'
    },
    cardContent: {
        padding: '15px',
    },
    cardMedia: {
        [theme.breakpoints.up('sm')]: {
            width: '170px',
        },
    },
    catalog_name: {
        width:"100%",
        font:+ FONTS.fontFamily,
        letterSpacing: '0.14px',
        color: COLORS.secondary,
        whiteSpace: 'nowrap',
        maxWidth: '150px',
        fontSize:"16px",
        lineHeight:"24px",
        fontWeight:"700",
        marginBottom:"16px"
    },
    catalog_description: {
        font: 'normal normal normal ' + FONTS.fontFamily,
        letterSpacing: '0.14px',
        color: '#757575',
        marginTop: '16px',
        whiteSpace: 'pre-line',
        fontSize:"14px",
        lineHeight:"21px",
        marginBottom:"8px"
    },
    catalog_distance: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        opacity: 0.8,
        padding: 3,
        backgroundColor: COLORS.primary,
        font: 'normal normal normal 11px/12px ' + FONTS.fontFamily,
        letterSpacing: '0.14px',
        color: 'white',
        marginTop: '9px',
        whiteSpace: 'pre-line'
    },
    placeIcon :{
        color:COLORS.primary,
        paddingRigth:"8px",
    },
    catalog_city:{
        fontSize:"14px",
        fontWeight:"600",
    },
    button:{
        width:"100%",
        marginTop:"16px",
        border:"2px solid !important",
        fontSize:"16px"
    }



}));

export default function MessageCatalog({catalog}) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <Box className={classes.cardBox}>
                <CardContent className={classes.cardContent}>
                    <Box display={"flex"} justifyContent={"space-between"} marginBottom={"24px"}>
                       <Box display={"flex"} justifyContent={"space-between"}>
                        <PlaceOutlinedIcon fontSize={"small"} className={classes.placeIcon}/>
                        <Typography variant="body2" color="textSecondary" component="p"
                                    className={classes.catalog_city}>&nbsp;{catalog?.city?.name}
                        </Typography>
                       </Box>
                        <Typography variant="body2" color="textSecondary" component="p"
                                    className={classes.catalog_rating}>
                            <RatingInfo rating={catalog.avg_rating} numVotes={catalog.num_rating_votes} max={1} />
                        </Typography>
                    </Box>

                    <Link to={`${catalog.url}`} className={classes.catalog_url}>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.catalog_name}>
                            {catalog.name}
                        </Typography>
                    </Link>

                    {classes.catalog_description && (
                        <>
                            <Divider/>

                            <Typography gutterBottom variant="h5" component="h2" className={classes.catalog_description}>
                                {catalog.description}
                            </Typography>
                        </>
                    )}

                    {catalog.company_phone && (
                        <Box pt={1} pb={1}>
                            <Divider className={classes.divider}/>
                            <Button color="primary" variant="outlined" size="large" className={classes.button}
                                    onClick={() => navigate("tel:" + catalog.company_phone)}>
                                Zadzwoń &nbsp;<strong>{catalog.company_phone}</strong>
                            </Button>
                        </Box>
                    )}

                </CardContent>
            </Box>
        </Card>
    );
}
