import React, {useState} from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import Price from "../Price";
import Button from "@material-ui/core/Button";
import AddMenuItemDialog from "./Dialogs/AddMenuItemDialog";
import IconButton from "@material-ui/core/IconButton";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {getJoinedProducts} from "../../services/orderService";

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 300,
        fontSize: 14
    }
}));

function OrderItemProducts({items}) {
    const classes = useStyles();

    return (
        <span className={classes.root}>
            {getJoinedProducts(items)}

            {/*2 x Ser, 3 x Burger, 5 x Woda, 1 x Pepsi, 1 x Rollo Mega - pszenna*/}
        </span>
    )
}

export default OrderItemProducts;
