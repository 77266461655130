import { createTheme } from '@material-ui/core/styles';
import red from "@material-ui/core/colors/red";

const defaultTheme = createTheme();

const REACT_APP_SITE = process.env.REACT_APP_SITE;

let jakieozeColors = {
    default: '#757575',
    primary: '#03A600',
    secondary: '#06106D',
    neutral: '#06106D',
    primaryLight: '#03A600',
}

let paramedycyColors = {
    default: '#667085',
    primary: '#008115',
    secondary: '#101828',
    neutral: '#F9FAFB',
    primaryLight: '#E9F6EB'
}

let jakieozeFonts = {
    fontFamily: 'DM sans',
}

let paramedycyFonts = {
    fontFamily: 'Sora',
}

export const COLORS = REACT_APP_SITE === 'jakieoze' ? jakieozeColors : paramedycyColors;
export const FONTS = REACT_APP_SITE === 'jakieoze' ? jakieozeFonts : paramedycyFonts;

export const APP_SITE = REACT_APP_SITE;
export const DATE_FRIENDLY_FORMAT = "dddd, D MMMM YYYY";

const theme = createTheme({
    typography: {
        fontFamily: [
            FONTS.fontFamily,
            'sans-regular'
        ].join(','),

        subtitle2: {
            color: '#757575',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
        }
    },
    palette: {
        primary: {
            main: COLORS.primary,
        },
        secondary: {
            main: COLORS.secondary,
            contrastText: 'black',
        },
        disabled: {
            main:'purple[500]',
        },

        default: {
            main: COLORS.default,
            contrastText: 'black',
            border: '1px solid #FA4A0C',
            backgroundColor: '#00B24D1F'
        },
        error: red,
        contrastThreshold: 1,
        tonalOffset: 0.2,
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 8
            },
            contained: {
                color: '#757575',
                backgroundColor: '#F3F3F3',
                border: '1px solid #F3F3F3',
                boxShadow: 'unset',
                padding: '5px 15px',
                textTransform: 'none',
                borderRadius: 6,
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                },
                '&:disabled': {
                    backgroundColor: '#FA4A0C',
                    border: '1px solid #FA4A0C',
                    color: 'white',
                }
            },
            containedPrimary: {
                color: 'white',
                backgroundColor: COLORS.primary,
                border: '1px solid',
                borderColor: COLORS.primary,
                boxShadow: '0px 1px 1px #00000029',
                padding: '5px 15px',
                textTransform: 'none',
                borderRadius: 6,
                '&:hover': {
                    '&:disabled': {
                        backgroundColor: COLORS.default,
                        border: '1px solid ' + COLORS.default,
                        color: 'white',
                    }
                },
                '&:disabled': {
                    backgroundColor: COLORS.default,
                    border: '1px solid' ,
                    color: 'white',
                }
            },

            containedSecondary: {
                color: '#757575',
                backgroundColor: '#F3F3F3',
                border: '1px solid #F3F3F3',
                boxShadow: 'unset',
                padding: '5px 15px',
                textTransform: 'none',
                borderRadius: 6,
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                },
                '&:disabled': {
                    backgroundColor: '#FA4A0C',
                    border: '1px solid #FA4A0C',
                    color: 'white',
                }
            },
            containedDefault: {
                color: COLORS.default,
                backgroundColor:'COLOR.secondary' ,
                border: '1px solid',
                borderColor: COLORS.secondary,
                boxShadow: '0px 1px 1px #00000029',
                padding: '5px 15px',
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                },
                borderRadius: 6
            },
            outlined: {
                color: COLORS.default,
                backgroundColor: 'white',
                border: '1px solid ',
                borderColor: COLORS.default,
                boxShadow: 'unset',
                padding: '5px 15px',
                borderRadius: 6,
                textTransform: "none"
            },
            outlinedPrimary: {
                color: COLORS.primary,
                backgroundColor: 'white',
                border: '1px solid',
                borderColor: COLORS.primary,
                boxShadow: 'unset',
                padding: '5px 15px',
                textTransform: 'none',
                '&:hover': {

                },
                borderRadius: 6
            },
            outlinedSecondary: {
                color: COLORS.secondary,
                backgroundColor: '#EEEEEE',
                border: '1px solid',
                borderColor: COLORS.secondary,
                boxShadow: 'unset',
                padding: '5px 15px',
                textTransform: 'none',
                '&:hover': {

                },
                borderRadius: 6
            },

        },
        MuiDialogTitle: {
            root: {
                paddingTop: '32px',
                '& h2': {
                    color: COLORS.secondary,
                    font: 'normal normal bold 20px/24px ' + FONTS.fontFamily,
                    letterSpacing: '0.19px',
                    textAlign: 'center'
                }
            }
        },
        MuiDialogContent: {
            root: {
                paddingTop: '8px 32px',
                [defaultTheme.breakpoints.up('sm')]: {
                    /*padding: '8px 96px',*/
                },
            }
        },
        MuiDialogActions: {
            root: {
                padding: '8px 32px',
                paddingTop: '24px',
                paddingBottom: '24px',
                [defaultTheme.breakpoints.up('sm')]: {
                    /*padding: '8px 96px',*/
                    paddingBottom: '32px'
                },
                '& > .MuiButton-root': {
                    minWidth: 180,
                    [defaultTheme.breakpoints.down('sm')]: {
                        minWidth: 150,
                    }
                }
            }
        },
        MuiDialogContentText: {
            root: {
                font: 'normal normal bold 16px/24px ' + FONTS.fontFamily,
                fontFamily: FONTS.fontFamily,
                letterSpacing: "0.15px",
                color: COLORS.default,
                marginBottom: 8
            }
        }
    },
    MuiLink:{
        root:{
            font: 'normal normal normal 14px/22px ' + FONTS.fontFamily,
            color: "#03a9f4",
            letterSpacing: "0.13px"
        }

    }
});

export default theme;
