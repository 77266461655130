import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "../../assets/icons/check-24px.svg";
import {APP_SITE, COLORS, FONTS} from "../../Theme";
import Price from "../../components/Price";
import {Power} from "@material-ui/icons";
import moment from "moment";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";


const useStyles = makeStyles((theme) => ({
    service_box: {
        borderRadius: "6px",
        boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.15)",
        display:"inline-flex",
        minWidth:"312px"

    },
    header: {
        textTransform: "uppercase",
        paddingTop:"8px"
    },
    properties: {
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "150%",
        color: COLORS.default
    },

    properties_price: {
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "150%",
        color: COLORS.primary,

    },
    properties_time:{
      color:COLORS.secondary,
      fontWeight:"600",

    },

    separator: {
        width: "100%", height: "0px", border: "1px solid #DDDDDD",
        [theme.breakpoints.down('sm')]: {
            position: "unset",
            width: "100%",
            height: "0px",
            border: "1px solid #DDDDDD",
            marginTop: -12,
            marginBottom: -16


        },
    },
    relative: {
        position: "relative",
        textAlign:"initial",
    },
    title: {
        textAlign: "center",
        fontSize: "16px",
    },
    date: {
        textAlign: "center",
        marginBottom: "16px",
        fontSize: "14px",
        color:COLORS.default,
    },
    box:{
      display:"flex",
      alignItems:"flex-end",
    },
    icon: {
        marginRight: "8px",

    },
    label: {
        fontSize:"16px",
        fontWeight:"600",
        color:COLORS.default,
        paddingBottom:"8px"
    },

}));

function MessageOffer({menuItem, date}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Box p={4} align={"right"}>
            <Typography className={classes.title}>Utworzono zapytanie o usługę</Typography>
            <Typography className={classes.date}>{moment(date).format('D')} {moment(date.created_at).format('MMMM')}</Typography>
            <Box p={2} className={classes.service_box}>
                <Grid container  m={2}>
                    <Grid md={6} xs={6} sm={6}>
                        <Box ml={0} p={2} className={classes.relative}>
                            <Box className={classes.box} paddingBottom={"8px"}>
                                <InfoOutlinedIcon className={classes.icon}/>
                                <Typography component="p" variant="body2" className={classes.header}>
                                    Usługa:
                                </Typography></Box>

                            {menuItem && APP_SITE === 'paramedycy' && (
                                <Typography component="p" variant="body1" className={classes.label}>
                                    {menuItem.name}
                                </Typography>
                            )}

                            {menuItem && APP_SITE === 'jakieoze' && (
                                <Typography component="p" variant="body1" className={classes.label}>
                                    <img src={CheckIcon}/>&nbsp;Moc:&nbsp;<span><Power value={menuItem.power}/></span>
                                </Typography>
                            )}
                            <div className={classes.separator}></div>
                            <Box mb={"-16px"}>
                            <Typography component="p" variant="body2" className={classes.header}>
                                Cena:
                            </Typography>
                            <Typography component="p" variant="body1" className={classes.properties_price}>
                                od&nbsp;<b><Price value={menuItem.price} round={true}/></b><br/>
                                <br/>
                            </Typography>
                        </Box>
                            <div className={classes.separator}></div>

                            <Typography component="p" variant="body2" className={classes.header}>
                                Termin:
                            </Typography>
                            <Typography component="p" variant="body1" className={classes.properties_time}>
                                {moment(date).format('DD')} {moment(date).format('MMM')}, {moment(date).format('HH:mm')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default MessageOffer;
