import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import moment from "moment/moment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {ArrowLeft, ArrowRight} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Link from "@mui/material/Link";
import {COLORS, FONTS} from "../../../../Theme";

const useStyles = makeStyles((theme) => ({
    default: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: COLORS.primaryLight,
        color: COLORS.primary + ' !important',
        fontWeight: 700,
        padding: '8px',
        font: 'normal normal normal 14px/16px ' + FONTS.fontFamily,
        borderRadius: '8px',

        textDecoration: 'none !important',
        letterSpacing: '0.47px',


        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        },

        '& a': {

        }
    },
    active: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: COLORS.primary,
        color: 'white !important',
        fontWeight: 700,
        padding: '8px',
        font: 'normal normal normal 14px/16px ' + FONTS.fontFamily,
        borderRadius: '8px',

        textDecoration: 'none !important',
        letterSpacing: '0.47px',


        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        },

        '& a': {

        }
    }
}));

function CalendarHour({onSuccess, onError, hour = null, date = null, onClick, active}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <div>
            <p>
                {date && (
                    <>
                        <Link className={active ? classes.active : classes.default} onClick={onClick}>
                            {hour}
                        </Link>
                    </>
                )}
            </p>
        </div>
    );
}

export default CalendarHour;
