import {navigate} from "@reach/router";
import {useUser} from "../selector";
import React from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";
import {useDispatch} from "react-redux";
import GeneralError from "../components/GeneralError";
import {userCatalogRegister} from "../services/userService";
import {showSuccessSnackbar} from "../actions/ui";
import {hasError} from "../services/validators";
import HeaderBar from "../components/HeaderBar";
import {Paper} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LoginFooter from "./LoginFooter";
import Footer from "./Footer";
import {assetsPath} from "../services/mainService";
import BoxLayout from "../components/Layouts/BoxLayout";
import {COLORS, FONTS} from "../Theme";
import {LANGS} from "../Langs";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        padding: theme.spacing(4),
        marginBottom: '74.2px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    h1: {
        textAlign: 'center',
        fontFamily: FONTS.fontFamily,
        color: COLORS.secondary,
        fontSize: '28 px',
        fontWeight:'700',
    },
    fb_button: {
        textTransform: "none",
        border: '1px solid #757575',
        boxShadow: 'unset',
        padding: '5px 15px',

        backgroundColor: '#3F5AA9',
        color: '#FFFFFF',
        borderRadius: 6,

        '&:hover': {
            backgroundColor: '#3F5AA9',
        }
    },
    box1: {
        '& a': {
            font: 'normal normal bold 18px/28px ' + FONTS.fontFamily,
            letterSpacing: '0.19px',
            color: COLORS.primary,
        },
        textAlign: 'center'


    },
    t: {
        marginTop: '60px',
        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
        letterSpacing: '0.15px',
        color: '#757575'
    },

    box2: {
        '& a': {
            font: 'normal normal bold 18px/28px ' + FONTS.fontFamily,
            letterSpacing: '0.19px',
            color: COLORS.primary,
        },
        marginTop: '38px',
        textAlign: 'center'


    },
    t2: {
        marginTop: '38px',
        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
        letterSpacing: '0.15px',
        color: '#757575'
    },
    body2: {
        font: 'normal normal normal 18px/28px ' + FONTS.fontFamily,
        letterSpacing: '0.15px',
        color: '#2D2D2D',

    }
}));

function RegisterCatalog() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [repeatPassword, setRepeatPassword] = React.useState("");

    const [errors, setErrors] = React.useState({});

    const user = useUser();

    React.useEffect(() => {
        console.log('user', user);
        if (user.name) {
            navigate(process.env.PUBLIC_URL + "/");
        }
    }, [user]);

    function changeName(e) {
        setName(e.target.value);
    }

    function changeEmail(e) {
        setEmail(e.target.value);
    }

    function changePassword(e) {
        setPassword(e.target.value);
    }

    function changeRepeatPassword(e) {
        setRepeatPassword(e.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();

        userCatalogRegister({name, email, password, repeatPassword}).then(response => {
            let data = response.data;

            if (data && data.error) {
                setErrors(data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
            } else {
                localStorage.session = data.token;
                dispatch({type: "LOGIN", payload: data});
                dispatch(showSuccessSnackbar("Rejestracja przebiegła pomyślnie"));
                //navigate(process.env.PUBLIC_URL + '/profile/confirm-phone');
                navigate(process.env.PUBLIC_URL + '/profile/menu');
            }
        });
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/home"/>
            <div className={classes.page}>
                <BoxLayout>
                    <CssBaseline/>
                    <form onSubmit={handleSubmit}>
                        <div className={classes.h1}>
                            <h1>{LANGS.loginText2}</h1>
                        </div>

                        <GeneralError errors={errors}/>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Imię i nazwisko"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            value={name}
                            onChange={changeName}
                            error={hasError('name', errors)}
                            helperText={
                                hasError('name', errors) ? errors.name[0] : null
                            }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Adres e-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={changeEmail}
                            error={hasError('email', errors)}
                            helperText={
                                hasError('email', errors) ? errors.email[0] : null
                            }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={changePassword}
                            error={hasError('cPassword', errors)}
                            helperText={
                                hasError('cPassword', errors) ? errors.cPassword[0] : null
                            }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="repeatPassword"
                            label="Powtórz hasło"
                            type="password"
                            id="repeatPassword"
                            autoComplete="repeat-password"
                            value={repeatPassword}
                            onChange={changeRepeatPassword}
                        />


                        {/*                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Konto kucharza"
                    />*/}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Zarejestruj się
                        </Button>

                        <Button

                            fullWidth
                            variant="contained"
                            className={classes.fb_button}
                        >
                            Logowanie przez Facebook
                        </Button>

                        <Box className={classes.box1}>
                            <Typography component="h2" className={classes.t}>
                                Nowy użytkownik?
                            </Typography>

                            <Link href="/register" variant={"body2"}>
                                Załóż konto
                            </Link>
                        </Box>


                        <Box className={classes.box2}>
                            <Typography component="h2" className={classes.t2}>
                                Mam już konto
                            </Typography>

                            <Link href="/login" variant={"body2"}>
                                {LANGS.loginText4}
                            </Link>
                        </Box>
                        <LoginFooter/>

                    </form>
                </BoxLayout>
            </div>
            {/*<Footer/>*/}
        </div>

    );
}

export default RegisterCatalog;
