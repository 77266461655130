import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HeaderBar from "../../components/HeaderBar";
import {getProfile} from "../../services/userService";
import {useDispatch} from "react-redux";
import {useParams} from "@reach/router";
import ajaxClient from "../../services/ajaxClient";
import HeaderBreadcrumb from "../../components/HeaderBreadcrumb";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent";
import OrderInformation from "../../components/Controls/OrderInformation";
import OrderItems from "../../components/Order/OrderItems";
import OrderAmount from "../../components/Order/OrderAmount";
import Auth from "./Auth";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    buttons: {
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}));

function OrderTracking(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();

    const [order, setOrder] = useState(null);
    const [errors, setErrors] = useState({});

    const [user, setUser] = useState(null);

    React.useEffect(() => {
        async function fetchOrder() {
            const response = await ajaxClient().get("profile/orders/" + params.id);
            setOrder(response.data.data);
            console.log('order', response.data.data);
        }

        fetchOrder();
    }, []);

    const [openDialog, setOpenDialog] = useState({
        'catalog': {
            open: false,
            data: null
        },
        'addMenuItem': {
            open: false,
            data: null
        },
        'editMenuItem': {
            open: false,
            data: null
        },
        'addMenuCollection': {
            open: false,
            data: null
        },
        'editMenuCollection': {
            open: false,
            data: null
        }
    });

    React.useEffect(() => {

        reloadUser();

    }, []);

    function reloadUser() {
        getProfile().then(response => {
            setUser(response.data);
        })
    }

    return (
        <div>
            <HeaderBar routePath="/profile/tracking" titleBar="Twoje zamówienie"/>

            <Container maxWidth="lg">
                <HeaderBreadcrumb name={"Złóż zamówienie"} url={"/order"}/>

                <Auth>
                    {order.catalog && (
                        <Typography gutterBottom variant="h4">
                            <Link href={`${order.catalog.url}`}
                                  color="default">
                                <ArrowBackIcon />
                            </Link>
                            Podsumowanie zamówienia
                        </Typography>
                    )}

                    {order.catalog && (
                        <Typography gutterBottom variant="h2" component="h2">
                            {order.catalog.name}
                        </Typography>
                    )}

                    {order.catalog && (
                        <Grid
                            container
                            spacing={8}
                            direction="row"
                            alignItems="flex-start"
                        >
                            <Grid item md={8} sm={8} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <CardContent className={classes.catalog__title_section}>

                                            <OrderInformation catalog={order.catalog}/>

                                        </CardContent>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={4} sm={4} xs={12}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h4" align="center">
                                            Twoje zamówienie
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p" align="center">
                                            dopłata do zamówień poniżej 25 zł
                                        </Typography>
                                        <br/>
                                        <OrderItems order={order}/>
                                        <OrderAmount order={order}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    )}

                </Auth>
            </Container>
        </div>
    );
}

export default OrderTracking;
