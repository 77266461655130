import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Price from "../Price";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
    },
    card: {
        borderRadius: 10
    },
    catalog__header: {
        width: '100%',
        height: '40vh',
        maxHeight: '600px',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    catalog__header_image: {
        width: '100%',
        height: 'auto',
    },
    catalog__container: {
        marginTop: '-80px'
    },
    catalog__order: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    catalog__order_button: {
        width: "100%",
        height: "50px",
        borderRadius: 25,
    }
}));

function OrderItems({order}) {

    console.log('[OrderItems]', order);

    return (
        <div>
            {order.items ? (
                <div css={{maxHeight: "50vh", overflowY: "auto"}}>
                    {Object.values(order.items).map(orderItem => {
                        return (
                            <div css={{padding: "10px"}} key={orderItem.id}>
                                <div
                                    css={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingTop: "10px"
                                    }}
                                >
                                    <span css={{fontSize: "1.2em", fontWeight: "bold", textAlign: "left", width: "50px"}}>{orderItem.quantity}x</span>
                                    <span css={{fontSize: "1.2em", fontWeight: "normal", textAlign: "left", flex: 1}}>
                                        {orderItem.menu_item.name}
                                        <p>{orderItem.menu_item.description}</p>
                                    </span>
                                    <span css={{fontSize: "1.1em"}}><Price value={orderItem.price * orderItem.quantity}/></span>
                                </div>
                                <div
                                    css={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingTop: "10px",
                                        borderBottom: "1px solid #ccc"
                                    }}
                                >
                                </div>

                            </div>
                        )
                        /* return <CatalogOrderMenuItem menuItem={menuItem} />;*/
                    })}
                </div>
            ) : (
                <Typography variant="body2" color="textSecondary" component="h1">
                    PUSTE
                </Typography>
            )}
        </div>
    );
}

export default OrderItems;
