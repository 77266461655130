import React from "react";

function Power({value}) {

    return (
        <span>
            {parseFloat(value).toFixed(2)} KW
        </span>
    );
}

export default Power;
