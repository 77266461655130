import React from "react";
import Catalog from "./views/Catalog";
import Catalogs from "./views/Catalogs";
import AdminCatalogs from "./views/Admin/AdminCatalogs";
import {Router} from "@reach/router";
import Login from "./views/Login";
import Register from "./views/Register";
import Record from "./views/Record";
import Order from "./views/Order";
import Home from "./views/Home";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import Delivery from "./views/Profile/Delivery.js";
import Personal from "./views/Profile/Personal.js";
import SuccessSnackbar from "./components/SuccessSnackbar";
import NoSsr from "@material-ui/core/NoSsr";
import {ThemeProvider} from '@material-ui/core/styles';
import Test from "./views/Test";
import Contact from "./views/Profile/Contact";
import MenuEmpty from "./views/Profile/MenuEmpty.js";
import Menu from "./views/Profile/Menu.js";
import MenuCatalog from "./views/Profile/MenuCatalog";
import LandingPage from "./views/LandingPage";

//require('dotenv').config();
import OrderTracking from "./views/Profile/OrderTracking";
import Orders from "./views/Profile/Orders";
import CatalogOrders from "./views/Profile/CatalogOrders";
import theme from "./Theme";
import Page from "./views/Page";
import RegisterCatalog from "./views/RegisterCatalog";
import ConfirmPhoneNumber from "./views/ConfirmPhoneNumber";
import Company from "./views/Profile/Company";
import CatalogCompany from "./views/Profile/CatalogCompany";
import Offers from "./views/Offers";
import Messages from "./views/Profile/Messages";

function App() {
    return (
        <NoSsr>
            <ThemeProvider theme={theme}>
                <div>
                    <SuccessSnackbar/>
                    <Router>
                        <Login path="/login"/>
                        <Register path="/register"/>
                        <RegisterCatalog path="/register-catalog"/>
                        <ForgotPassword path="/forgot-password"/>
                        <ResetPassword path="/reset-password/:token"/>
                        <ConfirmPhoneNumber path="/profile/confirm-phone" />

                        <Catalogs path="/"/>
                        <Catalogs path="/catalogs"/>
                        <Catalogs path="/s/:query"/>
                        <Catalog path="/:country/:city/:slug"/>

                        <Offers path="/offers"/>
                        <Offers path="/so/:query"/>
                        <Offers path="/so/:country/:city/:slug"/>

                        <Record path="/record"/>
                        <Order path="/order"/>

                        <Company path="/profile/company"/>
                        <Delivery path="/profile/delivery"/>
                        <Contact path="/profile/contact"/>

                        <Messages path="/profile/messages"/>
                        <Messages path="/profile/messages/:userId"/>
                        <Personal path="/profile/personal"/>
                        <MenuEmpty path="/profile/menu/empty"/> 
                        <Menu path="/profile/menu"/>
                        <MenuCatalog path="/profile/menu/catalog"/>
                        <Test path="/test"/>

                        <Orders path="/profile/orders"/>
                        <Orders path="/profile/orders/:id"/>
                        <CatalogOrders path="/profile/catalog/orders"/>
                        <CatalogCompany path="/profile/catalog/company"/>

                        <OrderTracking path="/profile/tracking/"/>
                        <OrderTracking path="/profile/tracking/:id"/>

                        <Page path="/warunki-i-postanowienia"/>
                        <Page path="/problem-z-zamowienie"/>
                        <Page path="/kontakt"/>
                        <Page path="/jak-zostac-instalatorem"/>
                        <Page path="/regulamin"/>
                        <Page path="/polityka-prywatnosci"/>
                        <Page path="/jak-korzystac"/>

                        <LandingPage path="/lp1"/>

                        <AdminCatalogs path="/admin/catalogs"/>

                    </Router>
                </div>
            </ThemeProvider>
        </NoSsr>
    );
}

export default App;
