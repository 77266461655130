import React from 'react';
import ImagesList from "./ImagesList";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {removeCatalogFile, storeCatalogFiles} from '../../../../services/catalogFilesService';
import {showSuccessSnackbar} from "../../../../actions/ui";
import {makeStyles} from "@material-ui/core/styles/index";
import {Icon} from "@mui/material";
import {CameraAlt} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {COLORS} from "../../../../Theme";

const useStyles = makeStyles((theme) => ({
    root: {},
    fullFileButtons: {
        '& > .MuiButton-label': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
            '& > b': {
                marginTop: 12,
                fontSize: 16,
                color: COLORS.secondary,
                fontWeight: 400,
            },
            '& > span': {
                marginTop: 6,
                fontSize: '14px',
                fontWeight:'400',
                color: '#6C757D',
            },
        },
        width: '100%',
        padding: 32,
        'margin-bottom': '3rem',
        'background-color': '#E7E7E7',
        'text-align': 'center',
        textTransform: 'unset'
    }
}));

export default function GalleryForm({data, onError, onSuccess}) {
    console.log('data', data);
    const [catalog, setCatalog] = React.useState(data.catalog);
    const [file, setFile] = React.useState([]);
    const hiddenFileInput = React.useRef(null);
    const dispatch = useDispatch();
    const [errors, setErrors] = React.useState({});
    const classes = useStyles();

    React.useEffect(() => {
        setCatalog(data)
    }, [data]);

    function submitForm(files) {
        var data = new FormData();

        for (var i = 0; i < files.length; i++) {
            console.log('file to append', files[i], files[i].name);
            data.append("files[]", files[i], files[i].name)
        }

        storeCatalogFiles(catalog, data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Błąd wgrywania plików."));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess();
            }
        });
    }

    function onFileSelected(f) {
        submitForm(f.target.files);
    }

    function filePickerClick() {
        hiddenFileInput.current.click();
    }

    function onRemoveImage(image) {
        removeCatalogFile(catalog, image).then(response => {
            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Błąd podczas usuwania plików."));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess();
            }
        });
    }

    if (!catalog) {
        return (<></>);
    }

    return (
        <div>
            <form method="post" onSubmit={submitForm}>

                <Button className={classes.fullFileButtons} type="button" onClick={filePickerClick}>
                    <CameraAlt style={{ fontSize: 48 }} />
                    <b>Kliknij, aby dodać zdjęcie</b>
                    <Typography variant={"p"}>Maksymalny rozmiar zdjęć do 1 MB.</Typography>
                </Button>

                <input
                    type="file"
                    ref={hiddenFileInput}
                    disabled={file.length === 5}
                    className="form-control"
                    onChange={onFileSelected}
                    multiple
                    style={{display: 'none'}}
                />

            </form>

            <ImagesList images={catalog.catalog_files} removeImage={onRemoveImage} user={catalog.user}/>
        </div>
    );
}
