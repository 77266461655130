import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HeaderBar from "../../components/HeaderBar";
import {Typography} from "@material-ui/core";
import {getProfile} from "../../services/userService";
import {useDispatch} from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import {getOrders} from "../../services/orderService";
import OrderDialog from "../../components/Profile/Dialogs/OrderDialog";
import Card from "@material-ui/core/Card/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import OrderItem from "../../components/Profile/OrderItem";
import CatalogOrderDialog from "../../components/Profile/Dialogs/CatalogOrderDialog";
import {Redirect, useParams} from "@reach/router";
import ajaxClient from "../../services/ajaxClient";
import Auth from "./Auth";
import {confirmOrder} from "../../services/catalogService";
import {showSuccessSnackbar} from "../../actions/ui";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    cardRoot: {
        display: 'flex',
        marginTop: theme.spacing(2)
    },
    buttons: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 150,
        height: 150
    },


}));

function CatalogOrders() {
    const params = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [orders, setOrders] = useState([]);
    const [user, setUser] = useState(null);

    console.log('[Orders]');

    const [openDialog, setOpenDialog] = useState({
        'order': {
            open: false,
            data: null
        }
    });

    React.useEffect(() => {
        reloadUser();
    }, []);

    function reloadUser() {
        getProfile().then(response => {
            setUser(response.data);
        })

        getOrders().then(response => {
            console.log('[Orders][Data]', response.data);
            setOrders(response.data);
        })
    }

    console.log('param', params);
    console.log('user', user);

    function handleOpenDialog(name, data = null) {
        console.log('[handleOpenDialog]', name, data);
        setOpenDialog({
            ...openDialog,
            [name]: {
                open: true,
                data: data
            }
        });

        console.log('openAddDialog', name, data);
    }

    function handleDialogClose(name, result) {
        console.log('handleDialogClose', name);

        reloadUser();

        setOpenDialog({
            ...openDialog,
            [name]: {
                ...openDialog[name],
                open: false
            }
        });
    }

    const handleConfirm = (order, confirmStatus, comment = null) => {
        confirmOrder(order, confirmStatus, comment).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                dispatch(showSuccessSnackbar(response.data.error.status));
                reloadUser();
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                //reloadUser();
                handleOpenDialog('order', response.data.data);
            }
        });
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/profile/catalog/orders" titleBar="Obługa zamówień"/>
            <Container component="main" >
                <Auth>
                    <CatalogOrderDialog open={openDialog.order.open} data={openDialog.order.data} onClose={() => handleDialogClose('order')} onConfirm={handleConfirm}/>

                    <div className={classes.paper}>

                        {orders && (
                            <div>
                                <Box pt={2} pb={2} mt={3}>

                                    <Typography gutterBottom variant="h5" component="h2" color="secondary">
                                        Moje zamówienia
                                    </Typography>


                                    {orders && orders.map(order => {
                                        return (
                                                <OrderItem order={order} onOpenDialog={handleOpenDialog}/>
                                        )
                                    })}

                                    {orders && orders.length === 0 && (
                                        <Box>Brak aktualnie zamówień złożonych w Twojej kuchnii</Box>
                                    )}
                                </Box>

                            </div>
                        )}
                    </div>
                </Auth>
            </Container>
        </div>
    );
}

export default CatalogOrders;
