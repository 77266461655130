import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Link} from "@reach/router";
import Box from "@material-ui/core/Box";
import './style.css';
import {FONTS} from "../../Theme";

const useStyles = makeStyles({
    card: {
        width: 250,
        maxWidth: 250
    },
    catalog_url: {
        textDecoration: 'none',
        color: '#757575'
    },
    catalog_name: {
        font: 'normal normal bold 14px/18px ' + FONTS.fontFamily,
        letterSpacing: '0.14px',
        color: '#757575',
        marginTop: '5px'

    },
   catalogDescription:{
       font: 'normal normal normal 14px/18px ' + FONTS.fontFamily,
       letterSpacing: '0.14px',
       color: '#757575',
       marginTop: '9px',
       whiteSpace: 'pre-line'
   }
});

export default function CatalogOnMap({catalog}) {
    const classes = useStyles();

    return (
        <Link to={`${catalog.url}`} className={classes.catalog_url}>
            <Box className={classes.card}>
                <CardMedia
                    component="img"
                    alt={catalog.name}
                    height="170"
                    image={catalog.image_filename_url}
                    title={catalog.name}
                />
                <Box p={2}>
                    <Typography gutterBottom variant="h5" component="h2" className={classes.catalog_name}>
                        {catalog.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.catalogDescription}>
                        {catalog.description}
                    </Typography>
                </Box>
            </Box>
            <br/>
        </Link>
    );
}
