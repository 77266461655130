import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import priceCalc from "./middlewares/priceCalc";

function useProductfromCart(id) {
  return useSelector(state => {
    if (state.cart.hasOwnProperty(id)) return state.cart[id];
    return null;
  }, shallowEqual);
}

function useCart() {
  return useSelector(state => {
    return state.cart;
  }, shallowEqual);
}

function useCartCatalogTotal(catalogId) {
  //console.log('[cart]', catalogId);
  return useSelector(state => {
    return Object.values(state.cart).reduce((acc, item) => {
      //console.log('[cart] reduce', catalogId, item.catalog_id);
        console.log('item for restaturant: ', item);
      if (catalogId === item.catalog_id) {
        // return acc + item.price * item.quantity;
          return acc + priceCalc( item.price, item.quantity, typeof (item.deliveryDates) !== 'undefined' && item.deliveryDates.length >= 1 ? item.deliveryDates.length : 1) ;
      }
      return acc;
    }, 0);
  }, shallowEqual);
}

function useCartTotal() {
  return useSelector(state => {
    return Object.values(state.cart).reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0);
  }, shallowEqual);
}

function useUser() {
  return useSelector(state => {
    if (state.user) {
      return state.user.currentUser;
    } else {
      return null;
    }
  }, shallowEqual);
}

function useUserErrors() {
  return useSelector(state => {
    if (state.user && state.user.errors) {
      return state.user.errors;
    } else {
      return null;
    }
  }, shallowEqual);
}

function useCatalogId() {
  return useSelector(state => {
    return state.catalogId;
  }, shallowEqual);
}

function useCatalog() {
  return useSelector(state => {
    if (state.catalogs) {
      return state.catalogs.catalog;
    } else {
      return null;
    }
  }, shallowEqual);
}

function useOrder() {
  return useSelector(state => {
    if (state.order) {
      return state.order.currentOrder;
    } else {
      return null;
    }
  }, shallowEqual);
}

function useOrderPayment() {
  return useSelector(state => {
    if (state.order.payment) {
      return state.order.payment;
    } else {
      return null;
    }
  }, shallowEqual);
}

function useOrderErrors() {
  return useSelector(state => {
    if (state.order && state.order.errors) {
      return state.order.errors;
    } else {
      return null;
    }
  }, shallowEqual);
}

function useLocationCatalog(id) {
  return useSelector(state => {
    const address = [];
    Object.values(state.catalogs).forEach(obj => {
      if (obj.id === id) {
        address.push({ address: obj.address });
      }
    });
    return address;
  }, shallowEqual);
}

export {
  useCatalogId,
  useCatalog,

  useProductfromCart,
  useUser,
  useUserErrors,
  useCart,
  useCartTotal,
  useCartCatalogTotal,
  useLocationCatalog,

  useOrder,
  useOrderErrors,
  useOrderPayment
};
