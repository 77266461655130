import React from 'react';
import {CircularProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

function Loader({children}) {
    const classes = useStyles();
    const theme = useTheme();

    let text = children ?? 'Trwa ładowanie';

    return <Box p={theme.spacing(2)} textAlign={"center"}>
        <CircularProgress alignItems={"center"} justifyContent={"center"}>{text}</CircularProgress>
    </Box>;
}

export default Loader;
