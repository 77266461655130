import Typography from "@material-ui/core/Typography";
import React from "react";
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {FONTS} from "../Theme";

const useStyles = makeStyles((theme) => ({
    header: {
        color: theme.palette.secondary.main,
        font: 'normal normal normal 20px/24px ' + FONTS.fontFamily,
        letterSpacing: "0.19px"
    },
    textWithIcon: {
        display: 'flex',
        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
        letterSpacing: "0.15px",
        marginTop: 16,
        color: theme.palette.secondary.main,
        "& > span": {
            marginLeft: 8
        }
    }
}));

function OrderPickUp({user}) {

    const classes = useStyles();

    return (
        <Box mb={3}>
            <Typography gutterBottom variant="h5" component="h2" className={classes.header}>
                Lokalizacja dostawy
            </Typography>

            <Typography className={classes.textWithIcon}>
                <FlagOutlinedIcon fontSize="small"/>
                <Typography component="span">
                    {user.delivery && user.delivery[0] && user.delivery[0].address ? user.delivery[0].address : ''}
                </Typography>
            </Typography>


            <Box my={1}>
                <Divider variant="fullWidth"/>
            </Box>
        </Box>
    );
}

export default OrderPickUp;
