import {useUser} from "../../../selector";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../actions/ui";
import {Redirect} from "@reach/router";
import {useFetchProfile} from "../../../actions";
import {hasError} from "../../../services/validators";
import {updateDelivery, updateCatalog, updateCatalogCompany} from "../../../services/catalogService";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {toNumber} from "../../../services/mainService";
import {getProfile, updateCompany, updateUserCompany} from "../../../services/userService";
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function UserCompanyForm({onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const [user, setUser] = useState(null);

    function reloadUser() {
        getProfile().then(response => {
            console.log('[Orders] setUser', response.data);
            setUser(response.data);
        })
    }

    React.useEffect(() => {
        reloadUser();
    }, []);

    React.useEffect(() => {
        console.log('[Orders][FORM VALUES]', user);

        if (user) {
            setFormValues(user);
        }

    }, [user]);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('[Orders]triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    function handleInputChange(event) {
        const target = event.target;

        console.log('target', event, target);

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        updateUserCompany(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    /*if (!user || !user.name) {
        return <Redirect to="/login" noThrow/>;
    }*/

    return (
        <div>
            {user ? (
                <form onSubmit={handleSubmit} className={classes.form}>
                    <TextField
                        type="text"
                        InputProps={{
                            inputProps: {
                                min: 0, max: 50
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="company_name"
                        label="Nazwa firmy"
                        name="company_name"
                        autoComplete="company_name"
                        autoFocus
                        value={formValues.company_name || ''}
                        onChange={handleInputChange}
                        error={hasError('company_name', errors)}
                        helperText={
                            hasError('company_name', errors) ? errors.company_name[0] : null
                        }
                    />

                    <TextField
                        type="text"
                        InputProps={{
                            inputProps: {
                                min: 0, max: 50
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="company_address"
                        label="Ulica"
                        name="company_address"
                        autoComplete="company_address"
                        autoFocus
                        value={formValues.company_address || ''}
                        onChange={handleInputChange}
                        error={hasError('company_address', errors)}
                        helperText={
                            hasError('company_address', errors) ? errors.company_address[0] : null
                        }
                    />

                    <TextField
                        type="text"
                        InputProps={{
                            inputProps: {
                                min: 0, max: 50
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="delivery"
                        label="Miasto"
                        name="company_city"
                        autoComplete="company_city"
                        autoFocus
                        value={formValues.company_city || ''}
                        onChange={handleInputChange}
                        error={hasError('company_city', errors)}
                        helperText={
                            hasError('company_city', errors) ? errors.company_city[0] : null
                        }
                    />

                    <TextField
                        type="text"
                        InputProps={{
                            inputProps: {
                                min: 0, max: 50
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="delivery"
                        label="Kod pocztowy"
                        name="company_postcode"
                        autoComplete="company_postcode"
                        autoFocus
                        value={formValues.company_postcode || ''}
                        onChange={handleInputChange}
                        error={hasError('company_postcode', errors)}
                        helperText={
                            hasError('company_postcode', errors) ? errors.company_postcode[0] : null
                        }
                    />

                    <TextField
                        type="number"
                        InputProps={{
                            inputProps: {
                                min: 0, max: 50
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="company_tax_id"
                        label="Nip"
                        name="company_tax_id"
                        autoComplete="company_tax_id"
                        autoFocus
                        value={formValues.company_tax_id || ''}
                        onChange={handleInputChange}
                        error={hasError('company_tax_id', errors)}
                        helperText={
                            hasError('company_tax_id', errors) ? errors.company_tax_id[0] : null
                        }
                    />

                    <Box my={1}>
                        <Divider variant="fullWidth"/>
                    </Box>

                    {!hideSubmitButton && (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isFormProcessed}
                        >
                            {!isFormProcessed ? <Box>Zapisz</Box> : <Box>Wysyłanie...</Box>}
                        </Button>
                    )}
                </form>
            ) : (
                <Box>
                    <CircularProgress/>
                </Box>
            )}
        </div>
    );
}

export default UserCompanyForm;
