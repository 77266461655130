import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HeaderBar from "../components/HeaderBar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {useDispatch} from "react-redux";
import Footer from "./Footer";
import {COLORS, FONTS} from "../Theme";

const useStyles = makeStyles((theme) => ({
    page: {},
    pageWrapper: {
        position: 'relative',
        height: '100%'
    },

    pageContainer: {
        minHeight: '400px',

        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),

        '& h1': {
            color: COLORS.secondary,
            font: 'normal normal bold 24px/24px ' + FONTS.fontFamily,
            marginTop: '60px',
            marginBottom: '30px',
            textAlign: 'left'
        },

        '& h2': {
            font: 'normal normal bold 16px/24px ' + FONTS.fontFamily,
            letterSpacing: '0.8px',
            color: '#757575',
            marginTop: '15px',
            marginBottom: '15px'
        },
        '& p': {
            font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
            color: '#757575',
            letterSpacing: '0.6px',
            marginTop: '15px',
            marginBottom: '15px'
        },
        '& ol': {
            font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
            color: '#757575',
            letterSpacing: '0.6px',
        },
        '& li': {
            font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
            color: '#757575',
            letterSpacing: '0.6px',
        }
    },


}));

export default function Page(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const query = props.query || '';

    console.log('[query]', query);

    return (
        <div>
            <HeaderBar routePath="/polityka-prywatnosci"/>

            <div className={classes.page}>

                <div className={classes.pageWrapper}>
                    <Container component="main" maxWidth="lg">
                        <Grid className={classes.pageContainer}>
                            <Typography component={'h1'}>
                                Zasady ochrony prywatności i danych osobowych
                            </Typography>
                            <Typography component={'h2'}>
                                To jest tresc polityki prywatnosci
                            </Typography>

                            <p><strong>A. Wprowadzenie</strong></p>
                            <ol>
                                <li>Prywatność odwiedzających naszą stronę internetową jest dla nas bardzo ważna i
                                    dokładamy wszelkich starań, aby ją chronić. Niniejsza polityka wyjaśnia, co robimy z
                                    Twoimi danymi osobowymi.
                                </li>
                                <li>Zgoda na korzystanie z plik&oacute;w cookie zgodnie z warunkami niniejszej polityki
                                    podczas pierwszej wizyty na naszej stronie pozwala nam na korzystanie z
                                    plik&oacute;w cookie przy każdej kolejnej wizycie na naszej stronie.
                                </li>
                            </ol>
                            <p><strong>B. Źr&oacute;dło</strong><br/>Ten dokument został utworzony przy użyciu szablonu
                                firmy SEQ Legal (seqlegal.com)<br/>i zmodyfikowany dla cel&oacute;w Website Planet (<a
                                    href="https://www.websiteplanet.com/">www.websiteplanet.com</a>)</p>
                            <p><strong>C. Zbieranie danych osobowych</strong></p>
                            <p>Następujące rodzaje danych osobowych mogą być gromadzone, przechowywane i
                                wykorzystywane:</p>
                            <ol>
                                <li>informacje o komputerze, w tym adres IP, lokalizacja geograficzna, typ i wersja
                                    przeglądarki oraz system operacyjny;
                                </li>
                                <li>informacje o Twoich wizytach i korzystaniu z tej witryny, w tym źr&oacute;dło
                                    odesłań, długość wizyty, wyświetlenia stron i ścieżki nawigacji w witrynie;
                                </li>
                                <li>informacje, takie jak adres e-mail, kt&oacute;re podajesz podczas rejestracji w
                                    naszej witrynie internetowej;
                                </li>
                                <li>informacje wprowadzane podczas tworzenia profilu w naszej witrynie &mdash; na
                                    przykład imię i nazwisko, zdjęcia profilowe, płeć, data urodzin, status związku,
                                    zainteresowania i hobby, dane edukacyjne i dane dotyczące zatrudnienia;
                                </li>
                                <li>informacje, takie jak imię i nazwisko oraz adres e-mail, kt&oacute;re podajesz w
                                    celu skonfigurowania subskrypcji naszych e-maili lub biuletyn&oacute;w;
                                </li>
                                <li>informacje wprowadzane podczas korzystania z usług na naszej stronie internetowej;
                                </li>
                                <li>informacje, kt&oacute;re są generowane podczas korzystania z naszej strony
                                    internetowej, w tym kiedy, jak często i w jakich okolicznościach z niej korzystasz;
                                </li>
                                <li>informacje dotyczące wszystkiego, co kupujesz, usług, z kt&oacute;rych korzystasz
                                    lub transakcji dokonywanych za pośrednictwem naszej strony internetowej, w tym imię
                                    i nazwisko, adres, numer telefonu, adres e-mail i dane karty kredytowej;
                                </li>
                                <li>informacje publikowane na naszej stronie internetowej z zamiarem opublikowania ich w
                                    Internecie, w tym nazwa użytkownika, zdjęcia profilowe i treść umieszczanych
                                    post&oacute;w;
                                </li>
                                <li>informacje zawarte we wszelkiej korespondencji przesyłanej do nas e-mailem lub za
                                    pośrednictwem naszej strony internetowej, w tym treści komunikacyjne i metadane;
                                </li>
                                <li>wszelkie inne dane osobowe, kt&oacute;re nam przesyłasz.</li>
                            </ol>
                            <p>Zanim ujawnisz nam dane osobowe innej osoby, musisz uzyskać zgodę tej osoby na ujawnienie
                                i przetwarzanie tych danych osobowych zgodnie z niniejszymi zasadami</p>
                            <p><strong>D. Korzystanie z Twoich danych osobowych</strong></p>
                            <p>Dane osobowe przesłane do nas za pośrednictwem naszej strony internetowej będą
                                wykorzystywane do cel&oacute;w określonych w niniejszej polityce lub na odpowiednich
                                stronach witryny. Możemy wykorzystywać Twoje dane osobowe do celu:</p>
                            <ol>
                                <li>administrowania naszą stroną internetową i biznesem;</li>
                                <li>personalizowania naszej strony internetowej dla Ciebie;</li>
                                <li>umożliwienia korzystania z usług dostępnych na naszej stronie internetowej;</li>
                                <li>wysyłania towar&oacute;w zakupionych za pośrednictwem naszej strony internetowej;
                                </li>
                                <li>świadczenia usług zakupionych za pośrednictwem naszej strony internetowej;</li>
                                <li>wysyłania do ciebie wyciąg&oacute;w, faktur i przypomnień o płatnościach oraz
                                    odbioru płatności od Ciebie;
                                </li>
                                <li>przesyłania niemarketingowej komunikacji handlowej;</li>
                                <li>wysyłania powiadomień e-mail, o kt&oacute;re prosiłeś;</li>
                                <li>wysyłania naszego newslettera e-mail, jeśli o to poprosiłeś (możesz nas w każdej
                                    chwili poinformować, jeśli nie chcesz już otrzymywać newslettera);
                                </li>
                                <li>przesyłania informacji marketingowych dotyczących naszej działalności lub aktywności
                                    starannie wybranych stron trzecich, kt&oacute;re naszym zdaniem mogą Cię
                                    zainteresować, pocztą lub, jeśli wyraziłeś na to zgodę, pocztą elektroniczną lub
                                    podobną technologią (możesz nas o tym poinformować w dowolnym momencie, jeśli nie
                                    chcesz już otrzymywać komunikat&oacute;w marketingowych);
                                </li>
                                <li>dostarczania stronom trzecim informacji statystycznych o naszych użytkownikach (ale
                                    te osoby trzecie nie będą w stanie zidentyfikować żadnego konkretnego użytkownika na
                                    podstawie tych informacji);
                                </li>
                                <li>&nbsp;zajmowania się zapytaniami i skargami składanymi przez Ciebie lub dotyczącymi
                                    Ciebie w związku z naszą witryną;
                                </li>
                                <li>zapewnienia bezpieczeństwa naszej strony internetowej i zapobieganie oszustwom;</li>
                                <li>weryfikacji zgodności z warunkami korzystania z naszej strony internetowej (w tym
                                    monitorowanie prywatnych wiadomości wysyłanych za pośrednictwem naszej prywatnej
                                    usługi przesyłania wiadomości); i
                                </li>
                                <li>innych zastosowań.</li>
                            </ol>
                            <p>Jeśli prześlesz dane osobowe do publikacji w naszej witrynie, opublikujemy je i
                                wykorzystamy w inny spos&oacute;b zgodnie z udzieloną nam licencją.</p>
                            <p>Twoje ustawienia prywatności mogą być wykorzystane do ograniczenia publikacji Twoich
                                informacji na naszej stronie internetowej i mogą być dostosowane za pomocą kontroli
                                prywatności na stronie.</p>
                            <p>Nie będziemy bez Twojej wyraźnej zgody przekazywać danych osobowych stronom trzecim, lub
                                jakimkolwiek innym związanym z nimi stronom trzecim, w celach marketingu
                                bezpośredniego.</p>
                            <p><strong>E. Ujawnianie danych osobowych</strong></p>
                            <p>Możemy ujawniać dane osobowe użytkownika wszelkim naszym pracownikom, członkom kadry
                                kierowniczej, przedstawicielom, dostawcom lub podwykonawcom, o ile jest to niezbędne do
                                cel&oacute;w określonych w niniejszych &bdquo;Zasadach ochrony prywatności&rdquo;.</p>
                            <p>Możemy ujawniać dane osobowe wszelkim członkom naszej grupy sp&oacute;łek (tzn.
                                sp&oacute;łkom zależnym, gł&oacute;wnej sp&oacute;łce holdingowej i jej sp&oacute;łkom
                                zależnym), o ile jest to niezbędne do cel&oacute;w określonych w
                                niniejszych &bdquo;Zasadach ochrony prywatności&rdquo;.</p>
                            <p>Możemy ujawniać Twoje dane osobowe:</p>
                            <ol>
                                <li>w zakresie, w jakim jest to wymagane przepisami prawa;</li>
                                <li>w związku z trwającymi lub potencjalnymi postępowaniami prawnymi;</li>
                                <li>w celu ustanowienia, wyegzekwowania lub obrony naszych praw (wliczając w to
                                    udostępnienie informacji innym podmiotom w celu przeciwdziałania oszustwom);
                                </li>
                                <li>nabywcy (lub potencjalnemu nabywcy) jakiejkolwiek działalności lub aktyw&oacute;w,
                                    kt&oacute;re sprzedajemy (lub rozważamy); i
                                </li>
                                <li>wszelkim osobom, kt&oacute;re wedle naszej zasadnej opinii mogą wystąpić do sądu lub
                                    innego właściwego organu o ujawnienie takich danych osobowych, jeśli wedle zasadnej
                                    rozsądnej opinii, istnieje duże prawdopodobieństwo, że taki sąd lub organ nakaże
                                    ujawnienie takich danych osobowych
                                </li>
                            </ol>
                            <p>Z wyjątkiem postanowień zawartych w niniejszych &bdquo;Zasadach ochrony
                                prywatności&rdquo; nie będziemy udostępniać osobom trzecim informacji dotyczących
                                użytkownika.</p>
                            <p><strong>F. Międzynarodowe transfery danych</strong></p>
                            <ol>
                                <li>Informacje, kt&oacute;re gromadzimy mogą być przechowywane i przetwarzane w każdym z
                                    kraj&oacute;w, w kt&oacute;rym prowadzimy działalność i mogą być przesyłane pomiędzy
                                    tymi krajami w celu umożliwienia wykorzystania informacji zgodnie z
                                    niniejszymi &bdquo;Zasadami ochrony prywatności&rdquo;.
                                </li>
                                <li>Informacje, kt&oacute;re zbieramy, mogą być przekazywane do następujących
                                    kraj&oacute;w, kt&oacute;re nie mają przepis&oacute;w o ochronie danych
                                    r&oacute;wnoważnych z obowiązującymi w Europejskim Obszarze Gospodarczym:
                                    Stan&oacute;w Zjednoczonych Ameryki, Rosji, Japonii, Chin i Indii.
                                </li>
                                <li>Dane osobowe, kt&oacute;re publikujesz na naszej stronie internetowej lub przesyłasz
                                    do publikacji na naszej stronie internetowej, mogą być dostępne za pośrednictwem
                                    Internetu na całym świecie. Nie możemy zapobiec wykorzystywaniu lub niewłaściwemu
                                    wykorzystaniu takich informacji przez inne osoby.
                                </li>
                                <li>Wyraźnie zgadzasz się na przekazywanie danych osobowych opisanych w tej sekcji F.
                                </li>
                            </ol>
                            <p><strong>G. Zachowywanie danych osobowych</strong></p>
                            <ol>
                                <li>W Niniejszej Sekcji G określiliśmy nasze zasady i procedury dotyczące zatrzymywania
                                    danych, kt&oacute;re mają na celu zapewnienie przestrzegania naszych zobowiązań
                                    prawnych w zakresie zachowywania i usuwania danych osobowych.
                                </li>
                                <li>Dane osobowe, kt&oacute;re przetwarzamy w dowolnym celu lub celach, nie będą
                                    przechowywane dłużej niż jest to konieczne do tego celu lub tych cel&oacute;w.
                                </li>
                                <li>Bez uszczerbku dla artykułu G-2 zazwyczaj usuwamy dane osobowe należące do kategorii
                                    określonych poniżej w dniu/godzinie określonej poniżej:
                                    <ol type="a">
                                        <li>typ danych osobowych zostanie usunięty WPROWADŹ DATĘ/GODZINĘ; i</li>
                                        <li>WPROWADŹ DODATKOWE DATY/GODZINY.</li>
                                    </ol>
                                </li>
                                <li>Niezależnie od innych postanowień niniejszej sekcji G zachowamy dokumenty (w tym
                                    dokumenty elektroniczne) zawierające dane osobowe:
                                    <ol type="a">
                                        <li>w zakresie, w jakim jest to wymagane przepisami prawa;</li>
                                        <li>jeśli uważamy, że dokumenty mogą mieć znaczenie dla wszelkich toczących się
                                            lub przyszłych postępowań sądowych; i
                                        </li>
                                        <li>w celu ustanowienia, wyegzekwowania lub obrony naszych praw (wliczając w to
                                            udostępnienie informacji innym podmiotom w celu przeciwdziałania oszustwom).
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p><strong>H. Bezpieczeństwo danych osobowych</strong></p>
                            <ol>
                                <li>Podejmiemy zasadne techniczne i organizacyjne środki ostrożności w celu
                                    przeciwdziałania utracie, nadużyciu lub zmianie danych osobowych użytkownika.
                                </li>
                                <li>Będziemy przechowywać wszystkie dane osobowe, kt&oacute;re podasz na naszych
                                    bezpiecznych serwerach (chronionych hasłem i zaporą).
                                </li>
                                <li>Wszystkie elektroniczne transakcje finansowe zawierane za pośrednictwem naszej
                                    strony internetowej będą chronione technologią szyfrowania.
                                </li>
                                <li>Użytkownik przyjmuje do wiadomości, że transmisja informacji przez Internet jest
                                    potencjalnie niebezpieczna i bezpieczeństwo przesłanych w ten spos&oacute;b danych
                                    nie jest gwarantowane.
                                </li>
                                <li>Jesteś odpowiedzialny za zachowanie poufności hasła używanego do uzyskania dostępu
                                    do naszej strony internetowej; nie poprosimy Cię o podanie hasła (z wyjątkiem
                                    sytuacji, gdy logujesz się na naszej stronie internetowej).
                                </li>
                            </ol>
                            <p><strong>I. Nowelizacje</strong></p>
                            <p>Niniejsze zasady mogą być okresowo aktualizowane poprzez zamieszczenie w naszej witrynie
                                ich nowej wersji. Należy od czasu do czasu sprawdzać tę stronę, aby upewnić się, że
                                rozumiesz wszelkie zmiany w tych zasadach. Możemy powiadomić Cię o zmianach w niniejszej
                                polityce za pośrednictwem poczty elektronicznej lub prywatnego systemu przesyłania
                                wiadomości na naszej stronie internetowej.</p>
                            <p><strong>J. Twoje prawa</strong></p>
                            <p>Użytkownik może zażądać udostępnienia jego danych osobowych, kt&oacute;re są
                                przechowywane. Udostępnienie takich danych wiąże się z:</p>
                            <ol>
                                <li>uiszczeniem opłaty WPROWADŹ OPŁATĘ, JEŚLI DOTYCZY; i</li>
                                <li>dostarczenie odpowiedniego dowodu tożsamości (DOSTOSUJ TEKST, ABY ODZWIERCIEDLAŁ
                                    TWOJĄ POLITYKĘ tym celu, zazwyczaj akceptujemy kserokopię paszportu poświadczoną
                                    przez notariusza oraz oryginalną kopię rachunku za media z aktualnym adresem).
                                </li>
                            </ol>
                            <p>Możemy na Twoje żądanie ukrywać dane osobowe, w zakresie dozwolonym przez prawo.</p>
                            <p>Możesz w dowolnym momencie poinstruować nas, aby nie przetwarzać danych osobowych w
                                celach marketingowych.</p>
                            <p>W praktyce zazwyczaj albo z g&oacute;ry wyraźnie wyrażasz zgodę na wykorzystanie przez
                                nas Twoich danych osobowych w celach marketingowych, albo zapewnimy Ci możliwość
                                rezygnacji z udostępniania Twoich danych osobowych w celach marketingowych.</p>
                            <p><strong>K. Strony Internetowe os&oacute;b trzecich</strong></p>
                            <p>Nasza strona internetowa zawiera hiperłącza do stron internetowych os&oacute;b trzecich
                                oraz szczeg&oacute;łowe informacje na ich temat. Nie mamy kontroli i nie ponosimy
                                odpowiedzialności za politykę prywatności i praktyki os&oacute;b trzecich.</p>
                            <p><strong>L. Aktualizacja informacji</strong></p>
                            <p>Prosimy o informację w przypadku konieczności skorygowania lub aktualizowania danych
                                osobowych, w kt&oacute;rych posiadaniu jesteśmy.</p>
                            <p><strong>M. Ciasteczka</strong></p>
                            <p>Nasza strona internetowa korzysta z plik&oacute;w cookie. Plik cookie to plik zawierający
                                identyfikator (ciąg liter i cyfr), kt&oacute;ry jest wysyłany przez serwer internetowy
                                do przeglądarki internetowej i przechowywany przez przeglądarkę. Dane identyfikacyjne są
                                ponownie przesyłane na serwer za każdym razem, gdy przeglądarka wyśle żądanie otwarcia
                                strony znajdującej się na serwerze. Pliki cookie mogą
                                być &bdquo;trwałymi&rdquo; lub &bdquo;sesyjnymi&rdquo; plikami cookie: trwałe pliki
                                cookie będą przechowywane przez przeglądarkę internetową i pozostaną ważne do ustalonej
                                daty wygaśnięcia, chyba że użytkownik usunie je przed datą wygaśnięcia; sesyjny plik
                                cookie wygasa z końcem sesji użytkownika, gdy przeglądarka internetowa jest zamykana.
                                Pliki cookie zazwyczaj nie zawierają żadnych informacji identyfikujących użytkownika,
                                ale dane osobowe, kt&oacute;re przechowujemy na Tw&oacute;j temat, mogą być powiązane z
                                informacjami przechowywanymi w plikach cookie i uzyskiwanymi z nich. WYBIERZ ODPOWIEDNIĄ
                                FRAZĘ Używamy tylko ciasteczek sesyjnych / tylko trwałych ciasteczek / zar&oacute;wno
                                sesyjnych, jak i trwałych ciasteczek na naszej stronie.</p>
                            <ol>
                                <li>Nazwy plik&oacute;w cookie, kt&oacute;rych używamy na naszej stronie internetowej,
                                    oraz cele, w kt&oacute;rych są wykorzystywane, są określone poniżej:
                                    <ol type="a">
                                        <li>używamy Google Analytics i Adwords na naszej stronie internetowej, aby
                                            rozpoznać komputer, gdy użytkownik OBEJMUJE WSZYSTKIE ZASTOSOWANIA DO
                                            KT&Oacute;RYCH PLIKI COOKIE SĄ UŻYWANE W TWOJEJ WITRYNIE, odwiedza witrynę /
                                            śledzi użytkownik&oacute;w podczas przeglądania witryny / umożliwia
                                            korzystanie z koszyka na stronie / poprawić użyteczność strony internetowej
                                            / analizować korzystanie ze strony internetowej / administrować stroną
                                            internetową / zarządzać witryną / zapobiegać oszustwom i poprawić
                                            bezpieczeństwo strony internetowej / personalizować stronę internetową dla
                                            każdego użytkownika / kierować reklamy, kt&oacute;re mogą być
                                            szczeg&oacute;lnie interesujące dla określonych użytkownik&oacute;w / opisać
                                            cele;
                                        </li>
                                    </ol>
                                </li>
                                <li>Większość przeglądarek pozwala odm&oacute;wić przyjęcia plik&oacute;w
                                    cookie &mdash; na przykład:
                                    <ol type="a">
                                        <li>w przeglądarce Internet Explorer (wersja 10) można blokować pliki cookie,
                                            korzystając z dostępnych ustawień zastępowania obsługi plik&oacute;w cookie,
                                            klikając &bdquo;Narzędzia&rdquo;, &bdquo;Opcje
                                            internetowe&rdquo;, &bdquo;Prywatność&rdquo;, a
                                            następnie &bdquo;Zaawansowane&rdquo;;
                                        </li>
                                        <li>w przeglądarce Firefox (wersja 24) możesz zablokować wszystkie pliki cookie,
                                            klikając &bdquo;Narzędzia&rdquo;, &bdquo;Opcje&rdquo;, &bdquo;Prywatność&rdquo;,
                                            wybierając &bdquo;Użyj ustawień niestandardowych dla historii&rdquo; z menu
                                            rozwijanego i odznaczając &bdquo;Akceptuj ciasteczka z witryn&rdquo;; i
                                        </li>
                                        <li>w Chrome (wersja 29) możesz zablokować wszystkie pliki cookie, otwierając
                                            menu &bdquo;Dostosuj i kontroluj&rdquo;,
                                            klikając &bdquo;Ustawienia&rdquo;, &bdquo;Pokaż ustawienia
                                            zaawansowane&rdquo; i &bdquo;Ustawienia treści&rdquo;, a następnie
                                            wybierając &bdquo;Blokuj witrynom ustawianie dowolnych danych&rdquo; pod
                                            nagł&oacute;wkiem &bdquo;Pliki cookie&rdquo;.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>Zablokowanie wszystkich plik&oacute;w cookie będzie miało negatywny wpływ na możliwość
                                korzystania z wielu stron internetowych. Jeśli zablokujesz pliki cookie, nie będziesz
                                m&oacute;gł korzystać z wszystkich funkcji naszej strony.</p>
                            <ol start="3">
                                <li>Możesz usunąć pliki cookie już zapisane na komputerze &mdash; na przykład:
                                    <ol type="a">
                                        <li>w przeglądarce Internet Explorer (wersja 10) należy ręcznie usunąć pliki
                                            cookie (instrukcje można znaleźć na stronie&nbsp;<a
                                                href="http://support.microsoft.com/kb/278835">http://support.microsoft.com/kb/278835</a>&nbsp;);
                                        </li>
                                        <li>w przeglądarce Firefox (wersja 24) możesz usunąć pliki cookie,
                                            klikając &bdquo;Narzędzia&rdquo;, &bdquo;Opcje&rdquo; i &bdquo;Prywatność&rdquo;,
                                            a następnie wybierając &bdquo;Użyj ustawień niestandardowych dla
                                            historii&rdquo;, klikając &bdquo;Pokaż pliki cookie&rdquo;, a następnie
                                            klikając &bdquo;Usuń wszystkie pliki cookie&rdquo;; i
                                        </li>
                                        <li>w przeglądarce Chrome (wersja 29) można usunąć wszystkie pliki cookie,
                                            otwierając menu &bdquo;Dostosuj i kontroluj&rdquo;,
                                            klikając &bdquo;Ustawienia&rdquo;, &bdquo;Pokaż ustawienia
                                            zaawansowane&rdquo; i &bdquo;Wyczyść dane przeglądarki&rdquo;, a następnie
                                            wybierając &bdquo;Usuń pliki cookie i dane innych stron i
                                            wtyczek&rdquo; przed kliknięciem &bdquo;Wyczyść
                                            dane<br/>przeglądania&rdquo;.
                                        </li>
                                    </ol>
                                </li>
                                <li>Usunięcie plik&oacute;w cookie będzie miało negatywny wpływ na użyteczność wielu
                                    stron internetowych.
                                </li>
                            </ol>
                            <p><em>Witryna Planet nie ponosi żadnej odpowiedzialności i zaleca konsultacje z ekspertami
                                prawnym, jeśli wdrożysz powyższe działania na swojej stronie internetowej.</em></p>
                        </Grid>
                    </Container>
                </div>

                <Footer/>
            </div>
        </div>
    );
}
