import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import StaticOrderForm from "../Forms/StaticOrderForm";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import ConfirmOrderDialog from "./ConfirmOrderDialog";

const useStyles = makeStyles((theme) => ({
    root: {

    }
}));

export default function CatalogOrderDialog({open = false, data = null, onClose, onConfirm}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [submit, setSubmit] = React.useState(false);

    const handleClose = () => {
        setSubmit(false);
        onClose();
    };

    const handleError = () => {
        setSubmit(false);
    };

    const triggerSubmit = () => {
        setSubmit(true);
    };

    const [openDialog, setOpenDialog] = useState({
        'confirm': {
            open: false,
            data: null
        },
        'cancel': {
            open: false,
            data: null
        }
    });

    function handleOpenDialog(name, data = null) {
        setOpenDialog({
            ...openDialog,
            [name]: {
                open: true,
                data: data
            }
        });

        console.log('openAddDialog', name, data);
    }

    function handleCloseDialog(name, result) {
        console.log('handleDialogClose', name);

        setOpenDialog({
            ...openDialog,
            [name]: {
                ...openDialog[name],
                open: false
            }
        });
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Podgląd zamówienia"}</DialogTitle>
                <DialogContent>
                    <StaticOrderForm order={data} type={"catalog"}/>
                </DialogContent>

                {data && data.status === 'new' ? (
                    <Box>
                        <DialogActions>
                            <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleOpenDialog("cancel", data)}
                                >
                                    Anuluj zamówienie
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => handleOpenDialog("confirm", data)}
                                    color="primary"
                                >
                                    Potwierdzam realizację zamówienia
                                </Button>
                            </Box>
                        </DialogActions>
                        <ConfirmDialog
                            title="Anuluj realizację tego zamówienia"
                            open={openDialog.cancel.open}
                            data={openDialog.cancel.data}
                            setOpen={(open) => handleCloseDialog('cancel', open)}
                            onConfirm={() => onConfirm(data, "declined")}
                        >
                            Czy na pewno anulować realizację tego zamówienia?
                        </ConfirmDialog>

                        <ConfirmOrderDialog
                            title="Potwierdź zamówienie"
                            open={openDialog.confirm.open}
                            data={openDialog.confirm.data}
                            onClose={(open) => handleCloseDialog('confirm', open)}
                            onConfirm={(comment) => { onConfirm(data, "confirmed", comment); handleCloseDialog('confirm', open)}}/>
                    </Box>
                ) : (
                    <DialogActions></DialogActions>
                )}

            </Dialog>
        </div>
    );
}