import React from "react";

function Price({value, round = false}) {

    return (
        <span>
            {round ? Math.round(parseFloat(value)) : parseFloat(value).toFixed(2)} ZŁ
        </span>
    );
}

export default Price;
