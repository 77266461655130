import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../../actions/ui";
import {hasError} from "../../../../services/validators";
import {createComment, sendInviteMessage, sendMessage} from "../../../../services/catalogService";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {getProfile} from "../../../../services/userService";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "../../../../assets/icons/check-24px.svg";
import {APP_SITE, FONTS} from "../../../../Theme";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    label: {
        fontFamily: FONTS.fontFamily, fontStyle: "normal", fontWeight: "400", fontSize: "16px", lineHeight: "150%", display: "flex", alignItems: "center", color: "#06106D", paddingBottom: 8,
        '& > span': {
            color: "#757575",
            fontWeight: 600
        }
    }
}));

function AdminInviteForm({catalog, menuItem, onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({
        'name': "",
        'phone': "",
        'email': "",
        'body':
            APP_SITE === 'jakieoze' ?
            "Już wkrótce startujemy z porównywarką cenową OZE. Zachęcamy do utworzenia konta w naszym serwisie i wypełnienia danych na temat Państwa oferowanych usług branży fotowoltaicznej." :
            "Prowadzisz firmę z branży paramedycznej? Dotrzyj do nowych klientów. Zachęcamy do utworzenia konta w naszym serwisie.",
    });
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const [user, setUser] = useState({
        'name': "",
        'phone': "",
        'email': ""
    });

    function reloadUser() {
        getProfile().then(response => {
            console.log('[Orders] setUser');
        })
    }

    React.useEffect(() => {
        reloadUser();
    }, []);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            setIsFormProcessed(true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    function handleInputChange(event) {
        const target = event.target;

        console.log('target', event, target);

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        setIsFormProcessed(true);
        submit(formValues);
    }

    function submit(data) {
        sendInviteMessage(data).then(response => {
            console.log('response', response);

            if (response !== undefined && response.data.error) {
                //console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Wiadomość została wysłana!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    return (
        <div>
            {(user || 1==1) && (
                <form onSubmit={handleSubmit} className={classes.form}>

                    {catalog && (
                        <>
                            <Box ml={0} p={0}>
                                <Typography component="p" variant="body1" className={classes.label}>
                                    <img src={CheckIcon}/>&nbsp;Instalator:&nbsp;<span>{catalog.name}</span>
                                </Typography>
                            </Box>
                        </>
                    )}

                    <div className={classes.separator}></div>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Adres e-mail"
                        name="email"
                        value={formValues.email}
                        onChange={handleInputChange}
                        error={hasError('email', errors)}
                        helperText={
                            hasError('email', errors) ? errors.email[0] : null
                        }
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="body"
                        label="Treść wiadomości"
                        name="body"
                        autoFocus
                        rows="12"
                        multiline={true}
                        value={formValues.body || ''}
                        onChange={handleInputChange}
                        error={hasError('body', errors)}
                        helperText={
                            hasError('body', errors) ? errors.body[0] : null
                        }
                    />

                    {/* <Box my={1}>
                        <Divider variant="fullWidth"/>
                    </Box>*/}

                    {!hideSubmitButton && (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isFormProcessed}
                        >
                            {!isFormProcessed ? <Box>Wyślij</Box> : <Box>Wysyłanie...</Box>}
                        </Button>
                    )}
                </form>
            )}
        </div>
    );
}

export default AdminInviteForm;
