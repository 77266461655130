import React, {useState} from "react";
import CatalogListItem from "./CatalogListItem";
import ajaxClient from "../../services/ajaxClient";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {Paper} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import DayOpeningHours from "../DayOpeningHours";
import GeoStaticMap from "../GeoStaticMap";
import CatalogAddress from "../CatalogAddress";
import SendMessage from "../SendMessage";
import {COLORS, FONTS} from "../../Theme";
import CardMedia from "@material-ui/core/CardMedia";
import {assetsPath} from "../../services/mainService";
import Button from "@material-ui/core/Button";
import Link from '@mui/material/Link';
import CatalogPremiumPhonePrice from "./CatalogPremiumPhonePrice";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
    root: {},
    miniCart: {
        /*        position: 'sticky',
                top: 90*/
    },
    order__title: {
        textAlign: 'start',
        font: 'normal normal bold 24px/150% ' + FONTS.fontFamily,
        color: COLORS.secondary,
    },
    card: {
        borderRadius: 6,
        marginBottom: theme.spacing(2),
        background: '#F2F4F7 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
    },
    cover: {
        height: 40,
        [theme.breakpoints.down('md')]: {
            width: "100%",
            maxHeight: 40,
            height: 40,
        },
        [theme.breakpoints.up('md')]: {
            width: 40,
            maxHeight: 40,
            height: 40,
        },
    },
    button: {
        minWidth: "367px"
    },
    img: {
        marginLeft: "50%"
    },
    p: {
        fontSize: "14px",
        color: "#101828"
    },
    t1: {
        color: COLORS.primary,
        fontSize: "24px",
        fontWeight: "700"

    },
    span: {
        color: COLORS.default,
        fontSize: "16px",
        fontWeight: "400"
    },

    link: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px",
        fontFamily: "Sora",
        fontSize: "16px",
    }
}));

function CatalogPremiumPhone({catalog, wysokosc, szerokosc, onClickPhone, showAvatar = true}) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent>

                {showAvatar && catalog.premium_phone.avatar_filename_url && (
                    <Box display={"flex"} alignItems={"center"} className={classes.name}>
                        <CardMedia
                            className={classes.cover}
                            image={catalog.premium_phone.avatar_filename_url + "?w=40&h=40"}
                            title={catalog.premium_phone.name}
                        />
                        &nbsp;{catalog.premium_phone.name}
                    </Box>
                )}
                <Box mt={2}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid className={classes.number} item xs={12} sm={8}>
                            <Typography align="left" className={classes.order__title}>
                                Porada telefoniczna
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <img src={assetsPath('premiumPhone.png')} className={classes.img}/>
                        </Grid>
                    </Grid></Box>

                {!showAvatar && (
                    <>
                        <Box display={"flex"} alignItems={"center"} className={classes.name}>
                            <InfoOutlinedIcon/>&nbsp;{catalog.premium_phone.name}
                        </Box>
                    </>
                )}

                <CatalogPremiumPhonePrice catalog={catalog} showDescription={false}/>

                {/* <Typography align="left">
                    Cena info: {catalog.premium_phone.price_info}
                </Typography>*/}

                <Box pt={2} pb={1}>
                    <Button color="primary" variant="contained" size="large" className={classes.button}
                            onClick={onClickPhone}>
                        Zadzwoń &nbsp;<strong>{catalog.premium_phone.generated_phone}</strong>
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}

export default CatalogPremiumPhone;
