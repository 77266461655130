import React from 'react'
import {GoogleMap, LoadScript, useLoadScript, Marker} from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '300px'
};

function GeoMap({onMapLoaded, position = null}) {
    const [map, setMap] = React.useState(null)
    const [center, setCenter] = React.useState( {
        lat: -3.745,
        lng: -38.523
    });

    console.log('[AC]: position', position);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyAlCyDwx8W6CXQvVYDcZJO8Wkkvg-c2isI",
        libraries: ['places']
    })

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
        onMapLoaded(map);
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onMarkerLoad = (marker) => {
        console.log('[AC]: onMarkerLoad');
        if (map) {
            console.log('[AC]: map onMarkerLoad', marker.position);
            map.setZoom(17);
            map.panTo(new window.google.maps.LatLng(position.lat, position.lng));
            //setCenter(marker.position);
        }
    }

    return (
        <div>
            {isLoaded ? (
                <GoogleMap
                    id="map"
                    options={{
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false
                    }}
                    mapContainerStyle={containerStyle}
                    center={center}

                    zoom={10}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    {position && position.lat && position.lng && (
                        <Marker
                            onLoad={onMarkerLoad}
                            position={new window.google.maps.LatLng(position.lat, position.lng)}
                        />
                    )}
                    <></>
                </GoogleMap>
            ) : 'Ładowanie...'}
        </div>
    )
}

export default React.memo(GeoMap)