// @ts-ignore
import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import {assetsPath} from "../../services/mainService";

function Arrow({
                   children,
                   disabled,
                   onClick
               }: {
    children: React.ReactNode;
    disabled: boolean;
    onClick: VoidFunction;
}) {
    return (
        <a
            disabled={disabled}
            onClick={onClick}
            style={{
                cursor: "pointer",
                /*display: "flex",*/
                flexDirection: "column",
                justifyContent: "center",
                right: "1%",
                display: disabled ? "none" : "flex",
                userSelect: "none"
            }}
        >
            {children}
        </a>
    );
}

export function LeftArrow() {
    const {
        isFirstItemVisible,
        scrollPrev,
        visibleItemsWithoutSeparators,
        initComplete
    } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(
        !initComplete || (initComplete && isFirstItemVisible)
    );
    React.useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

    return (
        <Arrow disabled={disabled} onClick={() => scrollPrev()}>
            <img src={assetsPath('icons/arrow-left.svg')}/>
        </Arrow>
    );
}

export function RightArrow() {
    const {
        isLastItemVisible,
        scrollNext,
        visibleItemsWithoutSeparators
    } = React.useContext(VisibilityContext);

    // console.log({ isLastItemVisible });
    const [disabled, setDisabled] = React.useState(
        !visibleItemsWithoutSeparators.length && isLastItemVisible
    );
    React.useEffect(() => {
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleItemsWithoutSeparators]);

    return (
        <Arrow disabled={disabled} onClick={() => scrollNext()}>
            <img src={assetsPath('icons/arrow-right.svg')}/>
        </Arrow>
    );
}
