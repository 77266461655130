import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import moment from "moment/moment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {ArrowLeft, ArrowRight} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {FONTS} from "../../../../Theme";

const useStyles = makeStyles((theme) => ({
    day_name: {
        fontWeight: 700,
        font: 'normal normal normal 14px/16px ' + FONTS.fontFamily,
        color: '#344054',
        textAlign: 'center',
        lineHeight: '150%'
    },
    date: {
        fontWeight: 500,
        font: 'normal normal normal 14px/16px ' + FONTS.fontFamily,
        color: '#344054'
    },
}));

function CalendarDay({onSuccess, onError, date = null}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <div>
            <p className={classes.day_name}>
                {date && (
                    <>
                        {date.format('ddd')}.<br/>
                        <small className={classes.date}>{date.format('D')} {date.format('MMM')}.</small>
                    </>
                )}
            </p>
        </div>
    );
}

export default CalendarDay;
