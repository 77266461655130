import React from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DayOpeningHoursLabel from "./DayOpeningHoursLabel";
import {FONTS} from "../Theme";

const useStyles = makeStyles((theme) => ({

    header: {
        color: theme.palette.secondary.main,
        font: 'normal normal normal 20px/24px ' + FONTS.fontFamily,
        letterSpacing: "0.19px"
    },
    textWithIcon: {
        display: 'flex',
        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
        letterSpacing: "0.15px",
        marginTop: 16,
        color: theme.palette.secondary.main,
        "& > span": {
            marginLeft: 8
        }
    }
}));

function DayOpeningHours({catalog}) {
    const classes = useStyles();
    return (
        <Box mb={3}>
            {catalog && catalog.opening_hours && (
                <Box>
                    <Typography component={"h2"} variant={"subtitle2"}>Godziny otwarcia</Typography>
                    <br/>
                    <DayOpeningHoursLabel day={catalog.opening_hours.monday} label={"Poniedziałek"}/>
                    <DayOpeningHoursLabel day={catalog.opening_hours.tuesday} label={"Wtorek"}/>
                    <DayOpeningHoursLabel day={catalog.opening_hours.wednesday} label={"Środa"}/>
                    <DayOpeningHoursLabel day={catalog.opening_hours.thursday} label={"Czwartek"}/>
                    <DayOpeningHoursLabel day={catalog.opening_hours.friday} label={"Piątek"}/>
                    <DayOpeningHoursLabel day={catalog.opening_hours.saturday} label={"Sobota"}/>
                    <DayOpeningHoursLabel day={catalog.opening_hours.sunday} label={"Niedziela"}/>
                </Box>
            )}
        </Box>
    );
}

export default DayOpeningHours;
