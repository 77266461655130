import React, {useState} from "react";
import CatalogsList from "../components/Catalogs/CatalogsList";
import {useUser} from "../selector";
import HeaderBar from "../components/HeaderBar";
import ajaxClient from "../services/ajaxClient";
import Container from "@material-ui/core/Container";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getQuery, getQueryLink, parseQuery} from "../services/localizationService";
import SearchBar from "../components/SearchBar";
import Footer from "./Footer";
import {FormControlLabel, Switch} from "@material-ui/core";
import MapContainer from "../components/Maps/MapContainer";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Loader from "../components/Loader";
import {LANGS} from "../Langs";
import CatalogCategoriesFilter from "../components/Catalogs/CatalogCategoriesFilter";
import CatalogTagsFilter from "../components/Catalogs/CatalogTagsFilter";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        marginBottom: '15px'
    },
    mapContainer: {
        /*backgroundColor: 'red',*/
        position: 'relative',
        '& $items': {
            /*backgroundColor: 'green',*/
            padding: 16,
            [theme.breakpoints.up('lg')]: {
                maxWidth: '808px'
            },
        },
        '& $map': {
            [theme.breakpoints.down('lg')]: {
                top: 0,
                position: 'fixed',
                width: '100%',
                height: '100%'
            },
            [theme.breakpoints.up('lg')]: {
                width: 'calc(100% - 840px)',
                inset: '0px 0px 0px',
                position: 'absolute',
                top: 0,
                left: 'auto !important',
            },
            /*backgroundColor: 'grey',*/
        },

        '& $mapBox': {
            [theme.breakpoints.up('lg')]: {
                height: '100vh',
                paddingtop: '80px',
                marginTop: '-80px',
                position: 'sticky',
                top: '0px',
            },
            /*backgroundColor: 'yellow',*/
            /*backgroundImage: `url(${Background})`,*/
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100% 100%",
        }
    },
    filters: {
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        },
    },

    mapSwitchFilter: {
        [theme.breakpoints.down('md')]: {
            position: 'fixed',
            zIndex: '10001',
            background: 'white',
            width: '100%',
            left: '0px',
            padding: '15px',
            top: '64px',
        },
    },

    map: {
        backgroundColor: 'gray',
        width: '100%',
        height: '100%',
    },

    items: {},
    mapBox: {}
}));

function Catalogs(props) {
    const classes = useStyles();

    const query = props.query || '';

    console.log('[query]', query);
    console.log('[parseQuery]', parseQuery(query));

    const user = useUser();

    const [catalogs, setCatalogs] = useState(null);
    const [category, setCategory] = useState([]);
    const [formValues, setFormValues] = useState(null);
    const [showMap, setShowMap] = useState(parseQuery(query).showMap);
    const [address, setAddress] = useState(parseQuery(query).address);

    React.useEffect(() => {
        setFormValues(parseQuery(query));
    }, [query]);

    React.useEffect(() => {
        if (formValues) {

            async function fetchCatalogs() {
                const response = await ajaxClient().get(`catalogs?` + getQuery(formValues));
                setCatalogs(response.data.data);
            }

            async function fetchCategory() {
                if (formValues.category) {
                    const response = await ajaxClient().get(`categories?` + getQuery(formValues) + "&first");
                    setCategory(response.data.data);
                } else {
                    setCategory(null);
                }
            }

            fetchCatalogs();
            fetchCategory();
        }
    }, [formValues]);

    function handleClick(event) {
        event.preventDefault();
    }

    function handleMap(event) {
        setShowMap(event.target.checked);
    }

    function handleAddressChange(address) {
        console.log('address changed', address);
        setAddress(address);
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/catalogs" titleBar=""/>
            {(!showMap || 1==2) && (
                <SearchBar address={address} onAddressChange={handleAddressChange}/>
            )}
            <Container disableGutters={!!showMap} maxWidth={showMap ? false : "lg"} className={showMap ? classes.mapContainer : classes.container}>
                <Box className={classes.items}>
                    <Box pt={2} pb={2}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/" onClick={handleClick}>
                                Strona główna
                            </Link>

                            <Link
                                color="textPrimary"
                                href="/catalogs"
                                to="/catalogs"
                                /*onClick={() => navigate("/catalogs")}*/
                            >
                                {LANGS.headerBarCatalogsName}
                            </Link>

                            {category && category.name && (
                                <Link
                                    color="textPrimary"
                                    href={getQueryLink(formValues, {'category': category.name})}
                                    to={getQueryLink(formValues, {'category': category.name})}
                                    /*onClick={() => navigate(`/${country}/${city}/catalogs/category/${category.slug}`)}*/
                                    aria-current="page"
                                >
                                    {category.name}
                                </Link>)
                            }

                        </Breadcrumbs>

                        <Box mt={2}>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Typography component={"h1"} variant={"h4"}>{LANGS.catalogsTypo}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={showMap}
                                                    onChange={handleMap}
                                                    name="map"
                                                    color="primary"
                                                />
                                            }
                                            label="Mapa"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <Grid container spacing={3}>
                        {!showMap && (
                            <Grid item xs={12} sm={12}>
                                <Box className={classes.filters}>
                                    <Box>
                                        <CatalogCategoriesFilter query={query} type={"catalogs"}/>
                                    </Box>

                                    <Box>
                                        <CatalogTagsFilter query={query} type={"catalogs"} category={category}/>
                                    </Box>
                                </Box>
                            </Grid>
                        )}

                        <Grid item xs={12} sm={showMap ? 12 : 12}>
                            {!catalogs && (
                                <Loader>Trwa ładowanie...</Loader>
                            )}

                            {catalogs && (
                                <CatalogsList category={category} catalogs={catalogs}/>
                            )}
                        </Grid>
                    </Grid>
                </Box>
                {showMap && (
                    <Box className={classes.map}>
                        <Box py={2} className={classes.mapSwitchFilter}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showMap}
                                        onChange={handleMap}
                                        name="map"
                                        color="primary"
                                    />
                                }
                                label="Mapa"
                            />
                        </Box>
                        <Box className={classes.mapBox}>
                            <MapContainer catalogs={catalogs}/>
                        </Box>
                    </Box>
                )}
            </Container>

            {!showMap && (
                <Footer/>
            )}
        </div>
    );
}

export default Catalogs;
