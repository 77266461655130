import ajaxClient from "./ajaxClient";

export const getMyCatalog = async (data) => {
    try {
        const response = await ajaxClient().get('profile/menu/catalog').then(response => response.data);

        return response;
    } catch (error) {
        return error.response;
    }
}

export const updateDelivery = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/delivery', {
            /*'has_delivery_house': data.has_delivery_house,
            'has_delivery_pickup': data.has_delivery_pickup,
            'has_delivery_catalog': data.has_delivery_catalog,*/
            'location_limit': data.location_limit,
            'categories': data.categories.map(category => category.id),
            'tags': data.tags.map(tag => tag.id)

            /*'delivery_price': data.delivery_price,
            'surcharge_price': data.surcharge_price,
            'minimum_price': data.minimum_price,*/
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateCatalog = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/catalog', {
            'address': data.address,
            'latitude': data.latitude,
            'longitude': data.longitude,
            'city': data.city,
            'country': data.country,

            'city_name': data.city,
            'country_name': data.country,

            'state': data.state,
            'name': data.name,
            'description': data.description,
            'description_short': data.description_short,
            'image': data.image
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateMenuItem = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/items' + (data.id ? '/' + data.id : ''), data).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const createMenuItem = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/items', data).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateMenuCollection = async (data) => {
    try {
        console.log('updateMenuCollection', data);

        const response = await ajaxClient().post('profile/menu/collections/' + data.id, {
            'name': data.name,
            'first_producer_name': data.first_producer_name,
            'first_product_name': data.first_product_name,
            'second_producer_name': data.second_producer_name,
            'second_product_name': data.second_product_name,
            'description': data.description,
            'category_id': data.category_id,
            'products': data.products,
            'menu_items': data.menu_items
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const createMenuCollection = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/collections', {
            'name': data.name,
            'description': data.description,
            'category_id': data.category_id
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const deleteMenuItem = async (data) => {
    try {
        const response = await ajaxClient().delete('profile/menu/items/' + data.id).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const deleteMenuCollection = async (data) => {
    try {
        const response = await ajaxClient().delete('profile/menu/collections/' + data.id).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateCatalogCompany = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/company', {
            'is_company': data.is_company,
            'company_name': data.company_name,
            'company_tax_id': data.company_tax_id,
            'company_address': data.company_address,
            'company_city': data.company_city,
            'company_postcode': data.company_postcode,
            'company_country': data.company_country,
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const updateCatalogOpeningHours = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/openingHours', data).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const createComment = async (catalog, data) => {
    try {
        const response = await ajaxClient().post('catalogs/' + catalog.id + '/comments', {
            'rating': data.rating,
            'body': data.body
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const sendMessage = async (catalog, data) => {
    try {
        const response = await ajaxClient().post('catalogs/' + catalog.id + '/messages', {
            'name': data.name,
            'email': data.email,
            'phone': data.phone,
            'body': data.body,
            'date': data.date,
            'menu_item_id': data.menu_item_id,
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const sendInviteMessage = async (data) => {
    try {
        const response = await ajaxClient().post('admin/sendInviteMessage', {
            'name': data.name,
            'email': data.email,
            'phone': data.phone,
            'body': data.body
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const confirmOrder = async (order, status, comment) => {
    try {
        const response = await ajaxClient().post('profile/catalog/orders/' + order.id + '/confirm', {
            'status': status,
            'comment': comment
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

/**
 * Calculate delivery price
 * @param catalog
 * @param amount
 */
export const calculateDeliveryPrice = (catalog, amount) => {
    if (!catalog || (catalog && !catalog.surcharge_price)) {
        return 0;
    }

    let total = 0;

    let surchargePrice = catalog.surcharge_price;
    let deliveryPrice = catalog.delivery_price;

    if (amount < 25) {
        total = total + surchargePrice;
    }

    return total + deliveryPrice;
}
