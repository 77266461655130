import React, {useState} from "react";
import {useUser} from "../selector";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MessageDialog from "./Profile/Dialogs/MessageDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
    },
    button: {
        [theme.breakpoints.down('md')]: {
            width: "100%"
        },
    }
}));


function SendMessage({catalog, menuItem, type}) {
    const user = useUser();
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState({
        'message': {
            open: false,
            data: null
        },
    });

    function handleOpenDialog(name, data = null) {
        setOpenDialog({
            ...openDialog,
            [name]: {
                open: true,
                data: data
            }
        });

        console.log('openAddDialog', name, data);
    }

    function handleCloseDialog(name, result) {
        console.log('handleCloseDialog', name);

        setOpenDialog({
            ...openDialog,
            [name]: {
                ...openDialog[name],
                open: false
            }
        });
    }

    if (!catalog) {
        return <div></div>
    }

    return (
        <>
            <MessageDialog open={openDialog.message.open} data={openDialog.message.data} onClose={() => handleCloseDialog('message')}/>
            {type === "offer" && (
                <Button color="primary" variant="contained" size="large" onClick={() => handleOpenDialog('message', {"catalog": catalog, "menuItem": menuItem})} className={classes.button}>
                    Zapytaj o ofertę
                </Button>
            )}
            {type === "offer-detail" && (
                <Button color="primary" variant="contained" size="large" onClick={() => handleOpenDialog('message', {"catalog": catalog, "menuItem": menuItem})} className={classes.button}>
                    Zapytaj
                </Button>
            )}
            {type === "catalog" && (
                <Button color="primary" variant="outlined" size="large" fullWidth={true} onClick={() => handleOpenDialog('message', {"catalog": catalog, "menuItem": menuItem})} className={classes.button}>
                    Napisz wiadomość
                </Button>
            )}
        </>
    );
}

export default SendMessage;
