import {useUser} from "../../../selector";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../actions/ui";
import {navigate, Redirect} from "@reach/router";
import {useFetchProfile} from "../../../actions";
import {hasError} from "../../../services/validators";
import {updateDelivery, updateCatalog} from "../../../services/catalogService";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {toNumber} from "../../../services/mainService";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import ajaxClient from "../../../services/ajaxClient";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    text:{
      marginTop: "32px"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function DeliveryForm({onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const [categories, setCategories] = useState([]);

    const fetchProfile = useFetchProfile();
    const user = useUser();

    React.useEffect(() => {
        fetchProfile();
    }, []);

    React.useEffect(() => {
        if (user && user.catalog) {
            setFormValues(user.catalog);
            console.log('formValues', user.catalog);
        }
    }, [user]);

    React.useEffect(() => {
        async function fetchCategories() {
            const response = await ajaxClient().get("categories");
            setCategories(response.data.data);
        }

        fetchCategories();
    }, []);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    function handleInputChange(event) {
        const target = event.target;

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleCategoryChange(event) {
        const target = event.target;
        let categoryId =  target.value;

        if (target.checked) {
            addCategory(categories.find(category => category.id == categoryId));
        } else {
            removeCategory(categories.find(category => category.id == categoryId));
        }
    }

    function removeCategory(categoryItem) {
        console.log('remove categoryItem', categoryItem);
        console.log('new items', formValues.categories.filter(category => category.id != categoryItem.id));

        setFormValues({
            ...formValues,
            categories: formValues.categories.filter(category => category.id != categoryItem.id)
        });
    }

    function addCategory(categoryItem) {
        console.log('add category', categoryItem);

        if (!categoryItem) {
            return;
        }

        console.log('new categories', [...formValues.categories, categoryItem]);

        setFormValues({
            ...formValues,
            categories: [...formValues.categories, categoryItem]
        });
    }

    function handleTagChange(tags, event) {
        const target = event.target;
        let tagId =  target.value;

        if (target.checked) {
            addTag(tags.find(tag => tag.id == tagId));
        } else {
            removeTag(tags.find(tag => tag.id == tagId));
        }
    }

    function removeTag(tagItem) {
        console.log('remove tagItem', tagItem);
        console.log('new items', formValues.tags.filter(tag => tag.id != tagItem.id));

        setFormValues({
            ...formValues,
            tags: formValues.tags.filter(tag => tag.id != tagItem.id)
        });
    }

    function addTag(tagItem) {
        console.log('add tag', tagItem);

        if (!tagItem) {
            return;
        }

        console.log('new tags', [...formValues.tags, tagItem]);

        setFormValues({
            ...formValues,
            tags: [...formValues.tags, tagItem]
        });
    }

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        updateDelivery(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    if (!user || !user.name) {
        return <Redirect to="/login" noThrow/>;
    }

    function categorySelected(categoryItem) {
        let item = formValues.categories.find((category) => {
            console.log('[comparision]', category.id, 'vs', categoryItem.id);
            return categoryItem.id === category.id
        });

        console.log('[comparision: found]', item);
        return !!item;
    }

    function tagSelected(tagItem) {
        let item = formValues.tags.find((tag) => {
            console.log('[comparision]', tag.id, 'vs', tagItem.id);
            return tagItem.id === tag.id
        });

        console.log('[comparision: found]', item);
        return !!item;
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className={classes.form}>

                {/*<Box>
                    <Box>Dostępne formy dostawy</Box>

                    <FormControlLabel
                        control={<Checkbox checked={formValues.has_delivery_house ?? 'checked'}
                                           onChange={handleInputChange} name="has_delivery_house"/>}
                        label="Dostawa do domu"
                    />

                    <FormControlLabel
                        control={<Checkbox checked={formValues.has_delivery_pickup ?? 'checked'}
                                           onChange={handleInputChange} name="has_delivery_pickup"/>}
                        label="Odbiór osobisty"
                    />

                    <FormControlLabel
                        control={<Checkbox checked={formValues.has_delivery_catalog ?? 'checked'}
                                           onChange={handleInputChange} name="has_delivery_catalog"/>}
                        label="Stolik u kucharza"
                    />

                </Box>*/}

                <TextField
                    type="number"
                    InputProps={{
                        inputProps: {
                            min: 0, max: 50
                        }
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="delivery"
                    label="Limit montażu w km"
                    name="location_limit"
                    autoComplete="location_limit"
                    autoFocus
                    value={toNumber(formValues.location_limit,0) || 0}
                    onChange={handleInputChange}
                    error={hasError('location_limit', errors)}
                    helperText={
                        hasError('location_limit', errors) ? errors.location_limit[0] : null
                    }
                />

                <Box my={1} className={classes.divider}>
                    <Divider variant="fullWidth"/>
                </Box>
                <DialogContentText className={classes.text}>
                    Wybierz, w jakich kategoriach realizujesz usługi.
                </DialogContentText>

                {categories && categories.map(categoryItem => {
                    return (
                        <Box>
                            <FormControlLabel
                                control={<Checkbox checked={categorySelected(categoryItem) ?? 'checked'}
                                                   onChange={handleCategoryChange} name="categories" value={parseInt(categoryItem.id)}/>}
                                label={categoryItem.name}
                            />

                            {categoryItem.tags && categoryItem.tags.map(tagItem => {
                                return (
                                    <Box ml={3}>
                                        <FormControlLabel
                                            control={<Checkbox checked={tagSelected(tagItem) ?? 'checked'}
                                                               onChange={(event) => handleTagChange(categoryItem.tags, event)} name="tags" value={parseInt(tagItem.id)}/>}
                                            label={tagItem.name}
                                        />
                                    </Box>
                                );
                            })}

                        </Box>
                    );
                })}

                {/*<Button
                    variant={formValues.category === categoryItem.name ? 'outlined' : "contained"}
                    color="default"
                    key={categoryItem.id}
                    className={classes.button}
                    onClick={() => navigate(process.env.PUBLIC_URL + getQueryLinkByType({...formValues, 'category': categoryItem.name}))}
                >
                    {categoryItem.name}
                </Button>*/}

                {/*<TextField
                    type="number"
                    InputProps={{
                        inputProps: {
                            min: 0, max: 50
                        }
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="delivery"
                    label="Koszt dostawy"
                    name="delivery_price"
                    autoComplete="delivery_price"
                    autoFocus
                    value={toNumber(formValues.delivery_price,0) || 0}
                    onChange={handleInputChange}
                    error={hasError('delivery_price', errors)}
                    helperText={
                        hasError('delivery_price', errors) ? errors.delivery_price[0] : null
                    }
                />*/}

                {/*<TextField
                    type="number"
                    InputProps={{
                        inputProps: {
                            min: 0, max: 30
                        }
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="surcharge_price"
                    label="Dopłata do zamówienia poniżej 25zł"
                    name="surcharge_price"
                    autoComplete="surcharge_price"
                    autoFocus
                    value={toNumber(formValues.surcharge_price,0) || 0}
                    onChange={handleInputChange}
                    error={hasError('surcharge_price', errors)}
                    helperText={
                        hasError('surcharge_price', errors) ? errors.surcharge_price[0] : null
                    }
                />*/}

                {/*<TextField
                    type="number"
                    InputProps={{
                        inputProps: {
                            min: 0, max: 50
                        }
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="minimum_price"
                    label="Minimalna wartość zamówienia"
                    name="minimum_price"
                    autoComplete="minimum_price"
                    autoFocus
                    value={toNumber(formValues.minimum_price,0) || 0}
                    onChange={handleInputChange}
                    error={hasError('minimum_price', errors)}
                    helperText={
                        hasError('minimum_price', errors) ? errors.minimum_price[0] : null
                    }
                />*/}

                <Box my={1}>
                    <Divider variant="fullWidth"/>
                </Box>

                {!hideSubmitButton && (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isFormProcessed}
                    >
                        {!isFormProcessed ? <Box>Zapisz</Box> : <Box>Wysyłanie...</Box>}
                    </Button>
                )}
            </form>
        </div>
    );
}

export default DeliveryForm;
