import React, {useState} from "react";
import ajaxClient from "../../services/ajaxClient";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {navigate} from "@reach/router";
import Box from "@material-ui/core/Box";
import {getOffersQueryLink, getQueryLink, parseQuery} from "../../services/localizationService";
import {FONTS} from "../../Theme";
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
    categories: {
        paddingBottom: theme.spacing(2),
        '& a': {
            display: 'block',
            font: 'normal normal normal 16px/18px ' + FONTS.fontFamily,
            letterSpacing: '0.47px',

            paddingTop: '5px',
            paddingBottom: '5px'
        }
    },
    categoriesBox: {

    },
    button: {
        maxHeight: 40,
        borderColor:'#DDDDDD',

    },
    current: {
        color: 'black',
        fontWeight: 'bold'
    },
    formControl: {
        width: '100%',
        minWidth: 160,
        maxWidth: 160
    },
}));


function MonthlyPriceFilter({query = null, type = "catalogs"}) {
    const classes = useStyles();

    console.log('[query category]', query);

    const [categories, setCategories] = useState([]);
    const [formValues, setFormValues] = useState(null);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setFormValues(parseQuery(query));
    }, [query]);

    React.useEffect(() => {
        async function fetchCategories() {
            const response = await ajaxClient().get("categories");
            setCategories(response.data.data);
        }

        fetchCategories();
    }, []);

    const getQueryLinkByType = (data) => {
        if (type === "catalogs") {
            return getQueryLink(data);
        } else {
            return getOffersQueryLink(data);
        }
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const handleSubmit = () => {
        navigate(process.env.PUBLIC_URL + getQueryLinkByType({...formValues}));
        setOpen(false);
    };

    return (
        <section className={classes.categories}>
            <Button onClick={handleClickOpen} size={"large"} variant="outlined" className={classes.button}>Rachunek za prąd {formValues && formValues.monthly_price ? formValues.monthly_price + "zł" : ""}</Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>Rachunek za prąd</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <TextField
                        id="monthly_price"
                        name="monthly_price"
                        type="number"
                        label={"Miesięczny rachunek"}
                        variant={"outlined"}

                        value={(formValues && formValues.monthly_price) ? formValues.monthly_price : ''}
                        onChange={handleInputChange}
                        className={classes.select}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">zł</InputAdornment>,
                        }}
                        fullWidth
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="secondary" variant="contained" size="medium">Anuluj</Button>
                    <Button onClick={handleClose} color="primary" variant="contained" autoFocus size="medium">Zmień</Button>
                </DialogActions>
            </Dialog>
        </section>
    );
}

export default MonthlyPriceFilter;
