import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {FONTS} from "../../Theme";
import Link from "@material-ui/core/Link";
import {assetsPath} from "../../services/mainService";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    link: {
        paddingBottom: theme.spacing(2),

        display: 'flex !important',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        font: 'normal normal normal 16px/18px ' + FONTS.fontFamily,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),

        fontWeight: 400,
        fontSize: 14,
        lineHeight: "150%",
        color: "#1D2939",
        letterSpacing: "0.008em",
        minWidth: 107,

        '& a': {

        },

        '&:hover': {

        }
    },
    title: {

    },

    img: {
        height: '20px',
    }
}));


function CategoryIcon({href, category}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Link href={href} className={classes.link}>
                {/*<img src={assetsPath("icons/categories/urynoterapia.svg")} className={classes.img}/>*/}
                <img src={assetsPath("icons/categories/" + category.slug + ".svg")} className={classes.img}/>

                <p>{category.name}</p>
                {/*<img src={assetsPath("icons/categories/" + category.slug + ".svg")} className={classes.img}/> {category.name}*/}
            </Link>
        </div>
    );
}

export default CategoryIcon;
