import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Price from "../Price";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Power from "../Power";
import {APP_SITE, COLORS, FONTS} from "../../Theme";
import {Link} from "@reach/router";
import SendMessage from "../SendMessage";


const useStyles = makeStyles((theme) => ({
    details: {
        borderRadius: 6,
        border: '1px solid #dee2e6!important',
        boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)!important',
        width: '100%',
        display: 'flex'
    },

    catalog_url: {
        textDecoration: 'none',
        color: '#757575',
    },
    cardContent: {
        padding: '15px',
    },
    catalog_name: {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "150%",
        color: "#757575"
    },
    header: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "150%",
        display: "flex",
        alignItems: "center",
        color: COLORS.secondary,
        textTransform: "uppercase",
        marginBottom: 15
    },
    catalog_description: {
        font: 'normal normal normal 16px/29px ' + FONTS.fontFamily,
        letterSpacing: '0.14px',
        color: '#757575',
        marginTop: '9px',
        whiteSpace: 'pre-line'
    },
    cover: {
        width: 120,
        height: 120,
        borderRadius: "6px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
            height: 120,
        },
    },
    properties: {
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "150%",
        color: "#03A600"
    },
    separator: {
        width: "0px", height: "90px", left: -10, top: 16, border: "1px solid #DDDDDD",
        position: "absolute",
        [theme.breakpoints.down('sm')]: {
            position: "unset",
            width: "100%",
            height: "0px",
            border: "1px solid #DDDDDD",
            marginTop: -12,
            marginBottom: 12
        },
    },
    relative: {
        position: "relative",
    },
    truncate: {
        width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
    },
    button: {
        [theme.breakpoints.down('md')]: {
            textAlign: "center",
            padding: 15
        },
    }
}));

function OfferMenuItem({menuItem}) {
    const classes = useStyles();

    return (
        <Grid container className={classes.details}>
            {menuItem.image_filename_url && (
                <Link to={`${menuItem.catalog.url}`}>
                    <CardMedia
                        className={classes.cover}
                        image={menuItem.image_filename_url + "?w=170&h=170"}
                        title={menuItem.name}
                    />
                </Link>
            )}
            <Grid md={3} xs={12} sm={12}>
                <Box ml={0} p={2}>
                    {APP_SITE === 'jakieoze' && (
                        <Typography component="p" variant="body2" className={classes.header}>
                            Instalator:
                        </Typography>
                    )}

                    {APP_SITE === 'paramedycy' && (
                        <Typography component="p" variant="body2" className={classes.header}>
                            Oferta:
                        </Typography>
                    )}
                    <Typography component="p" variant="body1" className={classes.catalog_name}>
                        <Link to={`${menuItem.catalog.url}`}
                              className={classes.catalog_name}>{menuItem.catalog.name}</Link><br/>
                        <small>{menuItem.name}</small>
                    </Typography>
                </Box>
            </Grid>
            <Grid md={3} xs={12} sm={12}>
                <Box ml={0} p={2} className={classes.relative}>
                    <div className={classes.separator}></div>
                    {APP_SITE === 'jakieoze' && (<>
                        <Typography component="p" variant="body2" className={classes.header}>
                            Instalacja:
                        </Typography>
                        {menuItem && menuItem.category && menuItem.category.type == 'pv' && (
                            <>
                                <Typography component="p" variant="body1" className={classes.truncate}>
                                    {menuItem.elements && (
                                        <>
                                            {menuItem.elements} x{" "}
                                        </>
                                    )}
                                    Panel: <b>{menuItem.collection.first_producent_name} {menuItem.collection.first_product_name}</b><br/>
                                    {menuItem.inverter_phase_count && (
                                        <>
                                            {menuItem.inverter_phase_count} x{" "}
                                        </>
                                    )}
                                    Inwerter: <b>{menuItem.collection.second_producent_name} {menuItem.collection.second_product_name}</b><br/>
                                </Typography>
                            </>
                        )}
                    </>)}

                    {menuItem && menuItem.category && menuItem.category.type != 'pv' && (
                        <>
                            <Typography component="p" variant="body2" className={classes.header}>
                                Usługa:
                            </Typography>
                            <Typography component="p" variant="body1" className={classes.truncate}>
                                {menuItem.description}
                            </Typography>
                        </>
                    )}

                </Box>
            </Grid>
            <Grid md={4} xs={12} sm={12}>
                <Grid container>
                    <Grid md={6} xs={12} sm={12}>
                        <Box ml={0} p={2} className={classes.relative}>
                            <div className={classes.separator}></div>
                            {APP_SITE === 'jakieoze' && (
                                <>
                                    <Typography component="p" variant="body2" className={classes.header}>
                                        Cena i moc:
                                    </Typography>
                                    <Typography component="p" variant="body1" className={classes.properties}>
                                        Cena:&nbsp;<b><Price value={menuItem.price} round={true}/></b><br/>
                                        Moc:&nbsp;<b><Power value={menuItem.power}/></b>
                                    </Typography>
                                </>
                            )}

                            {APP_SITE !== 'jakieoze' && (
                                <>
                                    <Typography component="p" variant="body2" className={classes.header}>
                                        Cena:
                                    </Typography>
                                    <Typography component="p" variant="body1" className={classes.properties}>
                                        Cena:&nbsp;<b><Price value={menuItem.price} round={true}/></b><br/>
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Grid>



                    <Grid md={6} xs={12} sm={12}>
                        <Box pt={5} align={"right"} className={classes.button}>
                            {/*<small>nr oferty: {menuItem.id}</small>*/}
                            <SendMessage catalog={menuItem.catalog} menuItem={menuItem} type={"offer"}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OfferMenuItem;
