import ajaxClient from "./ajaxClient";
import {useLocation} from "@reach/router";

function getLocalizationDataQuery(data) {
    var str = [];
    for (var item in data) {
        str.push(encodeURIComponent(item) + "=" + encodeURIComponent(data[item]));
    }

    return str.join("&");
}

export const parseQuery = (search) => {
    const query = new URLSearchParams(
        search
    );

    let data= {};

    for (let params of query.entries()) {
        data[params[0]] = params[1];
    }

    console.log('[parseQuery]', search, data);

    return data;
}

export const getQuery = (data) => {
    console.log('getQuery', data);
    return getLocalizationDataQuery(data);
}

export const getQueryLink = (data) => {
    return '/s/' + getQuery(data);
}

export const getOffersQueryLink = (data) => {
    return '/so/' + getQuery(data);
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
