import React from 'react';
import axios from 'axios';
import {navigate} from "@reach/router";

const API_URL = process.env.REACT_APP_API_URL + "/api/";

const ajaxClient = () => {
    const session = localStorage.getItem('session');

    const defaultOptions = session !== null ? {
        headers: {
            Authorization: 'Bearer ' + session
        },
        validateStatus: function (status) {
            if (status === 401) {
                localStorage.removeItem('session');
                localStorage.removeItem('profile');
                //console.log('navigate to login');
                //navigate("/login");
                /*if (profile && profile.role !== 'guest') {
                    localStorage.removeItem('session');
                    localStorage.removeItem('profile');
                    window.location.href = routeUrl('/auth/login');
                }*/
            }
            if (status === 403) {
                window.location.href = navigate(process.env.PUBLIC_URL + '/errors/error-401');
            }

            return status >= 200 && status < 300; // default
        },
    } : {};

    return {
        get: (url, options = {}) => axios.get(API_URL + url, {...defaultOptions, ...options}),
        post: (url, data, options = {}) => axios.post(API_URL + url, data, {...defaultOptions, ...options}),
        put: (url, data, options = {}) => axios.put(API_URL + url, data, {...defaultOptions, ...options}),
        delete: (url, options = {}) => axios.delete(API_URL + url, {...defaultOptions, ...options}),
    };
};

export default ajaxClient;
