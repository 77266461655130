import React, {useState} from "react";
import {useCart, useCartCatalogTotal} from "../selector";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import CatalogOrders from "./CatalogOrders";
import CatalogOrdersAmount from "./CatalogOrdersAmount";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import {navigate} from "@reach/router";
import {useAddCatalogId} from "../actions";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import background from "../assets/minicart/fastfood-24px.png"
import {calculateDeliveryPrice} from "../services/catalogService";
import Price from "./Price";
import {Tooltip} from "@material-ui/core";
import {FONTS} from "../Theme";


const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 6,
        marginBottom: theme.spacing(2),
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
    },
    order__title: {
        textAlign: 'center',
        font: 'normal normal bold 34px/41px ' + FONTS.fontFamily,
        color: '#757575',
        marginTop: '15px'
    },
    order__subtitle: {
        color: '#757575',
        font: 'normal normal normal 16px/29px ' + FONTS.fontFamily,
        marginTop: '10px'
    },
    order___subtitle: {
        color: '#757575',
        font: 'normal normal normal 18px/29px ' + FONTS.fontFamily,
        marginTop: '10px',
        display: "inline-flex",
        borderColor:"#B7B7B7"
    },
    order_button: {
        width: "100%",
        height: "50px"
    },
    order_box: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    motordelivery: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: '#FA4A0C'
    },

    background: {
        minHeight: 280,
        backgroundRepeat: "no-repeat",
        background: 'transparent',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',

    }
}));

function MiniContact({catalog, showOrderButton = false, delivery = null}) {

    const classes = useStyles();
    const cartProducts = useCart();
    const addCatalogId = useAddCatalogId();
    const amount = useCartCatalogTotal(catalog.id);

    const deliveryPrice = calculateDeliveryPrice(catalog, amount)
    const total = amount + deliveryPrice;

    const [user, setUser] = useState(null);

    function order() {
        addCatalogId(catalog.id);
        navigate(process.env.PUBLIC_URL + '/order');
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography align="center" className={classes.order__title}>
                    Kontakt
                </Typography>
            </CardContent>

            <Box pt={1} pb={3} pl={4} pr={4} className={classes.order_box}>
                <Link
                    onClick={order}>
                    <Button variant="contained" size="large" color="secondary" className={classes.order_button}>
                        Napisz wiadomość
                    </Button>
                </Link>
            </Box>
        </Card>
    )
}

export default MiniContact;
