import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {APP_SITE, COLORS, FONTS} from "../../../../Theme";
import CheckIcon from "../../../../assets/icons/check-24px.svg";
import Power from "../../../Power";
import Price from "../../../Price";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
    service_box: {
        borderRadius: "6px",
        boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.15)",
        marginTop: "16px",
    },
    title:{
      fontWeight:"600",
    },
    header: {
        textTransform: "uppercase"
    },
    properties: {
        fontFamily: FONTS.fontFamily, fontStyle: "normal", fontWeight: "700", fontSize: "16px", lineHeight: "150%", color: COLORS.default
    },

    properties_price: {
        marginTop:"8px",
        fontFamily: FONTS.fontFamily, fontStyle: "normal", fontWeight: "400", fontSize: "16px", lineHeight: "150%", color: COLORS.primary
    },

    separator: {
        width: "0px", height: "90px", left: -10, top: 16, border: "1px solid #DDDDDD",
        position: "absolute",
        [theme.breakpoints.down('sm')]: {
            position: "unset",
            width: "100%",
            height: "0px",
            border: "1px solid #DDDDDD",
            marginTop: -12,
            marginBottom: 16
        },
    },
    info:{
      display:"flex",
      alignItems:"center"
    },
    icon:{
      marginRight:"8px"
    },
    relative: {
        position: "relative",
    },
    label:{
      marginTop:"8px",
        fontWeight:"700",
        color: COLORS.default,

    },
}));

function MessageCalendarSelectedOffer({menuItem, date}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Box p={4}>
            <Typography className={classes.title}>Wybrana oferta</Typography>

            <Box p={2} className={classes.service_box}>
                <Grid container m={2}>
                    <Grid md={6} xs={12} sm={12}>
                        <Box ml={0} p={2} className={classes.relative}>
                            <Box className={classes.info}>
                            <InfoOutlinedIcon className={classes.icon}/>
                            <Typography component="p" variant="body2" className={classes.header}>
                                Usługa:
                            </Typography>
                        </Box>

                            {menuItem && APP_SITE === 'paramedycy' && (
                                <Typography component="p" variant="body1" className={classes.label}>
                                    {menuItem.name}
                                </Typography>
                            )}

                            {menuItem && APP_SITE === 'jakieoze' && (
                                <Typography component="p" variant="body1" className={classes.label}>
                                    <img src={CheckIcon}/>&nbsp;Moc:&nbsp;<span><Power value={menuItem.power}/></span>
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid md={6} xs={12} sm={12}>
                        <Box ml={0} p={2} className={classes.relative}>
                            <div className={classes.separator}></div>
                            <Typography component="p" variant="body2" className={classes.header}>
                                Cena:
                            </Typography>
                            <Typography component="p" variant="body1" className={classes.properties_price}>
                                od&nbsp;<b><Price value={menuItem.price} round={true}/></b><br/>
                                <br/>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default MessageCalendarSelectedOffer;
