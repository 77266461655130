import ajaxClient from "./ajaxClient";

export const updatePremiumPhone = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/premiumPhone', {
            'name': data.name,
            'description': data.description,
            'phone': data.phone,
            'bank': data.bank,
            'bank_nr': data.bank_nr,
            'bank_details': data.bank_details,
            'image': data.image
        }).then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const disablePremiumPhone = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/premiumPhone/disable').then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const enablePremiumPhone = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/premiumPhone/enable').then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const holdPremiumPhone = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/premiumPhone/hold').then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}

export const unholdPremiumPhone = async (data) => {
    try {
        const response = await ajaxClient().post('profile/menu/premiumPhone/unhold').then(response => response);

        return response;
    } catch (error) {
        return error.response;
        //throw new Error(error.message)
    }
}
