import React from 'react'

import {
    StaticGoogleMap,
    Marker
} from 'react-static-google-map';
import {makeStyles, useTheme} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {

    },
    staticMap: {
        width: '100%'
    }
}));

function GeoStaticMap({onMapLoaded, position = null}) {
    const theme = useTheme();
    const classes = useStyles();

    console.log('[AC]: position', position);

    let location = position.lat + "," + position.lng;

    return (
        <div>
                <StaticGoogleMap size="430x260" className={classes.staticMap} apiKey="AIzaSyAlCyDwx8W6CXQvVYDcZJO8Wkkvg-c2isI">
                    <Marker location={location} Offlocation="6.4488387,3.5496361" color="blue" label="P" />
                </StaticGoogleMap>
        </div>
    )
}

export default React.memo(GeoStaticMap)
