import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import OrderItemProducts from "../OrderItemProducts";
import Price from "../../Price";
import OrderProgress from "../../Order/OrderProgress";
import Loader from "../../Loader";
import Box from "@material-ui/core/Box";
import {FONTS} from "../../../Theme";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    header: {
        fontSize: '18px',
        fontWeight: 'normal',
        color: '#757575',
        textTransform: 'capitalize',
        display: 'flex',
        flexBasis: '100%',
        borderBottom: '1px solid lightgrey'
    },
    imageWithText: {
        backgroundColor: "white",
        display: 'flex',
        alignItems: 'top',
        fontSize: '14px',
        fontFamily: FONTS.fontFamily,
        paddingTop: 10
    },
    imageWithTextCardMedia: {
        width: 64,
        height: 64,
        borderRadius: 8
    },
    imageWithTextId: {
        fontSize: '14px',
        marginLeft: 5,
        fontWeight: '400'
    },

    address: {
        fontSize: '14px',
        paddingTop: 10,
        fontFamily: FONTS.fontFamily,
        fontWeight: '300'
    },

    products: {
        display: 'flex',
        alignItems: 'top',
        fontFamily: FONTS.fontFamily,
        fontSize: '14px',
        paddingTop: 10
    },
    price: {
        paddingTop: 10,
    },
    priceItem: {
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'space-between',
        fontFamily: FONTS.fontFamily,
        fontSize: '14px',
        paddingTop: 10,
        textAlign: 'right',
        fontWeight: '500'
    },
    summary: {
        fontSize: '14px',
        fontWeight: '500',
    },
    delivery: {
        fontFamily: FONTS.fontFamily,
        fontSize: '14px',
        paddingTop: 10,
    },
    payment: {
        fontFamily: FONTS.fontFamily,
        fontSize: '14px',
        paddingTop: 10,
    },
    row: {
        marginTop: 8
    }
}));

function StaticOrderForm({order, type = 'client'}) {
    const classes = useStyles();

    if (!order) {
        return <Loader/>
    }

    console.log('order contents: ', order);

    return (
        <div className={classes.root}>
            <OrderProgress order={order} type={type}/>

            <Grid container spacing={2} className={classes.row}>
                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Identyfikator
                    </Typography>

                    <div className={classes.imageWithText}>
                        <CardMedia className={classes.imageWithTextCardMedia} image={order.catalog.image_filename_url}/>
                        <Typography className={classes.imageWithTextId}>{order.id}</Typography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Podsumowanie
                    </Typography>

                    <div className={classes.products}>
                        <OrderItemProducts items={order.items}/>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.row}>
                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Forma dostawy
                    </Typography>
                    <div className={classes.delivery}>
                        {order.delivery ? order.delivery.name : ''}
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Forma płatności
                    </Typography>
                    <div className={classes.payment}>
                        {order.payment.name}
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.row}>
                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Adres dostawy
                    </Typography>
                    <div className={classes.address}>
                        {order.address}
                        <Box>tel.{order.phone}</Box>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="p" className={classes.header}>
                        Zestawienie kosztów
                    </Typography>
                    <div className={classes.price}>
                        <Box className={classes.priceItem}>
                            <Typography component="h3" className={classes.summary}>Koszt produktów</Typography>
                            <Price value={order.price}/>
                        </Box>
                        <Box className={classes.priceItem}>
                            <Typography component="h3" className={classes.summary}>Koszt dostawy</Typography>
                            <Price value={order.delivery_price + order.surcharge_price}/>
                        </Box>
                        <Box className={classes.priceItem}>
                            <Typography component="h3" className={classes.summary}>Łącznie</Typography>
                            <Price value={order.total_price}/>
                        </Box>
                    </div>
                </Grid>
            </Grid>

            {type == 'client' && (
                <Grid container spacing={2} className={classes.row}>
                    <Grid item xs={6}>
                        <Typography component="p" className={classes.header}>
                            Kontakt z instalatorem
                        </Typography>
                        <div className={classes.address}>
                            {order.catalog.user.name}
                            <Box>tel.{order.catalog.user.phone}</Box>
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default StaticOrderForm;
