import React, {useState} from "react";
import './HeaderBar.css';
import {navigate} from "@reach/router";
import {useCartReset, useFetchProfile, useLogout} from "../actions/index";
import Link from "@material-ui/core/Link";

import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

import {useCatalogId, useUser} from "../selector";
import Box from "@material-ui/core/Box";
import {Dialog, Hidden} from "@material-ui/core";
import {getProfile} from "../services/userService";
import {useDispatch} from "react-redux";
import {assetsPath} from "../services/mainService";
import {APP_SITE, COLORS, FONTS} from "../Theme";
import {LANGS} from "../Langs";
import Divider from "@material-ui/core/Divider";
import {ListItemIcon, MenuItem} from "@mui/material";
import SendIcon from '@material-ui/icons/Send';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingTop: 56
    },
    headerButton: {
        marginRight: theme.spacing(0),
    },
    rightSection: {
        marginLeft: 'auto',
    },
    title: {
        marginTop: 13,
        marginLeft: 7
    },
    headbar: {
        height: '56px',
        minHeight: '56px',
        backgroundColor: 'white',
    },
    userName: {
        textTransform: 'none',
        color: COLORS.primary,
        padding: '6px',
        fontSize: '0.875rem',
        minWidth: '56px',
        boxSizing: 'border-box',
        backgroundColor: '250ms',
        cubicBezier: '(0.4, 0, 0.2, 1) 0ms',
        boxShadow: '250ms',
        border: '250ms',
        fontFamily: FONTS.fontFamily,
        fontWeight: '500',
        lineHeight: '1.75',
        borderRadius: '8px',
        letterSpacing: '0.53',
        zIndex: 1002,

        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 120
    },
    button: {
        textTransform: 'none',
        color: COLORS.default,
        padding: '6px',
        fontSize: '1rem',
        minWidth: '32px',
        boxSizing: 'border-box',
        backgroundColor: '250ms',
        cubicBezier: '(0.4, 0, 0.2, 1) 0ms',
        boxShadow: '250ms',
        border: '250ms',
        fontFamily:  FONTS.fontFamily,
        fontWeight: '400',
        lineHeight: '1.75',
        borderRadius: '8px',
        letterSpacing: '0.53',
        zIndex: 1002
    },

    menuContent: {
        width: '330px',
        outline: "none"
    },
    menuButton: {
        fontSize: '14px/24px',
        fontFamily: FONTS.fontFamily,
        paddingTop: '0px 8px',
        color: COLORS.primary,
        fontWeight: 'bold',
        letterSpacing: '0.15px'
    },
    menuTitle: {
        font: 'normal normal normal 16px ' + FONTS.fontFamily,
        letterSpacing: '0.22px',
        color: '#757575'
    },

    menuItem: {
        /*hoverColor: 'red',*/
        '&:hover': {
            backgroundColor: '#ECECEC',
            borderRadius: '4px',
        },
        '& > span': {
            width: '100%',
            'display': 'block',
            '& > a': {
                width: '100%',
                'display': 'block'
            },
        },
        width: '100%',
        borderRadius: 15,
        paddingTop: "12px !important",
        paddingBottom: "12px !important"

    },
    
    menuSubtitle: {
        fontFamily: FONTS.fontFamily,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: COLORS.default,
        paddingTop: '3px',

    },

    menuSubButton: {
        color: '#7C7C7C',
        letterSpacing: '0.15px',
        fontFamily: FONTS.fontFamily,
        fontSize: '16px',
        lineHeight: '24px',
        marginLeft: theme.spacing(1),
        fontWeight: '400',

    },

    menuSubButtonVerify: {
        display: 'inline',
        marginLeft: theme.spacing(1),
        color: COLORS.primary,
        fontFamily: FONTS.fontFamily,
        opacity: '0.2'
    },

    logo: {
        maxWidth: 160,
        marginRight: '10px',
    }
}));


function HeaderBar({routePath, titleBar}) {
    const [showDialog, setShowDialog] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [auth, setAuth] = React.useState(true);
    const classes = useStyles();

    const dispatch = useDispatch();
    const fetchProfile = useFetchProfile();
    const user = useUser();
    const logout = useLogout();
    const reset = useCartReset();
    const catalogId = useCatalogId();

    const REACT_APP_SITE_URL = process.env.REACT_APP_SITE_URL;

    const open = Boolean(anchorEl);

    function reloadUser() {
        getProfile().then(response => {
            console.log('[Orders] setUser');
        })
    }

    React.useEffect(() => {
        fetchProfile();
    }, []);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleYes() {
        setShowDialog(false);
        reset();
        navigate(process.env.PUBLIC_URL + "/");
    }

    console.log('[USER]', user);

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar className={classes.headbar}>

                    {<Typography variant="h6" className={classes.title}>
                        <Link href={REACT_APP_SITE_URL} onClick={() => navigate(REACT_APP_SITE_URL)}>
                            <img src={assetsPath('logo.png')} className={classes.logo}/>

                            {/*<Image src={'logo.png'} className={classes.logo}/>*/}
                            {/*<img src={Logo} className={classes.logo}/>*/}
                        </Link>
                    </Typography>}

                    {/*<Button onClick={() => navigate(REACT_APP_SITE_URL)} className={classes.button}>Baza wiedzy</Button>*/}

                    <Button onClick={() => navigate(process.env.PUBLIC_URL + "/offers")}
                            className={classes.button}>Oferty</Button>

                    <Button onClick={() => navigate(process.env.PUBLIC_URL + "/catalogs")}
                            className={classes.button}>{LANGS.headerBarCatalogsName}</Button>

                    <Hidden xsDown>
                        <Button onClick={() => navigate(REACT_APP_SITE_URL)} className={classes.button}>Blog</Button>
                    </Hidden>

                    <div className={classes.rightSection}>
                        {(user && user.id) ? (
                            <Box>
                                <IconButton className={classes.button}
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleMenu}
                                >
                                    <Hidden mdDown>
                                        <Typography className={classes.userName}>{user.name}</Typography>
                                    </Hidden>

                                    <AccountCircleOutlinedIcon/>
                                </IconButton>

                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >

                                    <Box className={classes.menuContent} pl={1} pr={1} pt={1} pb={1}>

                                        <MenuItem className={classes.menuItem}>
                                            <ListItemIcon>
                                                <AccountCircleOutlinedIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                                <Link href="/profile/messages" className={classes.menuTitle}>
                                                    Wiadomości
                                                </Link>
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem className={classes.menuItem}>
                                            <ListItemIcon>
                                                <AccountCircleOutlinedIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                                <Link href="/profile/personal" className={classes.menuTitle}>
                                                    Twój profil
                                                </Link>
                                            </Typography>
                                        </MenuItem>

                                        <MenuItem className={classes.menuItem}>
                                            <ListItemIcon>
                                                <PhoneOutlinedIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                                <Link href="/profile/contact" className={classes.menuTitle}>
                                                    Twój telefon {!user.phone_verified && (
                                                    <Box className={classes.menuSubButtonVerify}>
                                                        zweryfikuj
                                                    </Box>
                                                )}
                                                </Link>
                                            </Typography>
                                        </MenuItem>

                                        {user && (user.type === 'catalog' || user.type == 'admin') && (
                                            <Box>


                                                <MenuItem className={classes.menuItem}>
                                                    <ListItemIcon>
                                                        <ReceiptOutlinedIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">
                                                        <Link href="/profile/catalog/company" className={classes.menuTitle}>
                                                            Dane do faktury
                                                        </Link>
                                                    </Typography>
                                                </MenuItem>

                                                <Divider variant="fullWidth"/>

                                                <MenuItem className={classes.menuItem}>
                                                    <ListItemIcon>
                                                        <LocalOfferOutlinedIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">
                                                        <Link href="/profile/menu" className={classes.menuTitle}>
                                                            Zarządzaj ofertami
                                                        </Link>
                                                    </Typography>
                                                </MenuItem>
                                                {/*<Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                    <Box className={classes.menuTitle}>Zapytanie ofertowe</Box>
                                                    <Link href="/profile/catalog/orders" className={classes.menuButton}>
                                                        Przejdź
                                                    </Link>
                                                </Box>*/}



                                            </Box>
                                        )}

                                        {user && user.type === 'client' && (
                                            <Box>
                                                {/*<MenuItem className={classes.menuItem}>
                                                    <ListItemIcon>
                                                        <SendIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">
                                                        <Link href="/profile/delivery" className={classes.menuTitle}>
                                                            Dane dostawy
                                                        </Link>
                                                    </Typography>
                                                </MenuItem>
                                                <MenuItem className={classes.menuItem}>
                                                    <ListItemIcon>
                                                        <SendIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">
                                                        <Link href="/profile/orders" className={classes.menuTitle}>
                                                            Moje zamówienia
                                                        </Link>
                                                    </Typography>
                                                </MenuItem>
                                                <MenuItem className={classes.menuItem}>
                                                    <ListItemIcon>
                                                        <SendIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">
                                                        <Link href="/profile/company" className={classes.menuTitle}>
                                                            Dane do Faktury
                                                        </Link>
                                                    </Typography>
                                                </MenuItem>*/}
                                            </Box>
                                        )}

                                        {user && user.type === 'admin' && (
                                            <MenuItem className={classes.menuItem}>
                                                <ListItemIcon>
                                                    <GroupOutlinedIcon fontSize="small" />
                                                </ListItemIcon>
                                                <Typography variant="inherit">
                                                    <Link href="/admin/catalogs" className={classes.menuTitle}>
                                                        Zarządzanie klientami
                                                    </Link>
                                                </Typography>
                                            </MenuItem>
                                        )}
                                        <Divider variant="fullWidth"/>

                                        <MenuItem className={classes.menuItem}>
                                            <ListItemIcon>
                                                <ExitToAppOutlinedIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                                <Link href="#" onClick={logout} className={classes.menuTitle}>
                                                    Wyloguj
                                                </Link>
                                            </Typography>
                                        </MenuItem>

                                    </Box>

                                </Menu>
                                {/*<div className='header_center'>
                                    <input type="text"/>
                                    <SearchIcon />
                                </div>*/}
                            </Box>
                        ) : (
                            <Box>
                                <Hidden smDown>
                                    <Button onClick={() => navigate(process.env.PUBLIC_URL + "/login")}
                                            className={classes.button}>Zaloguj się</Button>

                                    {APP_SITE === 'jakieoze' && (
                                        <Button onClick={() => navigate(process.env.PUBLIC_URL + "/catalogs")}
                                            className={classes.button} variant="outlined" color="primary">Dla Instalatorów</Button>
                                    )}


                                </Hidden>
                                <Hidden smUp>
                                    <IconButton className={classes.button}
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                onClick={handleMenu}
                                    >
                                        <MenuIcon/>
                                    </IconButton>

                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}

                                        keepMounted
                                        open={open}
                                        onClose={handleClose}

                                        className={classes.menu}

/*                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                top: '58px !important',
                                                mt: 3,
                                                ml: 0,
                                                minWidth: "100%",
                                                width: '100%',
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 58,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}*/
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                                    >
                                        <Box className={classes.menuContent} pl={1} pr={1} pt={1} pb={1}>
                                            {APP_SITE === 'jakieoze' && (
                                                <Box>
                                                    <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                        <Link onClick={() => navigate(process.env.PUBLIC_URL + "/offers")} className={classes.menuTitle} >Oferty</Link>
                                                    </Box>
                                                </Box>
                                            )}

                                            <Box>
                                                <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                    <Link onClick={() => navigate(process.env.PUBLIC_URL + "/catalogs")} className={classes.menuTitle}>{LANGS.headerBarCatalogsName}</Link>
                                                </Box>
                                            </Box>

                                            <Box>
                                                <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                    <Link onClick={() => navigate(REACT_APP_SITE_URL)}  className={classes.menuTitle}>Blog</Link>
                                                </Box>
                                            </Box>

                                            <Box>
                                                <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                    <Link onClick={() => navigate(process.env.PUBLIC_URL + "/login")} className={classes.menuTitle}>Zaloguj się</Link>
                                                </Box>
                                            </Box>

                                            {APP_SITE === 'jakieoze' && (
                                                <Box>
                                                    <Box pt={2} display={"flex"} justifyContent={"space-between"}>
                                                        <Link onClick={() => navigate(process.env.PUBLIC_URL + "/login")} className={classes.menuTitle}>Dla Instalatorów</Link>
                                                    </Box>
                                                </Box>
                                            )}

                                        </Box>
                                    </Menu>
                                </Hidden>

                                {/*<div className='header_center'>
                                    <input type="text"/>
                                    <SearchIcon />
                                </div>*/}
                            </Box>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default HeaderBar;
