
import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ajaxClient from "../../services/ajaxClient";
import {useDispatch} from "react-redux";
import HeaderBar from "../../components/HeaderBar";
import {showSuccessSnackbar} from "../../actions/ui";
import {Redirect} from "@reach/router";
import {getProfile, updatePersonal} from "../../services/userService";
import {hasError} from "../../services/validators";
import {CircularProgress, Paper} from "@material-ui/core";
import Auth from "./Auth";
import {useFetchProfile} from "../../actions";
import Footer from "../Footer";
import BoxLayout from "../../components/Layouts/BoxLayout";
import Box from "@material-ui/core/Box";
import {COLORS, FONTS} from "../../Theme";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4),
        marginBottom: '74.2px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    h1: {
        textAlign: 'center',

        font: ' normal normal bold 24px/24px ' + FONTS.fontFamily,
        letterSpacing: '0.23px',
        color: COLORS.default
    }
}));

function Personal() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    React.useEffect(() => {
        reloadUser();
    }, []);

    function reloadUser() {
        getProfile().then(response => {
            let data = response.data;
            setFormValues(data);
            dispatch({ type: "PROFILE", data });
        })
    }

    function handleInputChange(event) {
        const target = event.target;

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleSubmit(event) {
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);
        setErrors({});

        updatePersonal(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
            }

            setIsFormProcessed(false);
        });
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/profile/personal" titleBar=""/>
            <BoxLayout>

                <Auth>
                    <CssBaseline />


                        <form onSubmit={handleSubmit} className={classes.form}>
                            <h1 className={classes.h1}>Twój profil</h1>

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="name"
                                label="Imię i nazwisko"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                value={formValues.name || ''}
                                onChange={handleInputChange}
                                error={hasError('name', errors)}
                                helperText={
                                    hasError('name', errors) ? errors.name[0] : null
                                }
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={formValues.email || ''}
                                onChange={handleInputChange}
                                error={hasError('email', errors)}
                                helperText={
                                    hasError('email', errors) ? errors.email[0] : null
                                }
                            />



                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.submit}
                                disabled={isFormProcessed}
                            >
                                {!isFormProcessed ? <Box>Zapisz</Box> : <Box>Wysyłanie...</Box>}
                            </Button>
                        </form>
                </Auth>
            </BoxLayout>
            <Footer/>
        </div>
    );
}

export default Personal;
