import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Price from "./Price";
import priceCalc from '../middlewares/priceCalc';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    paddedLeftGrid: {
        paddingLeft: '24px'
    }
}));


function BoxDietDatesCost({dateStart, dateEnd, numDays, numItems, unitPrice}){

    const classes = useStyles();


    return (
        <Grid container spacing={3} className={classes.paddedLeftGrid}>

            <Grid item xs={6}>
                <Typography variant='subtitle2'>Od</Typography>
                <Typography variant='caption'>{dateStart === null || typeof(dateStart) === 'undefined' ? '-' : new Date(dateStart).toDateString()}</Typography>
            </Grid>

            <Grid item xs={6}>
                <Typography variant='subtitle2'>Do</Typography>
                <Typography variant="caption" >{dateEnd === null || typeof(dateEnd) === 'undefined' ? '-' : new Date(dateEnd).toDateString()}</Typography>
            </Grid>

            <Grid item xs={6}>
                <Typography variant='subtitle2'>Dostaw</Typography>
                <Typography variant='caption'>{numDays}</Typography>
            </Grid>

            <Grid item xs={6}>
                <Typography variant='subtitle2'>Cena</Typography>
                {/*unitPrice, numItems, numDays = 1*/}
                <Price value={priceCalc(unitPrice, numItems, numDays)} />
            </Grid>

        </Grid>
    );

}


export default BoxDietDatesCost;