import Typography from "@material-ui/core/Typography";
import React from "react";
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {hasError} from "../../services/validators";
import Grid from "@material-ui/core/Grid";
import {FONTS} from "../../Theme";

const useStyles = makeStyles((theme) => ({

    header: {
        color: theme.palette.secondary.main,
        font: 'normal normal normal 20px/24px ' + FONTS.fontFamily,
        letterSpacing: "0.19px"
    },
    textWithIcon: {
        display: 'flex',
        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
        letterSpacing: "0.15px",
        marginTop: 16,
        color: theme.palette.secondary.main,
        "& > span": {
            marginLeft: 8
        }
    }
}));

function DayOpeningHours({name, label, values, errors, onChange}) {
    const classes = useStyles();

    const [formValues, setFormValues] = React.useState(values);

    React.useEffect(() => {
        setFormValues(values);
    }, [values]);

    React.useEffect(() => {
        onChange(name, formValues);
    }, [formValues]);

    function handleInputChange(event) {
        const target = event.target;

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    return (
        <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox checked={formValues.open ?? 'checked'}
                                           onChange={handleInputChange} name="open"/>}
                        label={label}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="time"
                        label="od"
                        type="time"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        name={"from"}
                        value={formValues.from}
                        onChange={handleInputChange}
                        error={hasError('from', errors)}
                        helperText={
                            hasError('from', errors) ? errors.from[0] : null
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="time"
                        label="od"
                        type="time"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}

                        name={"to"}
                        value={formValues.to}
                        onChange={handleInputChange}
                        error={hasError('to', errors)}
                        helperText={
                            hasError('to', errors) ? errors.to[0] : null
                        }
                    />
                </Grid>
                <Box my={1}>
                    <Divider variant="fullWidth"/>
                </Box>
        </Grid>
    );
}

export default DayOpeningHours;
