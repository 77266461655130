import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Price from "../Price";
import Power from "../Power";
import {Tooltip} from "@mui/material";
import {APP_SITE, COLORS, FONTS} from "../../Theme";
import clsx from  'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: theme.spacing(2),
        border: "1px solid #DDDDDD",
        borderRadius: "6px"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    categoryDetails: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "150%",
        color: "#757575"
    },
    categoryName: {
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "150%",
        color: COLORS.secondary,
        textTransform: "uppercase"
    },
    content: {
        flex: '1 0 auto',
    },
    h100: {
        height: "100%"
    },
    w100: {
        width: "100%"
    },
    header: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "150%",
        display: "flex",
        alignItems: "center",
        color: COLORS.secondary,
        textTransform: "uppercase",
        marginBottom: 15
    },
    properties: {
        fontFamily: FONTS.fontFamily, fontStyle: "normal", fontWeight: "400", fontSize: "16px", lineHeight: "150%", color: "#03A600"
    },
    cover: {
        height: 100,
        [theme.breakpoints.down('md')]: {
            width: "100%",
            maxHeight: 141,
            height: 141,
        },
        [theme.breakpoints.up('md')]: {
            width: 120,
            maxHeight: 120,
            height: 120,
        },
    },
    controls: {
        display: 'flex',
        height: "100%",
        width: "100%",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    separator: {
        width: "0px", height: "90px", left: -10, top: 16, border: "1px solid #DDDDDD",
        position: "absolute",
        [theme.breakpoints.down('sm')]: {
            position: "unset",
            width: "100%",
            height: "0px",
            border: "1px solid #DDDDDD",
            marginTop: -12,
            marginBottom: 12
        },
    },
    relative: {
        position: "relative",
    },
    truncate: {
        width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
    },
    error: {
        color: "red"
    }
}));

function PremiumPhoneItem({catalog, onClick, onClickDisable, onClickEnable}) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box className={classes.root}>
            <Grid container classNameOff={classes.details}>
                {catalog.premium_phone.avatar_filename_url && (
                    <CardMedia
                        className={classes.cover}
                        image={catalog.premium_phone.avatar_filename_url + "?w=170&h=170"}
                        title={catalog.premium_phone.name}
                    />
                )}
                <Grid md={8} xs={12}>
                    <Box ml={0} p={2}>
                        <Typography component="h4" variant="p" className={classes.categoryName}>
                            Teleporada
                        </Typography>
                        <Box mt={1} className={classes.categoryDetails}>
                            {catalog.premium_phone.description}
                        </Box>
                    </Box>
                </Grid>
                <Grid md xs={12}>
                    <Box p={2} className={clsx(classes.relative, classes.h100, classes.w100)}>
                        <div className={classes.separator}></div>
                        <div className={classes.controls}>
                            {!catalog.premium_phone.disabled && (
                                <Button size="small" onClick={onClickDisable}>Dezaktywuj</Button>
                            )}

                            {catalog.premium_phone.disabled && (
                                <Button size="small" onClick={onClickEnable}>Aktywuj</Button>
                            )}

                            <Button size="small" onClick={onClick}>Edytuj</Button>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PremiumPhoneItem;
