import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import ImageListItem from "@mui/material/ImageListItem/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ImageList from "@mui/material/ImageList/ImageList";
import Typography from "@material-ui/core/Typography";
import {assetsPath} from "../../../services/mainService";
import Box from "@material-ui/core/Box";
import {APP_SITE} from "../../../Theme";

const useStyles = makeStyles((theme) => ({
    root: {},
    fullButtons: {
        display: 'flex',
        justifyContent: 'space-between',

        '& > *': {
            minWidth: 180,

            [theme.breakpoints.down('md')]: {
                minWidth: 140,
                width: 140,
            },
        },
    },
    typo: {
        marginTop:'24px',
        textAlign: "center",
        fontSize:'16px',
        color:'#757575',
    },

    box: {
        border: '1px solid #DDDDDD',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    img:{
        maxWidth:"300px",
        maxHeight:"300px",
        margin:"auto",
        display:"block",
        paddingTop:"24px",
    }
}));

export default function GalleryDialog({open = false, data = null, onClose, onSuccess}) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    console.log('data', data);

    function onError(e) {
        console.log('images upload form error', e);
    }

    const handleClose = () => {
        onClose();
    };

    const handleError = () => {
    };

    if (!data || !data.catalog_files) {
        return <></>
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    Galeria zdjęć
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>

                    {data.catalog_files.length == 0 && (
                        <div>
                            <Box className={classes.box} variant="p" textAlign={"center"}>

                                <div>
                                    {APP_SITE == 'jakieoze' && (
                                    <Typography component="p" className={classes.typo}>
                                        Ten instalator nie ma jeszcze dodanych zdjęć do galerii.
                                    </Typography>
                                        )}
                                    {APP_SITE == 'paramedycy' && (
                                        <Typography component="p" className={classes.typo}>
                                            Ta firma nie ma jeszcze dodanych zdjęć do galerii.
                                        </Typography>
                                        )}

                                </div>
                                <img src={assetsPath('noGalery.png')} className={classes.img}/>
                            </Box>
                        </div>
                    )}

                    <ImageList cols={3} gap={8}>
                        {data.catalog_files.map((item, i) => (
                            <ImageListItem key={item.id}>
                                <img
                                    src={`${item.image_filename}`}
                                    srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt='Galeria zdjęć'
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>

                </DialogContent>
                <DialogActions className={classes.fullButtons}>
                    <Button autoFocus onClick={handleClose} color="secondary" variant="contained" size="large">
                        Zamknij
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
