import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../actions/ui";
import {hasError} from "../../../services/validators";
import {updateMenuItem} from "../../../services/catalogService";
import CardMedia from "@material-ui/core/CardMedia";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import {getProfile} from "../../../services/userService";
import FormHelperText from "@material-ui/core/FormHelperText";
import FileUploader from "../../FileUploader";
import Box from "@material-ui/core/Box";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ajaxClient from "../../../services/ajaxClient";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    file: {
        width: 200,
        height: 150
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formControl: {
        minWidth: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function AddEditMenuItemForm({menuItem, onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);
    const [categories, setCategories] = useState([]);

    const fileInputRef = useRef(null);

    const [user, setUser] = useState(null);

    React.useEffect(() => {
        reloadUser();
    }, []);

    function reloadUser() {
        getProfile().then(response => {
            setUser(response.data);
        })
    }

    React.useEffect(() => {
        if (menuItem) {
            setFormValues(menuItem);
        }
    }, [menuItem]);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    React.useEffect(() => {
        async function fetchCategories() {
            const response = await ajaxClient().get("categories");
            setCategories(response.data.data);
        }

        fetchCategories();
    }, []);

    const handleAttacheInputFile = () => {
        fileInputRef.current.click();
    };

    function handleInputChange(event) {
        const target = event.target;

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    const handleInputFileChange = (event) => {
        const target = event.target;
        const name = target.name;

        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);

        reader.onload = function () {
            setFormValues({
                ...formValues,
                [name]: reader.result
            });
        };
    };

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        updateMenuItem(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    function getSelectedCollection() {
        if (!user) {
            return;
        }

        if (!user.collections) {
            return;
        }

        return user.collections.find((collection) => {
            //console.log('[comparision]', tagId, 'vs', tagItem.id);
            return formValues.menu_collection_id == collection.id
        });
    }

    function getCategory(categoryId) {
        if (!categoryId) {
            return;
        }

        return categories.find((category) => {
            return category.id == categoryId
        })
    }

    function getTags(categoryId) {
        if (!categoryId) {
            return [];
        }

        let category = getCategory(categoryId);

        if (!category) {
            return [];
        }

        return category.tags ?? [];
    }

    function handleTagChange(tags, event) {
        if (!tags) {
            return null;
        }

        const target = event.target;
        let tagId =  target.value;

        if (target.checked) {
            console.log('find tag', tags.find(tag => tag.id == tagId), tags, tagId);

            let tag = tags.find(tag => tag.id == tagId);

            addTag(tag);
        } else {
            removeTag(tags.find(tag => tag.id == tagId));
        }
    }

    function removeTag(tagItem) {
        if (!formValues.tags) {
            return;
        }

        console.log('remove tagItem', tagItem);
        console.log('new items', formValues.tags.filter(tag => tag.id != tagItem.id));

        setFormValues({
            ...formValues,
            tags: formValues.tags.filter(tag => tag.id != tagItem.id)
        });
    }

    function addTag(tagItem) {
        console.log('add tag', tagItem);

        if (!tagItem) {
            return [];
        }

        let tags = formValues.tags;

        if (typeof tags === undefined || !tags) {
            tags = [];
        }

        console.log('new tags', [...tags, tagItem]);

        setFormValues({
            ...formValues,
            tags: [...tags, tagItem]
        });
    }

    function tagSelected(tagItem) {
        if (!formValues.tags) {
            return false;
        }

        let item = formValues.tags.find((tag) => {
            console.log('[comparision]', tag.id, 'vs', tagItem.id);
            return tagItem.id === tag.id
        });

        if (typeof item === undefined) {
            return false;
        }

        console.log('[comparision: found]', item);
        return !!item;
    }

    function handleInputCategoryChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let selectedCategory = findCategory(target.value);

        setFormValues({
            ...formValues,
            [name]: value,
            category: selectedCategory
        });
    }

    const findCategory = (categoryId) => {
        return categories.find((element) => {
            return element.id === categoryId;
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className={classes.form}>
                {/*<FormControl variant="outlined" className={classes.formControl} error={hasError('menu_collection_id', errors)}>
                    <InputLabel id="demo-simple-select-outlined-label">Kolekcja</InputLabel>

                    <Select
                        fullWidth
                        labelId="menu_collection_id-label"
                        id="menu_collection_id"
                        name="menu_collection_id"
                        value={formValues.menu_collection_id || 0}
                        onChange={handleInputChange}
                        label="Kolekcja"
                        error={hasError('menu_collection_id', errors)}
                        helperText={
                            hasError('menu_collection_id', errors) ? errors.menu_collection_id[0] : null
                        }
                    >
                        {user && user.collections && user.collections.map(collection => {
                            return (
                                <MenuItem value={collection.id} key={collection.id}>{collection.name}</MenuItem>
                            )
                        })}
                    </Select>

                </FormControl>*/}

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Kategoria</InputLabel>
                    <Select
                        id="category_id"
                        name="category_id"
                        label={"Kategoria"}

                        value={(formValues && formValues.category_id) ? formValues.category_id : ''}
                        onChange={handleInputCategoryChange}

                        error={hasError('category_id', errors)}
                        helperText={
                            hasError('category_id', errors) ? errors.category_id[0] : null
                        }
                    >
                        <MenuItem value={null}>
                            Wybierz kategorię
                        </MenuItem>

                        {categories && categories.map(categoryItem => {
                            return (
                                <MenuItem value={categoryItem.id} key={categoryItem.id}>
                                    {categoryItem.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Nazwa"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={formValues.name || ''}
                    onChange={handleInputChange}
                    error={hasError('name', errors)}
                    helperText={
                        hasError('name', errors) ? errors.name[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="description"
                    label="Opis"
                    rows="3"
                    multiline={true}
                    name="description"
                    autoComplete="description"
                    autoFocus
                    value={formValues.description || ''}
                    onChange={handleInputChange}
                    error={hasError('description', errors)}
                    helperText={
                        hasError('description', errors) ? errors.description[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="price"
                    label="Cena"
                    name="price"
                    value={formValues.price || '0'}
                    onChange={handleInputChange}
                    error={hasError('price', errors)}
                    helperText={
                        hasError('price', errors) ? errors.price[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="price"
                    label="Cena promocyjna"
                    name="promotion_price"
                    value={formValues.promotion_price || '0'}
                    onChange={handleInputChange}
                    error={hasError('promotion_price', errors)}
                    helperText={
                        hasError('promotion_price', errors) ? errors.promotion_price[0] : null
                    }
                />

                <br/><br/>
                <Divider></Divider>
                <br/>

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="price"
                    label="Dostępność"
                    name="max_quantity"
                    value={formValues.max_quantity || '0'}
                    onChange={handleInputChange}
                    error={hasError('max_quantity', errors)}
                    helperText={
                        hasError('max_quantity', errors) ? errors.max_quantity[0] : null
                    }
                />

                {getCategory(formValues.category_id) && getCategory(formValues.category_id).type === 'pv' && (
                    <Box>
                        <h3 className={classes.h1}>Panel fotowoltaiczny</h3>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="first_producer_name"
                            label="Producent"
                            name="first_producer_name"
                            autoComplete="first_producer_name"
                            autoFocus
                            value={formValues.first_producer_name}
                            onChange={handleInputChange}
                            error={hasError('first_producer_name', errors)}
                            helperText={
                                hasError('first_producer_name', errors) ? errors.first_producer_name[0] : null
                            }
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="first_product_name"
                            label="Model"
                            name="first_product_name"
                            autoComplete="first_product_name"
                            autoFocus
                            value={formValues.first_product_name}
                            onChange={handleInputChange}
                            error={hasError('first_product_name', errors)}
                            helperText={
                                hasError('first_product_name', errors) ? errors.first_product_name[0] : null
                            }
                        />

                        <TextField
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0, max: 100
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="elements"
                            label="Ilość paneli"
                            name="elements"
                            value={formValues.elements || '0'}
                            onChange={handleInputChange}
                            error={hasError('elements', errors)}
                            helperText={
                                hasError('elements', errors) ? errors['elements'][0] : null
                            }
                        />

                        <h3 className={classes.h1}>Inwerter</h3>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="second_producer_name"
                            label="Producent"
                            name="second_producer_name"
                            autoComplete="second_producer_name"
                            autoFocus
                            value={formValues.second_producer_name}
                            onChange={handleInputChange}
                            error={hasError('second_producer_name', errors)}
                            helperText={
                                hasError('second_producer_name', errors) ? errors.second_producer_name[0] : null
                            }
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="second_product_name"
                            label="Model"
                            name="second_product_name"
                            autoComplete="second_product_name"
                            autoFocus
                            value={formValues.second_product_name}
                            onChange={handleInputChange}
                            error={hasError('second_product_name', errors)}
                            helperText={
                                hasError('second_product_name', errors) ? errors.second_product_name[0] : null
                            }
                        />

                        <TextField
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0, max: 50
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="inverter_phase_count"
                            label="Inwerter fazy (ilość)"
                            name="inverter_phase_count"
                            value={formValues.inverter_phase_count || '0'}
                            onChange={handleInputChange}
                            error={hasError('inverter_phase_count', errors)}
                            helperText={
                                hasError('inverter_phase_count', errors) ? errors['inverter_phase_count'][0] : null
                            }
                        />

                        <TextField
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0, max: 50
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="inverter_mppt_count"
                            label="Inwerter MPPT (ilość)"
                            name="inverter_mppt_count"
                            value={formValues.inverter_mppt_count || '0'}
                            onChange={handleInputChange}
                            error={hasError('inverter_mppt_count', errors)}
                            helperText={
                                hasError('inverter_mppt_count', errors) ? errors['inverter_mppt_count'][0] : null
                            }
                        />
                    </Box>
                )}

                {getTags(formValues.category_id).length > 0 && (
                        <Box>
                            <h3 className={classes.h1}>Tagi</h3>
                        </Box>
                    )
                }


                {getTags(formValues.category_id).map(tagItem => {
                    return (
                        <Box>
                            <FormControlLabel
                                control={<Checkbox checked={tagSelected(tagItem) ?? 'checked'}
                                                   onChange={(event) => handleTagChange(getTags(formValues.category_id), event)} name="tags" value={parseInt(tagItem.id)}/>}
                                label={tagItem.name}
                            />
                        </Box>
                    );
                })}

                {/*
                {formValues.image && formValues.image !== '' ? formValues.image : formValues.image_filename_url && (
                    <CardMedia
                        className={classes.file}
                        onClick={handleAttacheInputFile}
                        image={formValues.image && formValues.image !== '' ? formValues.image : formValues.image_filename_url}
                        title={formValues.name}
                    />
                )}
                <input
                    name="image"
                    ref={fileInputRef}
                    accept={'.peg,.png,.jpg,.gif'}
                    type="file"
                    onChange={handleInputFileChange}
                />*/}

                <FileUploader
                    name="image"
                    accept={'.peg,.png,.jpg,.gif'}
                    type="file"
                    image={formValues.image && formValues.image !== '' ? formValues.image : formValues.image_filename_url}
                    blob={formValues.image && formValues.image !== '' ? true : false}
                    title={'Wgraj zdjęcie'}
                    onChange={handleInputFileChange}/>

                {!hideSubmitButton && (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isFormProcessed}
                    >
                        {!isFormProcessed ? <Box>Dodaj</Box> : <Box>Wysyłanie...</Box>}
                    </Button>
                )}
            </form>
        </div>
    );
}

export default AddEditMenuItemForm;
