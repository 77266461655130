import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {CircularProgress} from "@material-ui/core";
import {Redirect} from "@reach/router";
import {getProfile} from "../../services/userService";
import Box from "@material-ui/core/Box";
import {useTheme} from "@material-ui/core/styles";
import Loader from "../../components/Loader";

function Auth({children}) {
    const [progress, setProgress] = useState(false);
    const [user, setUser] = useState(null);

    const theme = useTheme();

    React.useEffect(() => {
        reloadUser();
    }, []);

    function reloadUser() {
        getProfile().then(response => {
            setUser(response.data);
            setProgress(true);
        })
    }

    if (!progress) {
        return <Loader>Trwa ładowanie...</Loader>
    }

    if (!user) {
        return <Redirect to="/login" noThrow/>;
    }

    return <>{children}</>;
}

Auth.propTypes = {
    children: PropTypes.node
};

export default Auth;
