import {initialState} from "./index";

function userReducer(state = initialState.user, action = {}) {
    console.log('action.payload', action);

    switch (action.type) {
        case "LOGIN": {
            return {
                ...state,
                currentUser: action.payload,
                loggedIn: true
            };
        }
        case "PROFILE": {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    ...action.payload
                },
                loggedIn: true
            };
        }
        case "LOGOUT":
            {
            return {
                ...state,
                currentUser: {},
                loggedIn: false
            };
        }

        case "PASSWORD_RESET":
        {
            return {
                ...state,
                currentUser: {},
                loggedIn: false
            };
        }

        default: {
            return state;
        }
    }
}

export default userReducer;
