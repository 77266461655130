import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import FooterGrass from "../assets/icons/footer-grass.svg";
import {assetsPath} from "../services/mainService";
import {APP_SITE, COLORS, FONTS} from "../Theme";
import Typography from "@material-ui/core/Typography";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles((theme) => ({

    footer: {
        background: '#F5F5F5',
        paddingTop: 30,

        '& a': {
            color: '#707070',
            display: 'block',
            font: 'normal normal normal 16px/21px ' + FONTS.fontFamily,
            letterSpacing: '0.47px',

            paddingTop: '5px',
            paddingBottom: '5px'
        }

    },
    h2: {
        font: 'normal normal bold 16px/21px ' + FONTS.fontFamily,
        color: COLORS.secondary
    },
    footerGrass: {
        position: "relative",
        left: '40%',
        marginBottom: '-5px',
        /*marginBottom:'-30px',*/

    },
    footerLine: {
        width: '94%',
        color: '#E7E7E7',
        border: '1px solid',
        marginLeft: '0px',
        marginTop: '48px',


    },
    h3: {
        fontFamily: FONTS.fontFamily,
        fontSize: '16px',
        fontWeight: '400',
        marginTop: '24px',
        marginBottom: '5px',
        color: '#707070',
    },
    p: {
        fontFamily: FONTS.fontFamily,
        fontSize: '14px',
        fontWeight: '400',
        marginTop: '24px',
        marginBottom: '5px',
        color: '#707070',
    },
    span: {
        fontWeight: '700',
    },
    icon: {
        marginRight: 8,
        display: "inline-block"
    }

}));

export default function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.footer}>
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <h2 className={classes.h2}>Kontakt</h2>
                        <Typography className={classes.p}>
                            <Grid container>
                                <Grid item xs={6} sm={4} md={2}>
                                    Pon - Pt: 08:00 - 16:00
                                </Grid>
                                <Grid item xs={6} sm={4} md={2}>
                                    <Box display={"flex"}>
                                        <PhoneOutlinedIcon className={classes.icon}/> +48 517 170 888
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} md={2}>
                                    <Box display={"flex"}>
                                        <EmailOutlinedIcon className={classes.icon}/> bok@{APP_SITE == 'paramedycy' ? 'kuracje' : 'jakieoze'}.pl
                                    </Box>
                                </Grid>
                            </Grid>
                        </Typography>

                        <Typography className={classes.p}>
                            <Box display={"flex"}>
                                <Box display={"flex"}>
                                    Leona Heyki 27/2 <br/>70-631 Szczecin
                                </Box>
                            </Box>
                        </Typography>

                    </Grid>


                    {/*<Grid item xs={12} sm={3}>
                        <h2 className={classes.h2}>Pomoc</h2>
                        <Link href={`kontakt`}
                              color="default">
                            Kontakt
                        </Link>
                        <Link href={`/`}
                              color="default">
                            FAQ
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <h2 className={classes.h2}>Blog</h2>
                        <Link href={`/regulamin`}
                              color="default">
                            Klienci indywidualni
                        </Link>
                        <Link href={`/dla-rolnika`}
                              color="default">
                            Dla rolnika
                        </Link>
                        <Link href={`/dla-biznesu`}
                              color="default">
                            Dla biznesu
                        </Link>
                        <Link href={`/dotacje`}
                              color="default">
                            Dotacje
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <h2 className={classes.h2}>Dla Rolnika</h2>

                        <Link href={`/polityka prywatności`}
                              color="default">
                            Polityka prywatności
                        </Link>

                        <Link href={`/polityka cookies`}
                              color="default">
                            Polityka cookies
                        </Link>

                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <h2 className={classes.h2}>Oferty</h2>

                        <Link href={`/polityka prywatności`}
                              color="default">
                            Fotowoltaika
                        </Link>

                        <Link href={`/polityka cookies`}
                              color="default">
                            Pompy ciepła
                        </Link>

                        <Link href={`/polityka cookies`}
                              color="default">
                            Turbiny wiatrowe
                        </Link>
                    </Grid>*/}

                    <hr className={classes.footerLine}/>
                    <h3 className={classes.h3}>© 2022 <span
                        className={classes.span}> {APP_SITE == 'paramedycy' ? 'kuracje' : 'jakieoze'}.pl</span></h3>


                </Grid>
                <div>
                    {APP_SITE == 'jakieoze' && (
                        <img src={assetsPath('footerGrass.png')} className={classes.footerGrass}/>
                    )}


                </div>

            </Container>
        </div>
    );
}
