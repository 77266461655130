import ajaxClient from "./ajaxClient";


export const storeCatalogFiles = async (catalog, data) => {
    console.log('sending to catalog: ' + catalog.id, data.data);
    try {
        return await ajaxClient().post('catalogs/' + catalog.id + '/files', data)
            .then(response => response);
    } catch (error) {
        return error.response;
    }
}

export const removeCatalogFile = async (catalog, file) => {
    console.log('sending to catalog: ' + catalog.id, file);
    try {
        return await ajaxClient().delete('catalogs/' + catalog.id + '/files/' + file.id)
            .then(response => response);
    } catch (error) {
        return error.response;
    }
}

