import React from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FONTS} from "../Theme";

const useStyles = makeStyles((theme) => ({
    header: {
        color: theme.palette.secondary.main,
        font: 'normal normal normal 20px/24px ' + FONTS.fontFamily,
        letterSpacing: "0.19px"
    },
    textWithIcon: {
        display: 'flex',
        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
        letterSpacing: "0.15px",
        marginTop: 16,
        color: theme.palette.secondary.main,
        "& > span": {
            marginLeft: 8
        }
    },
    opened: {
        color: "green",
        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
    },
    closed: {
        color: "gray",
        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
    },
    hours: {
        textAlign: "right"
    },
}));

function DayOpeningHoursLabel({label, slug, day}) {
    const classes = useStyles();

    return (
        <Grid container className={day.open ? classes.opened : classes.closed}>
            <Grid item xs={6}>
                {label}
            </Grid>
            <Grid item xs={6} className={classes.hours}>

                {day.open ? (
                    <>
                        {day.from} - {day.to}
                    </>
                ) : (
                    <>Nieczynne</>
                )}

            </Grid>
        </Grid>
    );
}

export default DayOpeningHoursLabel;
