import React from "react";
import CatalogListItem from "./CatalogListItem";
import Grid from "@material-ui/core/Grid";
import Loader from "../Loader";
import {CheckCircleRounded} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {assetsPath} from "../../services/mainService";
import {APP_SITE} from "../../Theme";

const useStyles = makeStyles((theme) => ({
    typo: {
        marginTop:'24px',
        textAlign: "center",
        fontSize:'16px',
        color:'#757575',
    },
    box: {
        border: '1px solid #DDDDDD',
        borderRadius: '6px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            paddingRight: 10
        },
    },
    img :{

        maxWidth:"300px",
        maxHeight:"300px",
        margin:"auto",
        display:"block",
        padding:"24px 0px 0px",

    }
}));

function CatalogsList({catalogs, showMap}) {
    const classes = useStyles();

    return (
        <>
            {catalogs.length > 0 && (
                <Grid
                    container
                    spacing={4}
                    direction="row"
                    alignItems="flex-start"
                >
                    {catalogs && catalogs.map(catalog => {
                        return (
                            <Grid item md={6} sm={6} xs={12} key={catalog.id}>
                                <CatalogListItem
                                    catalog={catalog}
                                    key={"catalog" + catalog.id}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            {catalogs.length === 0 && (
                <Box mt={5} class={classes.box} display={"flex"} justifyContent={"center"}>
                   <div>
                       {APP_SITE == 'jakieoze' && (
                            <Typography component="p" className={classes.typo}>
                                Brak usługodawców spełniających Twoje kryteria wyszukiwania.
                            </Typography>
                           )}
                       {APP_SITE == 'paramedycy' && (
                           <Typography component="p" className={classes.typo}>
                               Brak firm spełniających Twoje kryteria wyszukiwania.
                           </Typography>
                           )}
                    <img src={assetsPath('noServices.png')} className={classes.img}/>
                   </div>
                </Box>
            )}
        </>
    );
}

export default CatalogsList;
