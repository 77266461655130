import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import moment from "moment/moment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {ArrowLeft, ArrowRight} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import CalendarDay from "./CalendarDay";
import Grid from "@material-ui/core/Grid";
import Link from "@mui/material/Link";
import CalendarHour from "./CalendarHour";
import {navigate} from "@reach/router";
import CalendarSelectedDay from "./CalendarSelectedDay";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Calendar({onSuccess, onError, selectedDate = null, openingHours = null}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const showDays = 5;

    const [isFormProcessed, setIsFormProcessed] = useState(false);
    const [currentDate, setCurrentDate] = useState(moment());
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [showCalendar, setShowCalendar] = useState(true);
    const today = (moment());

    function handleLeft() {
        setCurrentDate(currentDate.clone().subtract(1, 'days'));
    }

    function handleRight() {
        setCurrentDate(currentDate.clone().add(1, 'days'));
    }

    function handleSelectDate() {
        console.log('select date clicked');
        setShowCalendar(true);
    }

    function selectDateTime(date, hour) {
        setShowCalendar(false);

        console.log('selected item', date, hour);
        let time = hour.split(':');
        let newDate = date.clone().set("hour", time[0]).set("minute", time[1]);

        setSelectedDateTime(newDate);
        onSuccess(newDate);
        //console.log('selectedDateTime', selectedDateTime.format('LT'));
    }

    function getDayIndex(day) {
        if (day === 'sunday') {
            return 0;
        } else if (day === 'monday') {
            return 1;
        } else if (day === 'tuesday') {
            return 2;
        } else if (day === 'wednesday') {
            return 3;
        } else if (day === 'thursday') {
            return 4;
        } else if (day === 'friday') {
            return 5;
        } else if (day === 'saturday') {
            return 6;
        }
    }

    function getDayName(day) {
        if (day === 0) {
            return 'sunday';
        } else if (day === 1) {
            return 'monday';
        } else if (day === 2) {
            return 'tuesday';
        } else if (day === 3) {
            return 'wednesday';
        } else if (day === 4) {
            return 'thursday';
        } else if (day === 5) {
            return 'friday';
        } else if (day === 6) {
            return 'saturday';
        }
    }

    function getHours(date) {
        let dates = [];
        let weekDayIndex = date.day();

        let dayName = getDayName(weekDayIndex);

        if (openingHours && openingHours[dayName].open) {
            let from = openingHours[dayName].from;
            let to = openingHours[dayName].to;

            let time = from.split(':');
            let startDate = date.clone().set("hour", time[0]).set("minute", time[1]);

            time = to.split(':');
            let endDate = date.clone().set("hour", time[0]).set("minute", time[1]);

            for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'hour')) {
                dates.push(m.clone());
                console.log(m.format('YYYY-MM-DD HH:mm'));
            }

            console.log('from to', startDate.format('lll'), endDate.format('lll'), dates);
            return dates;
        }

        return false;
    }

    return (
        <div>

            {selectedDateTime && !showCalendar && (
                <>
                    <CalendarSelectedDay date={selectedDateTime} onClick={handleSelectDate}/>
                </>
            )}

            {console.log('openingHours', openingHours)}
            {console.log('openingHours day', moment().day("Sunday").format('d'))}

            {showCalendar && (
                <Grid container direction="row" justifyContent="space-between" alignItems="top">
                    <Grid item>
                        {currentDate && currentDate.isAfter(today) && (
                            <IconButton  aria-label="Dates select" color="primary" onClick={handleLeft}>
                                <ArrowLeft/>
                            </IconButton >
                        )}

                        {currentDate && !currentDate.isAfter(today) && (
                            <IconButton  aria-label="Dates select" color="secondary" onClick={handleLeft} disabled={true}>
                                <ArrowLeft/>
                            </IconButton >
                        )}
                    </Grid>

                    <Grid item>
                        <CalendarDay date={currentDate}/>

                        {getHours(currentDate) && getHours(currentDate).map(date => {
                            return (
                                <CalendarHour hour={date.format('HH:mm')} date={date} onClick={() => selectDateTime(date, date.format('HH:mm'))} active={selectedDateTime && date.format('ll') === selectedDateTime.format('ll') && selectedDateTime.format('LT') === date.format('HH:mm')}/>
                            );
                        })}

                    </Grid>

                    <Grid item>
                        <CalendarDay date={currentDate.clone().add(1, 'days')}/>

                        {getHours(currentDate.clone().add(1, 'days')) && getHours(currentDate.clone().add(1, 'days')).map(date => {
                            return (
                                <CalendarHour hour={date.format('HH:mm')} date={date} onClick={() => selectDateTime(date, date.format('HH:mm'))} active={selectedDateTime && date.format('ll') === selectedDateTime.format('ll') && selectedDateTime.format('LT') === date.format('HH:mm')}/>
                            );
                        })}
                    </Grid>

                    <Grid item>
                        <CalendarDay date={currentDate.clone().add(2, 'days')}/>

                        {getHours(currentDate.clone().add(2, 'days')) && getHours(currentDate.clone().add(2, 'days')).map(date => {
                            return (
                                <CalendarHour hour={date.format('HH:mm')} date={date} onClick={() => selectDateTime(date, date.format('HH:mm'))} active={selectedDateTime && date.format('ll') === selectedDateTime.format('ll') && selectedDateTime.format('LT') === date.format('HH:mm')}/>
                            );
                        })}
                    </Grid>

                    <Grid item>
                        <IconButton  aria-label="Dates select"  color="primary" onClick={handleRight}>
                            <ArrowRight/>
                        </IconButton >
                    </Grid>
                </Grid>
            )}
            {/*<p>
                {currentDate.format('D')} {currentDate.format('dddd')}<br/>
                {currentDate.format('MMMM')}
            </p>*/}
        </div>
    );
}

export default Calendar;
