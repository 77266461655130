import React, {useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import DeliveryForm from "../Forms/DeliveryForm";
import CompanyForm from "../Forms/CompanyForm";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CommentForm from "../Forms/CommentForm";
import {useFetchProfile} from "../../../actions";
import {useUser} from "../../../selector";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {},
    fullButtons: {
        display: 'flex',
        justifyContent: 'space-between',

        '& > *': {
            minWidth: 180,

            [theme.breakpoints.down('md')]: {
                minWidth: 140,
                width: 140,
            },
        },
    },
    box: {
        padding: '24px',
    },
    text: {
        marginBottom: '20px',
        maxWidth: '450px'
    }
}));

export default function CommentDialog({open = false, data = null, onClose}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [submit, setSubmit] = React.useState(false);

    const user = useUser();

    console.log('user', user);

    const handleClose = () => {
        setSubmit(false);
        onClose();
    };

    const handleError = () => {
        setSubmit(false);
    };

    const triggerSubmit = () => {
        setSubmit(true);
    };

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Dodaj opinię"}</DialogTitle>

                {user && user.id && (
                    <Box>
                        <DialogContent>
                            <DialogContentText>
                                Twoja opinia jest dla nas i innych użytkowników bardzo cenna.
                            </DialogContentText>

                            <Divider variant="fullWidth"/>
                            <Box>
                                <Typography>Twoja ocena:</Typography>
                                <CommentForm onSuccess={handleClose} onError={handleError} triggerSubmit={submit}
                                             catalog={data}/>
                            </Box>
                        </DialogContent>
                        <DialogActions className={classes.fullButtons}>
                            <Button className={classes.bn} autoFocus onClick={handleClose} color="default"
                                    variant="contained" size="large">
                                Anuluj
                            </Button>
                            <Button onClick={triggerSubmit} color="primary" variant="contained" autoFocus size="large">
                                Dodaj opinię
                            </Button>
                        </DialogActions>
                    </Box>
                )}

                {user && !user.id && (
                    <Box className={classes.box}>
                        <Box>
                            <Typography className={classes.text}>Dodawanie komentarzy dostępne jest tylko dla
                                <strong> zalogowanych użytkowników</strong></Typography>
                            <Divider variant="fullWidth"/>

                        </Box>
                        <DialogActions className={classes.fullButtons}>
                            <Button autoFocus onClick={handleClose} color="default" variant="contained" size="large">
                                Anuluj
                            </Button>
                            <Button onClick={triggerSubmit} color="primary" variant="contained" autoFocus size="large">
                                Dodaj
                            </Button>
                        </DialogActions>
                    </Box>
                )}
            </Dialog>
        </div>
    );
}
