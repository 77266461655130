
import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../actions/ui";
import {hasError} from "../../../services/validators";
import {createMenuCollection} from "../../../services/catalogService";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ajaxClient from "../../../services/ajaxClient";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    file: {
        width: 200,
        height: 150
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formControl: {
        width: '100%'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function AddMenuCollectionForm({menuItem, onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);
    const [categories, setCategories] = useState([]);

    const fileInputRef = useRef(null);

    React.useEffect(() => {
        if (menuItem) {
            setFormValues(menuItem);
        }
    }, [menuItem]);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    React.useEffect(() => {
        async function fetchCategories() {
            const response = await ajaxClient().get("categories");
            setCategories(response.data.data);
        }

        fetchCategories();
    }, []);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleSubmit(event) {
        console.log('[SUBMIT]', formValues);
        event.preventDefault();

        submit(formValues);
    }

    React.useEffect(() => {
        async function fetchCategories() {
            const response = await ajaxClient().get("categories");
            setCategories(response.data.data);
        }

        fetchCategories();
    }, []);


    function submit(data) {
        setIsFormProcessed(true);

        createMenuCollection(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(response.data.data);
            }

            setIsFormProcessed(false);
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className={classes.form}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Nazwa zestawu"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={formValues.name}
                    onChange={handleInputChange}
                    error={hasError('name', errors)}
                    helperText={
                        hasError('name', errors) ? errors.name[0] : null
                    }
                />

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Kategoria</InputLabel>
                    <Select
                        id="category_id"
                        name="category_id"
                        label={"Kategoria"}

                        value={formValues.category_id}
                        onChange={handleInputChange}

                        error={hasError('category_id', errors)}
                        helperText={
                            hasError('category_id', errors) ? errors.category_id[0] : null
                        }
                    >

                        <MenuItem value="">
                            Wybierz kategorię
                        </MenuItem>

                        {categories && categories.map(categoryItem => {
                            return (
                                <MenuItem value={categoryItem.id} key={categoryItem.id}>
                                    {categoryItem.name}
                                </MenuItem>
                            );
                        })}

                    </Select>
                </FormControl>

                {!hideSubmitButton && (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isFormProcessed}
                    >
                        {!isFormProcessed ? <Box>Dodaj</Box> : <Box>Wysyłanie...</Box>}
                    </Button>
                )}
            </form>
        </div>
    );
}

export default AddMenuCollectionForm;
