import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Paper} from "@material-ui/core";
import {assetsPath} from "../../services/mainService";
import {COLORS, FONTS} from "../../Theme";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: `url(${assetsPath('search/bg.png')})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",
        height: "100vh",
        maxHeight: "100%",

        [theme.breakpoints.down('md')]: {
            /*maxHeight: '562px',
            height: '562px',*/
        },
        [theme.breakpoints.down('sm')]: {
            /*maxHeight: '462px',
            height: '462px',*/
        },
        paddingTop: 60
    },
    paper: {
        marginTop: theme.spacing(0),
        padding: theme.spacing(4),
        marginBottom: '1px',
        boxShadow: '-4px 4px 22px #00000015',
    },
    h1: {

        textAlign: 'center',
        color: '#FA4A0C',
        fontSize: '24 px'
    },
    form: {

        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    link: {
        '& a': {
            font: 'normal normal normal 12px/24px ' + FONTS.fontFamily,
            letterSpacing: '0.11px',
            color: '#757575',
            marginTop: '13px'
        },
        textAlign: "right",


    },
    box1: {
        '& a': {
            font: 'normal normal bold 20px/28px ' + FONTS.fontFamily,
            letterSpacing: '0.19px',
            color: COLORS.secondary,
        },
        textAlign: 'center'


    },
    t: {
        marginTop: '60px',
        font: 'normal normal normal 16px/28px ' + FONTS.fontFamily,
        letterSpacing: '0.15px',
        color: '#757575'
    },

    box2: {
        '& a': {
            font: 'normal normal bold 20px/28px ' + FONTS.fontFamily,
            letterSpacing: '0.19px',
            color: COLORS.secondary,
        },
        marginTop: '38px',
        textAlign: 'center'


    },
    t2: {
        marginTop: '38px',
        font: 'normal normal normal 16px/28px ' + FONTS.fontFamily,
        letterSpacing: '0.15px',
        color: '#757575'
    },
    body2: {
        font: 'normal normal normal 18px/28px ' + FONTS.fontFamily,
        letterSpacing: '0.15px',
        color: '#2D2D2D',

    }
}));

function BoxLayout({children}) {
    const classes = useStyles();


    return (
        <div className={classes.root}>
             <div className={classes.overlay}/>
            <Container component="main" maxWidth="sm">
                <Paper className={classes.paper}>
                    {children}
                </Paper>
                <CssBaseline/>

            </Container>
        </div>

    );
}

export default BoxLayout;
