import React from "react";

import HistoryHeader from "../components/HistoryHeader";
import HistoryList from "../components/HistoryList";
import { useFetchOrder } from "../actions/index";

function Record() {
  //const fetchOrder = useFetchOrder();

  React.useEffect(() => {
    //fetchOrder();
  });
  return (
    <div>
      <HistoryHeader />
      <HistoryList />
    </div>
  );
}

export default Record;
