import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CatalogForm from "../Forms/CatalogForm";

const useStyles = makeStyles((theme) => ({
    root: {},
    fullButtons: {
        display: 'flex',
        justifyContent: 'space-between',

        '& > *': {
            minWidth: 180,

            [theme.breakpoints.down('md')]: {
                minWidth: 140,
                width: 140,
            },
        },
    },
    dialog:{
        textAlign:'left'
    }
}));

export default function CatalogDialog({open = false, onClose}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [submit, setSubmit] = React.useState(false);

    const handleClose = () => {
        setSubmit(false);
        onClose();
    };

    const handleError = () => {
        setSubmit(false);
    };

    const triggerSubmit = () => {
        setSubmit(true);
    };

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle className={classes.dialog} id="responsive-dialog-title">{"Twoja wizytówka"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wprowadź wszystkie niezbędne dane o firmie.
                    </DialogContentText>
                    <CatalogForm onSuccess={handleClose} onError={handleError} triggerSubmit={submit}/>
                </DialogContent>
                <DialogActions className={classes.fullButtons}>
                    <Button autoFocus onClick={handleClose} color="secondary" variant="contained" size="large">
                        Anuluj
                    </Button>
                    <Button onClick={triggerSubmit} color="primary" variant="contained" autoFocus size="large">
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
