import React, {useState} from "react";
import CatalogListItem from "./CatalogListItem";
import ajaxClient from "../../services/ajaxClient";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {navigate, useMatch} from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import {getOffersQueryLink, getQueryLink, parseQuery} from "../../services/localizationService";
import {COLORS, FONTS} from "../../Theme";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {hasError} from "../../services/validators";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    categories: {
        paddingBottom: theme.spacing(2),
        paddingTop:'0px',
        '& a': {
            display: 'block',
            font: 'normal normal normal 16px/18px ' + FONTS.fontFamily,
            letterSpacing: '0.47px',

            paddingTop: '5px',
            paddingBottom: '5px'
        }
    },
    categoriesBox: {

    },
    button: {
        marginRight: theme.spacing(1),
        flexShrink: 0,
        maxHeight: 40,
        borderRadius: '35px',
        borderColor: '#DDDDDD',
        color: '#757575',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 8
        },
    },

    current: {
        color: 'black',
        fontWeight: 'bold'
    },
    select: {
        width: '100%',
        minWidth: 160,
        maxWidth: 160
    },
}));


function CatalogTagsFilter({query = null, type = "catalogs", category}) {
    const classes = useStyles();

    console.log('[query category]', query);

    const [formValues, setFormValues] = useState(null);

    React.useEffect(() => {
        setFormValues(parseQuery(query));
    }, [query]);

    const getQueryLinkByType = (data) => {
        if (type === "catalogs") {
            return getQueryLink(data);
        } else {
            return getOffersQueryLink(data);
        }
    }

    function tagSelected(tagItem) {
        if (!formValues) {
            return false;
        }

        if (!formValues.tags) {
            return false;
        }

        let tags = formValues.tags.split(',');

        let item = tags.find((tagId) => {
            console.log('[comparision]', tagId, 'vs', tagItem.id);
            return tagItem.id == tagId
        });

        console.log('[comparision: found]', item);
        return !!item;
    }

    function removeTag(tagItemId) {
        if (!formValues.tags) {
            return '';
        }

        let tags = formValues.tags.split(',');
        return tags.filter(tagId => tagId != tagItemId).join(',');
    }

    function addTag(tagItemId) {
        console.log('add tag', tagItemId);

        if (!tagItemId) {
            return '';
        }

        if (!formValues || !formValues.tags) {
            return tagItemId;
        }

        let tags = formValues.tags.split(',');

        return [...tags, tagItemId].join(',');
    }

    return (
        <section className={classes.categories}>
                {category && category.tags && category.tags.map(tagItem => {
                    return (
                        <Button
                            variant={tagSelected(tagItem) ? 'contained' : "outlined"}
                            color="default"
                            size={"large"}
                            key={tagItem.id}
                            className={classes.button}
                            onClick={() => navigate(process.env.PUBLIC_URL + getQueryLinkByType({...formValues, 'tags': tagSelected(tagItem) ? removeTag(tagItem.id) : addTag(tagItem.id)}))}
                        >
                            {tagItem.name}
                        </Button>
                    );
                })}
        </section>
    );
}

export default CatalogTagsFilter;
