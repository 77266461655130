import React from 'react';

function GeneralError({errors}) {
    console.log('[ERRORS]', errors);

    return (
        <>
            {errors && errors.general && errors.general[0] && (
                <div>{errors.general[0]}</div>
            )}
        </>
    )
}

export default GeneralError;