import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import HeaderBar from "../../components/HeaderBar";
import {showSuccessSnackbar} from "../../actions/ui";
import {getProfile, sendMessage, useIsMobile} from "../../services/userService";
import {Paper} from "@material-ui/core";
import Auth from "./Auth";
import Grid from "@material-ui/core/Grid";
import {List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import SendIcon from "@material-ui/icons/Send";
import Divider from "@material-ui/core/Divider";
import ajaxClient from "../../services/ajaxClient";
import {navigate} from "@reach/router";
import Loader from "../../components/Loader";
import moment from "moment";
import MessageOffer from "./MessageOffer";
import AvatarLetter from "../../components/AvatarLetter";
import {COLORS} from "../../Theme";
import CatalogListItem from "../../components/Catalogs/CatalogListItem";
import MessageCatalog from "./MessageCatalog";
import Box from "@material-ui/core/Box";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        backgroundColor: "#F2F4F7",
    },
    submit: {
        display: 'none',
        /*margin: theme.spacing(2, 0, 0),*/
        width: '100%',
        height: '40px',
        marginLeft: 10
    },
    chatSection: {
        width: '100%',
        height: "calc(100vh - 56px)",
    },
    root: {
        width: '100%',
        height: '100vh'
    },
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    usersArea: {
        borderRight: '1px solid #e0e0e0',
    },
    msArea: {
      padding:'24px',
    },
    p1:{
      fontSize: "24px",
      fontWeight: "bold"
    },
    p2:{
        fontSize: "16px",
        fontWeight: "600",
        color: COLORS.default,
    },
    messageArea: {
        overflowY: 'auto',
        height: 'calc(100vh - 136px)'
    },
    active: {

        "& .Mui-selected": {
            backgroundColor: "pink",
            color: "red",
            fontWeight: "bold"
        },
        "& .Mui-selected:hover": {
            backgroundColor: "tomato"
        },


        '&:hover': {},

        /*backgroundColor: '#E9F6EB',*/

        '& div': {
            /*backgroundColor: '#E9F6EB',*/
            /*color: COLORS.primaryLight,*/
        },

        '& *': {
            /*backgroundColor: '#E9F6EB',*/
            /*color: COLORS.primaryLight,*/
        }
    },
    companyText: {
        backgroundColor: "#F2F4F7",
        padding: "8px",
        borderRadius: "6px",
        borderTopLeftRadius: "0px",
        display: "inline-block",
        color: COLORS.default,
        fontSize: "14px",
        fontWeight: "400",
    },
    companyArea: {
        backgroundColor: "#F2F4F7",
        padding:"24px",

    },
    itemText: {
        backgroundColor: "#004F0D",
        padding: "8px",
        borderRadius: "6px",
        borderTopRightRadius: "0px",
        display: "inline-block",
        color: "#F9FAFB",
        fontSize: "14px",
        fontWeight: "400",
    },
    textField: {
        backgroundColor: "#FFFFFF",
    },

    title:{
        fontSize:"18px",
        fontWeight:"bold",
        color:COLORS.secondary,
        paddingBottom:"24px"
    },
    styleBox:{
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: '#F2F4F7',
        boxShadow: 'inset 0px 0px 20px 20px #cccccc47',

    },
    keyIcon:{
        backgroundColor:COLORS.neutral,
      color: "#0657A3",
        borderRadius:"4px",
        marginRight:"10px",
    },
    p3:{
      color:"#344054",
        fontWeight:"600",
        fontSize:"16px",
    },
    boldText: {
        "& span": {
            fontWeight: "bold"
        }
    },
}));

function Messages(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const propsUserId = props.userId || null;
    const [userId, setUserId] = React.useState(propsUserId);
    const [loader, setLoader] = React.useState(true);
    const [catalog, setCatalog] = React.useState(null);

    const [errors, setErrors] = React.useState({});
    //const [userId, setUserId] = React.useState(null);
    const [formValues, setFormValues] = React.useState({});
    const [message, setMessage] = React.useState("");
    const [currentConversation, setCurrentConversation] = React.useState(null);
    const [messages, setMessages] = React.useState([]);
    const [conversations, setConversations] = React.useState([]);
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const [countInTimeout, setCountInTimeout] = useState(0);
    const [unread, setUnread] = useState(0);
    const [unreadUsers, setUnreadUsers] = useState([]);
    const timerRef = useRef(null);

    const messagesEnd = useRef(null);

    const [width, setWidth] = useState(window.innerWidth);

    const isMobile = useIsMobile(640);
    const [inConversation, setInConversationList] = React.useState(isMobile);

    React.useEffect(() => {
        setInConversationList(isMobile);
    }, [isMobile]);

    React.useEffect(() => {
        reloadUser();

        async function fetchConversations() {
            const response = await ajaxClient().get("profile/messages/conversations");
            setConversations(response.data.users);
            setUnread(response.data.unread);
            setUnreadUsers(response.data.unreadUsers);

            /*if (response.data.users[0]) {
                moveToConversation(response.data.users[0]);
            }*/
        }

        fetchConversations();
    }, [currentConversation]);

    React.useEffect(() => {
        /*
                timerRef.current = createTimer();
                console.log('set Timer', timerRef.current);
        */

        return () => resetTimer();
    }, []);

    React.useEffect(() => {
        if (userId) {
            setLoader(true);
            resetTimer();
            timerRef.current = createTimer(3000);

            console.log('userId changed', userId);
        } else {
            setLoader(false);
        }
    }, [userId]);

    const scrollToBottom = () => {
        messagesEnd.current?.scrollIntoView({behavior: "instant"})
    }

    const resetTimer = () => {
        clearInterval(timerRef.current);
        timerRef.current = null;
    };

    function createTimer() {
        return setInterval(() => {
            async function fetchMessages(toUserId) {
                console.log('toUserId', toUserId);
                if (toUserId === 'undefined') {
                    return;
                }

                const response = await ajaxClient().get("profile/messages/" + toUserId);
                setMessages(response.data.data);

                setLoader(false);
            }

            if (userId) {
                fetchMessages(userId);
            } else {
                setLoader(false);
            }

        }, 1000);
    }

    React.useEffect(() => {
        //scrollToBottom()
    }, [messages]);

    function reloadUser() {
        getProfile().then(response => {
            let data = response.data;
            setFormValues(data);
            dispatch({type: "PROFILE", data});
        })
    }

    function handleInputChange(event) {
        const target = event.target;

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setMessage(value);
    }

    function handleSubmit(event) {
        event.preventDefault();

        submit(userId, message);
    }

    function submit(toUserId, message) {
        setIsFormProcessed(true);
        setErrors({});

        sendMessage(toUserId, message).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
            } else {
                dispatch(showSuccessSnackbar("Wiadomość została wysłana!"));
                setMessage('');
            }

            setIsFormProcessed(false);
        });
    }

    function findConversationById(id) {
        return conversations.find(conversation => conversation.id === id);
    }

    function moveToConversation(conversation) {
        setUserId(conversation.id);
        setInConversationList(false);

        setCurrentConversation(conversation);

        if (conversation.catalog) {
            setCatalog(conversation.catalog);
        } else {
            setCatalog(null);
        }

        setLoader(true);
        clearInterval(timerRef.current);
        resetTimer();
        navigate(process.env.PUBLIC_URL + "/profile/messages/" + conversation.id);
    }

    function returnToConversation() {
        setInConversationList(true);
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/profile/messages" titleBar=""/>

            <Auth>
                <Grid container className={classes.chatSection}>

                    {(!isMobile || (inConversation && isMobile) ) && (
                        <Grid item xs={12} md={3} className={classes.usersArea}>
                            <Box className={classes.msArea}>
                                <Typography className={classes.p1}>Powiadomienia</Typography>
                                <Typography className={classes.p2}>Masz <b>{unread}</b>
                                    {" "}
                                    {unread == 0 && ("nowych powiadomień")}
                                    {unread == 1 && ("nowe powiadomienie")}
                                    {(unread >= 2 && unread <= 4) && ("nowe powiadomienia")}
                                    {(unread > 4) && ("nowych powiadomień")}
                                </Typography></Box>
                            <List>
                                {conversations && conversations.filter(conversation => unreadUsers.includes(conversation.id)).map(conversation => {
                                    return (
                                        <ListItem button key={conversation.id}
                                                  onClick={() => moveToConversation(conversation)}
                                                  className={userId === conversation.id ? classes.active : classes.active}
                                                  selected={userId === conversation.id}>
                                            <ListItemIcon>
                                                <AvatarLetter alt={conversation.name}
                                                              name={(conversation.catalog && conversation.catalog.name) ? conversation.catalog.name : conversation.name}
                                                              src={`https://material-ui.com/static/images/avatar/${conversation.id}.jpg`}/>
                                            </ListItemIcon>
                                            <ListItemText
                                                className={unreadUsers.includes(conversation.id) ? classes.boldText : null}
                                                primary={conversation.name}>{(conversation.catalog && conversation.catalog.name) ? conversation.catalog.name : conversation.name}</ListItemText>
                                        </ListItem>
                                    );
                                })}

                                {conversations && conversations.filter(conversation => !unreadUsers.includes(conversation.id)).map(conversation => {
                                    return (
                                        <ListItem button key={conversation.id}
                                                  onClick={() => moveToConversation(conversation)}
                                                  className={userId === conversation.id ? classes.active : classes.active}
                                                  selected={userId === conversation.id}>
                                            <ListItemIcon>
                                                <AvatarLetter alt={conversation.name}
                                                              name={(conversation.catalog && conversation.catalog.name) ? conversation.catalog.name : conversation.name}
                                                              src={`https://material-ui.com/static/images/avatar/${conversation.id}.jpg`}/>
                                            </ListItemIcon>
                                            <ListItemText
                                                className={unreadUsers.includes(conversation.id) ? classes.boldText : null}
                                                primary={conversation.name}>{(conversation.catalog && conversation.catalog.name) ? conversation.catalog.name : conversation.name}</ListItemText>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Grid>
                    )}

                    {(!isMobile || (!inConversation && isMobile) ) && (
                        <Grid item md={6} xs={12} className={classes.chatArea}>
                            {isMobile && (
                                <Box className={classes.styleBox}>
                                    <IconButton aria-label="Zmniejsz" color="default" onClick={() => returnToConversation()}>
                                        <KeyboardArrowLeftIcon className={classes.keyIcon}/> <Typography className={classes.p3} >Wróć do listy</Typography>
                                    </IconButton >
                                </Box>
                            )}
                            <List className={classes.messageArea}>

                            {inConversation && (
                                <Typography>W konwersacji</Typography>
                            )}

                            {isMobile && currentConversation && (
                                <Box>
                                    {currentConversation.catalog ? (
                                        <Box p={3} display={"flex"} alignItems={"center"} onClick={() => navigate(process.env.PUBLIC_URL + currentConversation.catalog.url)}>
                                            <AvatarLetter name={currentConversation.name}/>
                                            &nbsp;
                                            <Typography component="p" variant="body1" className={classes.user_name}>
                                                {currentConversation.name}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box p={3} display={"flex"} alignItems={"center"}>
                                            <AvatarLetter name={currentConversation.name}/>
                                            &nbsp;
                                            <Typography component="p" variant="body1" className={classes.user_name}>
                                                {currentConversation.name}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            )}

                            {loader && (
                                <Loader/>
                            )}

                            {!loader && messages && messages.map(message => {
                                return (
                                    <ListItem key={message.id}>
                                        {message.from_user_id == userId ? (
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {moment(message.created_at).isSame(moment(), 'day') ? (
                                                        <ListItemText align="left" primary={message.to_name} secondary={moment(message.created_at).format("HH:MM")}></ListItemText>
                                                    ) : (
                                                        <ListItemText align="left" primary={message.to_name} secondary={moment(message.created_at).format("D MMMM HH:MM")}></ListItemText>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {message.menu_item && (<>
                                                        <MessageOffer menuItem={message.menu_item} date={message.date}/>
                                                    </>)}
                                                    {message.message && (
                                                        <ListItemText align="left" primary={message.message} className={classes.companyText}></ListItemText>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid container alignItems={"right"}>
                                                <Grid item xs={12}>
                                                    {moment(message.created_at).isSame(moment(), 'day') ? (
                                                        <ListItemText align="right" primary={message.from_name} secondary={moment(message.created_at).format("HH:MM")}></ListItemText>
                                                    ) : (
                                                        <ListItemText align="right" primary={message.from_name} secondary={moment(message.created_at).format("D MMMM HH:MM")}></ListItemText>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {message.menu_item && (<>
                                                        <MessageOffer menuItem={message.menu_item} date={message.date}/>
                                                    </>)}
                                                    <Box textAlign={"right"}>
                                                        <ListItemText align="right" primary={message.message} className={classes.itemText}></ListItemText>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </ListItem>
                                );
                            })}

                            <div ref={messagesEnd}/>
                        </List>

                        {userId && (
                            <form onSubmit={handleSubmit} className={classes.form}>
                                <Grid container style={{padding: '12px', height: '100%'}}>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"

                                            fullWidth
                                            size="small"
                                            id="body"
                                            label=""
                                            name="body"
                                            autoComplete="off"
                                            placeholder={"Napisz wiadomość"}
                                            value={message || ''}
                                            onChange={handleInputChange}
                                            className={classes.textField}
                                        />
                                        <Button
                                            type="submit"

                                            variant="contained"
                                            color="primary"
                                            size={"medium"}
                                            className={classes.submit}
                                            disabled={isFormProcessed}
                                            endIcon={<SendIcon/>}
                                        >
                                            {!isFormProcessed ? "Wyślij" : "Wysyłanie"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Grid>
                    )}

                    {!isMobile && (
                        <Grid item xs={3} className={classes.companyArea}>
                            {catalog && (
                                <>
                                    <Typography className={classes.title}>Firma</Typography>
                                    <MessageCatalog className={classes.messageCatalog} catalog={catalog}/>
                                </>
                            )}
                        </Grid>
                    )}

                </Grid>
            </Auth>
        </div>
    );
}

export default Messages;
