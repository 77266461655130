import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Link} from "@reach/router";
import Box from "@material-ui/core/Box";
import RatingInfo from "../RatingInfo/RatingInfo";
import {COLORS, FONTS} from "../../Theme";
import {Tooltip} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 6,
        border: '1px solid #dee2e6!important',
        boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)!important',
        width: '100%',
        display: 'flex',
        position: 'relative',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',

            '& > *': {
                width: '100% !important'


            },
        },
    },
    cardBox: {
        display: 'flex',
        width: '100%'
    },
    catalog_url: {
        textDecoration: 'none',
        color: '#12AC62'
    },
    cardContent: {
        padding: '15px',
    },
    cardMedia: {
        [theme.breakpoints.up('sm')]: {
            width: '170px',
        },
    },
    catalog_name: {
        font: 'normal normal bold 16px/29px ' + FONTS.fontFamily,
        letterSpacing: '0.14px',
        color: '#757575',
        whiteSpace: 'nowrap',
        maxWidth: '150px'
    },
    catalog_description: {
        font: 'normal normal normal 16px/29px ' + FONTS.fontFamily,
        letterSpacing: '0.14px',
        color: '#757575',
        marginTop: '9px',
        whiteSpace: 'pre-line'
    },
    catalog_distance: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        opacity: 0.8,
        padding: 3,
        backgroundColor: COLORS.primary,
        font: 'normal normal normal 11px/12px ' + FONTS.fontFamily,
        letterSpacing: '0.14px',
        color: 'white',
        marginTop: '9px',
        whiteSpace: 'pre-line'
    }
}));

export default function CatalogListItem({catalog}) {
    const classes = useStyles();
    const maxNameLength = 35; // Maksymalna długość nazwy
    const displayName = catalog.name.length > maxNameLength
        ? catalog.name.substring(0, maxNameLength) + "..."
        : catalog.name;

    return (
        <Link to={`${catalog.url}`} className={classes.catalog_url}>
            <Card className={classes.card}>
                <Box className={classes.cardBox}>
                    <CardContent className={classes.cardContent}>
                        <Tooltip title={catalog.name}>
                        <Typography gutterBottom variant="h5" component="h2" className={classes.catalog_name}>
                            {displayName}
                            {/*{catalog.opened && (
                                <Box>Czynne teraz</Box>
                            )}*/}
                        </Typography>
                        </Tooltip>
                        <Typography variant="body2" color="textSecondary" component="p"
                                    className={classes.catalog_description}>
                            {/*{catalog.categories && catalog.categories[0] && (
                                <Box component={'span'}>{catalog.categories[0].name}</Box>
                            )}

                            {catalog.categories && catalog.categories[1] && (
                                <Box component={'span'}> • {catalog.categories[1].name}</Box>
                            )}*/}

                            {catalog && (
                                <RatingInfo rating={catalog.avg_rating} numVotes={catalog.num_rating_votes} max={1}/>
                            )}
                        </Typography>

                        {catalog.distance && catalog.city && (
                            <Typography variant="body2" color="textSecondary" component="p"
                                        className={classes.catalog_distance}>
                                    <Box>{catalog.distance} km ({catalog.city.name})</Box>
                            </Typography>
                        )}

                        {!catalog.distance && catalog.city && (
                            <Typography variant="body2" color="textSecondary" component="p"
                                        className={classes.catalog_distance}>
                                <Box>({catalog.city.name})</Box>
                            </Typography>
                        )}
                    </CardContent>
                </Box>
                <CardMedia
                    className={classes.cardMedia}
                    component="img"
                    alt={catalog.name}
                    height="170"
                    width="170"
                    image={catalog.image_filename_url + "?w=170&h=170"}
                    title={catalog.name}
                />
            </Card>
            <br/>
        </Link>
    );
}
