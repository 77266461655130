import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../actions/ui";
import {updateCatalogOpeningHours} from "../../../services/catalogService";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {getProfile} from "../../../services/userService";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import DayOpeningHours from "../../Controls/DayOpeningHours";
import {hasError} from "../../../services/validators";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function OpeningHoursForm({onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({
        'monday': {
            'open': false,
            'from': '10:00',
            'to': '18:00'
        },
        'tuesday': {
            'open': false,
            'from': '10:00',
            'to': '18:00'
        },
        'wednesday': {
            'open': false,
            'from': '10:00',
            'to': '18:00'
        },
        'thursday': {
            'open': false,
            'from': '10:00',
            'to': '18:00'
        },
        'friday': {
            'open': false,
            'from': '10:00',
            'to': '18:00'
        },
        'saturday': {
            'open': false,
            'from': '10:00',
            'to': '18:00'
        },
        'sunday': {
            'open': false,
            'from': '10:00',
            'to': '18:00'
        },

    });
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const [user, setUser] = useState(null);

    function reloadUser() {
        getProfile().then(response => {
            console.log('[Orders] setUser');
            setUser(response.data);
        })
    }

    React.useEffect(() => {
        reloadUser();
    }, []);

    React.useEffect(() => {
        if (user && user.catalog && user.catalog.opening_hours) {
            console.group('user');
            console.log('user', user);
            console.groupEnd();
            setFormValues(user.catalog.opening_hours);
            console.log('user.catalog.opening_hours', user.catalog.opening_hours);
        }
    }, [user]);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    function handleInputChange(event) {
        const target = event.target;

        console.log('target', event, target);

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleOpeningHoursChange(name, values) {
        setFormValues({
            ...formValues,
            [name]: values
        });
    }

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        updateCatalogOpeningHours(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    return (
        <div>
            {user && (
                <form onSubmit={handleSubmit} className={classes.form}>

                    <DayOpeningHours name={"monday"} errors={errors.monday} values={formValues.monday}
                                     onChange={handleOpeningHoursChange} label={"Poniedziałek"}/>
                    <DayOpeningHours name={"tuesday"} errors={errors.tuesday} values={formValues.tuesday}
                                     onChange={handleOpeningHoursChange} label={"Wtorek"}/>
                    <DayOpeningHours name={"wednesday"} errors={errors.wednesday} values={formValues.wednesday}
                                     onChange={handleOpeningHoursChange} label={"Środa"}/>
                    <DayOpeningHours name={"thursday"} errors={errors.thursday} values={formValues.thursday}
                                     onChange={handleOpeningHoursChange} label={"Czwartek"}/>
                    <DayOpeningHours name={"friday"} errors={errors.friday} values={formValues.friday}
                                     onChange={handleOpeningHoursChange} label={"Piątek"}/>
                    <DayOpeningHours name={"saturday"} errors={errors.saturday} values={formValues.saturday}
                                     onChange={handleOpeningHoursChange} label={"Sobota"}/>
                    <DayOpeningHours name={"sunday"} errors={errors.sunday} values={formValues.sunday}
                                     onChange={handleOpeningHoursChange} label={"Niedziela"}/>

                    <Box my={1}>
                        <Divider variant="fullWidth"/>
                    </Box>

                    {!hideSubmitButton && (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isFormProcessed}
                        >
                            {!isFormProcessed ? <Box>Zapisz</Box> : <Box>Wysyłanie...</Box>}
                        </Button>
                    )}
                </form>
            )}
        </div>
    );
}

export default OpeningHoursForm;
