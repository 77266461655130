import React from "react";
import './HeaderBar.css';

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Background from "../assets/bg.png";
import {COLORS} from "../Theme";
import {assetsPath} from "../services/mainService";


const useStyles = makeStyles((theme) => ({
    catalog__header: {
        width: '100%',
        height: '40vh',
        maxHeight: '318px',
        /*backgroundColor: '#f5f5f5',*/
        background: `url(${assetsPath('search/background.png')})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',


        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",
    },
    pageOverlay: {
        /*width: "100%",
        height: "100%",
        backgroundColor: "#343a40",
        zIndex: 0,
        position: "absolute",
        maxHeight: '318px',
        minHeight: "318px"*/
    },
    pageOverlayDown: {
        /*backgroundColor: COLORS.primary,*/
        height: "0px",
        width: "100%",
        position: "absolute",
        bottom: "0"
    },
    catalog__header_image: {
        width: '100%',
        height: 'auto',
    },
    catalog__header_image_text: {
        position: 'absolute',
        marginBottom: 60,
        fontSize: 42,
        fontWeight: 700,
        color: COLORS.secondary,
        letterSpacing: 2,

        [theme.breakpoints.down('md')]: {
            fontSize: 30,
        },
        catalog__header_image_text2: {
            fontSize: "21px",
            fontWeight: "normal",

        },
    },
}));


function HeaderOverlay({title = null}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <section className={classes.catalog__header}>
                <div className={classes.pageOverlay}>
                    <div className={classes.pageOverlayDown}></div>
                </div>

                <Typography component="h1" variant="h1" className={classes.catalog__header_image_text}>
                    {title ? (
                        title
                    ) : ''}
                </Typography>
            </section>
        </div>
    );
}

export default HeaderOverlay;
