import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import moment from "moment/moment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {ArrowLeft, ArrowRight} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Link from "@mui/material/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {COLORS} from "../../../../Theme";
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    data: {
        fontSize: "14px",
        fontWeight: "600",
    },

    service_box: {
        borderRadius: "6px",
        boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.15)",

    },
    default: {

        display: "flex",
        alignItems: "center",
        textDecoration: 'none !important',
        marginTop:"12px !important",
        transition: "0.3s",
        opacity:"1",
        '&:hover': {
            opacity: "0.7"
        }

    },
    p: {
        marginRight: "8px",
        color: COLORS.default,
    },
    editIcon: {
        marginRight: "8px",

    },
    link: {
        textDecoration: "none !important",
        color: COLORS.default,
        fontSize: "16px",

    },


    dateRange: {
        backgroundColor: COLORS.primaryLight,
        color: COLORS.primary,
        fontSize: "medium",
        marginRight: "8px",
        padding: "8px",
        borderRadius: "8px ",

    }
}));

function CalendarSelectedDay({date = null, onClick}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <Box p={4}>
            <Box p={2} className={classes.service_box}>
                <p>
                    {date && (
                        <>
                            <Box display={"flex"} alignItems={"center"}>
                                <DateRangeOutlinedIcon className={classes.dateRange}/>
                                <Typography component="p" variant="body2" className={classes.p}>
                                    Termin: </Typography>
                                <Typography component="p" variant="body1"
                                            className={classes.data}>{date.format('lll')} </Typography>

                            </Box>
                            <Link className={classes.default} onClick={onClick} variant="body2">
                                <EditOutlinedIcon color="action" className={classes.editIcon}/> <Typography
                                className={classes.link}>Zmień termin</Typography>
                            </Link>
                        </>
                    )}
                </p>
            </Box>
        </Box>
    );
}

export default CalendarSelectedDay;
