import React from "react";
import ImageList from "@mui/material/ImageList/ImageList";
import ImageListItem from "@mui/material/ImageListItem/ImageListItem";
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import moment from "moment";

export default function ImagesList({images = [], removeImage}) {
    return (
        <ImageList cols={3} gap={8}>
            {images.map((item, i) => (
                <ImageListItem key={item.id}>
                    <img
                        src={`${item.image_filename}`}
                        srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt='Galeria zdjęć'
                        loading="lazy"
                    />
                    <ImageListItemBar
                        position="below"
                        subtitle={moment(item.created_at).format('YYYY-MM-DD')}
                        title={"Zdjęcie"}
                        actionIcon={
                            <IconButton
                                aria-label={`Usuń zdjęcie`}
                                onClick={() => removeImage(item)}
                            >
                                <DeleteOutlineIcon color={'white'}/>
                            </IconButton>
                        }
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
}
