import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Price from "../Price";
import Button from "@material-ui/core/Button";
import OrderItemProducts from "./OrderItemProducts";
import RealizedIcon from "../../assets/icons/status/realized.svg";
import ProgressIcon from "../../assets/icons/status/progress.svg";
import CancelIcon from "../../assets/icons/status/cancel.svg";
import WaitingIcon from "../../assets/icons/status/waiting.svg";
import Box from "@material-ui/core/Box";
import {FONTS} from "../../Theme";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: theme.spacing(2)
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    price: {
        fontWeight: 'bold',
        marginTop: 30,

        display: 'flex',
        justifyContent: 'space-between'
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
    },
    status: {
        marginLeft: 5,
        fontWeight: 700,

        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
        letterSpacing: "0.15px",

        "& > span": {
            marginLeft: 8
        }
    },
    statusConfirmed: {
        marginRight: 15,
        color: 'green',
        fontWeight: 300
    },
    statusWaitForPayment: {
        marginRight: 15,
        color: 'red',
        fontWeight: 300
    },
    statusDeclined: {
        marginRight: 15,
        color: 'red',
        fontWeight: 300
    },
    products: {
        lineHeight: 1.2
    }
}));

function OrderItem({order, onOpenDialog}) {
    const classes = useStyles();

    console.log('order item details: ', order);

    return (
        <Card className={classes.root} key={"order" + order.id}>
            <CardMedia
                className={classes.cover}
                image={order.catalog.image_filename_url}
                title={order.catalog.name}
            />
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography gutterBottom variant="h5" component="h1">
                        {order.id} / {order.catalog.name}
                    </Typography>

                    <Typography component="p" className={classes.products}>
                        <OrderItemProducts items={order.items}/>
                    </Typography>

                    <Typography variant="subtitle1" color="textSecondary" className={classes.price}>
                        <Price value={order.price}/>
                        <div className={classes.buttons}>
                            {(order.status === 'new' && (
                                <Box display="flex" alignItems="center" justifyContent={"center"}>
                                    <img src={RealizedIcon} />
                                    <Typography variant="p" className={classes.status}>
                                        W trakcie realizacji
                                    </Typography>
                                </Box>
                            ))}

                            {(order.status === 'confirmed' && (
                                <Typography variant="p" className={classes.status}>
                                    Potwierdzone przez kucharza
                                </Typography>
                            ))}

                            {(order.status === 'declined' && (
                                <Typography variant="p" className={classes.statusDeclined}>
                                    Zamówienie odrzucone
                                </Typography>
                            ))}

                            {(order.payment.name == 'card') && (
                                <Box>
                                    {(order.payment_status === 'confirmed' && (
                                        <Typography variant="p" className={classes.statusConfirmed}>
                                            Opłacone
                                        </Typography>
                                    ))}

                                    {(order.payment_status === 'new' && (
                                        <Typography variant="p" className={classes.statusWaitForPayment}>
                                            Czeka na wpłatę
                                        </Typography>
                                    ))}
                                </Box>
                            )}

                            <Button color="primary" aria-label="Podgląd zamówienia" onClick={() => onOpenDialog('order', order)}>
                                Podgląd
                            </Button>
                        </div>
                    </Typography>
                </CardContent>
            </div>
        </Card>
    )
}

export default OrderItem;

/*
<Card className={classes.root} onClick={onClick}>
    <CardActionArea>
        <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image={menuItem.image_filename_url}
            title="Contemplative Reptile"
        />
        <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
                {menuItem.name}
            </Typography>
        </CardContent>
    </CardActionArea>
    <CardActions>
        <div className={classes.catalog_menu_item__actions}>
            <div className={classes.catalog_menu_item__actions_price}>
                <Typography><Price value={menuItem.price}/></Typography>
            </div>
        </div>
    </CardActions>
</Card>*/
