import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HeaderBar from "../components/HeaderBar";
import Typography from "@material-ui/core/Typography";
import GeoAutoCompleteInput from "../components/GeoAutoCompleteInput";
import Container from "@material-ui/core/Container";
import {navigate} from "@reach/router";
import {getQueryLink} from "../services/localizationService";
import {useDispatch} from "react-redux";
import AddLocationIcon from "../assets/icons/add_location-24px.svg";
import FastFoodIcon from "../assets/icons/fastfood-24px.svg";
import ShoppingBagIcon from "../assets/icons/shopping_bag-24px.svg";
import DeliveryDiningIcon from "../assets/icons/delivery-dining.svg";
import EatingIcon from "../assets/icons/eating.svg";
import OrderFasterImage from "../assets/home/order-faster.png";
import OrderFasterMobileImage from "../assets/home/order-faster-mobile.png";
import Footer from "./Footer";
import Box from "@material-ui/core/Box";
import {CheckCircleRounded} from "@material-ui/icons";
import {FONTS} from "../Theme";

const useStyles = makeStyles((theme) => ({
    page: {
        //backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",
        maxHeight: '662px',
        height: '662px',

        [theme.breakpoints.down('md')]: {
            maxHeight: '562px',
            height: '562px',
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: '462px',
            height: '462px',
        },
    },
    pageWrapper: {
        position: 'relative',
        height: '100%'
    },
    pageOverlay: {
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        opacity: 0.1,
        zIndex: 0,
        position: "absolute",
        /*maxHeight: '410px',*/

        minHeight: '662px',

        [theme.breakpoints.down('md')]: {
            minHeight: '562px'
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '462px'
        },
    },
    pageContainer: {
        zIndex: 1,
        position: "relative"
    },

    pageIntro: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%'
    },
    title: {
        marginTop: '95px',
        fontWeight: 'bold',
        fontSize: '48px',
        letterSpacing: '2px',
        color: '#FFFFFF',
        opacity: '1',

        [theme.breakpoints.down('sm')]: {
            marginTop: '30px',
            fontSize: 32,
        },
    },

    search: {
        zIndex: 1001,
        marginTop: '25px'
    },

    geoInput: {
        minWidth: '500px',
        maxWidth: '500px',
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            minWidth: '300px',
            maxWidth: '300px',
        },
    },

    h2: {
        marginTop: '60px',
        font: 'normal normal normal 34px/41px ' + FONTS.fontFamily,
        color: '#FFFFFF',
        letterSpacing: '1.13px',

        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            fontSize: 18,
        },
    },

    h3: {
        marginTop: '182px',
        font: 'normal normal bold 48px/59px ' + FONTS.fontFamily,
        letterSpacing: '1.6px',
        color: '#FA4A0C'
    },

    whitePage: {
        marginTop: '-100px',
        '& h2': {
            color: '#FA4A0C',
            font: 'normal normal bold 48px/59px ' + FONTS.fontFamily,
            marginTop: '60px',
            marginBottom: '30px',
            paddingBottom: 0,
            textAlign: 'center',
        },

        [theme.breakpoints.down('md')]: {
            marginTop: '-30px',
        },

        [theme.breakpoints.down('sm')]: {
            marginTop: '-30px',

            '& h2': {
                fontSize: 18,

            },

        },
    },

    blackPage: {
        backgroundColor: '#2B2B2B',

        minHeight: 390,
        maxHeight: 390,

        marginBottom: 200,

        '& h2': {
            color: 'white',
            font: 'normal normal bold 48px/58px ' + FONTS.fontFamily,
            marginTop: 40,
            paddingBottom: 15,

            [theme.breakpoints.up('sm')]: {
                paddingRight: 32,
            },

            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                fontSize: 32
            },
        },
        '& h3': {
            color: 'white',
            font: 'normal normal bold 24px/30px ' + FONTS.fontFamily,
            paddingBottom: 15,

            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                fontSize: 18
            },
        },
        '& p': {
            color: 'white',
            font: 'normal normal normal 20px/24px ' + FONTS.fontFamily,

            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                fontSize: 16
            },
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
            maxHeight: 'none',
        },
    },

    orderFaster: {
        marginTop: -65,

        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },

    orderFasterMobile: {
        display: 'none',
        width: '100%',

        [theme.breakpoints.down('md')]: {
            marginTop: 20,
            display: 'block'
        },
    },

    deliveryDiningIcon: {
        [theme.breakpoints.down('sm')]: {
            position: 'unset',
            display: 'block',

            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },

    whitePageBox: {
        minWidth: '340px',
        maxWidth: '340px',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            maxWidth: '340px',
        },

        marginBottom: '90px',
        zIndex: 1,

        background: '#FFFFFF',
        boxShadow: '-4px 4px 22px rgba(0, 0, 0, 0.15)',
        borderRadius: '20px',

        '& h2': {
            font: 'normal normal bold 24px/18px ' + FONTS.fontFamily,
            letterSpacing: '0.8px',
            color: '#757575',
            marginTop: '15px',
            marginBottom: '15px'
        },
        '& p': {
            font: 'normal normal normal 18px/22px ' + FONTS.fontFamily,
            color: '#757575',
            letterSpacing: '0.6px',
            marginTop: '15px',
            marginBottom: '15px'
        },
        '& .iconWrapper': {
            background: '#7575751A 0% 0% no-repeat padding-box',

            width: 80,
            height: 80,
            borderRadius: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '30px'
        },
        '& .icon': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',

            width: 68,
            height: 68,
            borderRadius: 34,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },

    footer: {
        background: '#2D2D2D',

        paddingTop: 30,
        paddingBottom: 30,

        '& a': {
            color: 'white',
            display: 'block',
            font: 'normal normal normal 14px/18px ' + FONTS.fontFamily,
            letterSpacing: '0.47px',
            paddingTop: '5px',
            paddingBottom: '5px'
        }
    }
}));

export default function LandingPage() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    function handleInputAddressChange(event, data) {
        console.log('[AC]: addressChange', data);
        /*

                setFormValues({
                    ...formValues,
                    address: data.address,
                    latitude: data.latitude,
                    longitude: data.longitude,

                    city: data.city,
                    state: data.state,
                    country: data.country
                });
        */

        submit({
            ...formValues,
            address: data.address,
            latitude: data.latitude,
            longitude: data.longitude,

            city: data.city,
            state: data.state,
            country: data.country
        });
    }

    function handleClick(event) {
        submit(formValues);
    }

    function submit(data) {
        navigate(process.env.PUBLIC_URL + getQueryLink(data));
    }

    return (
        <div>
            <HeaderBar routePath="/home"/>

            <div className={classes.page}>
                <div className={classes.pageWrapper}>
                    <div className={classes.pageOverlay}></div>

                    <Container component="main" maxWidth="lg">
                        <Grid container className={classes.pageContainer}>
                            <Grid item xs={8} className={classes.pageIntro}>

                                <Typography className={classes.h2}>
                                    Lorem ipsum et dolor et simple lorem
                                </Typography>
                                <Typography className={classes.title}>
                                    Zarabiaj w domu gotując dla innych!
                                </Typography>
                                <box>
                                    <CheckCircleRounded/>
                                    <Typography>
                                        Lorem ipsum et dolor et simple lorem
                                    </Typography>
                                </box>



                            </Grid>
                            <Grid item xs={4}>
                                Prawa strona / formularz
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className={classes.whitePage}>
                    <Container component="main" maxWidth="lg">
                        <Grid item xs={12}>
                            <Grid container justify="space-around" spacing={2}>
                                <Grid item className={classes.whitePageBox}>
                                    <div className={'iconWrapper'}>
                                        <span className={'icon'}>
                                            <img src={AddLocationIcon}/>
                                        </span>
                                    </div>

                                    <Typography component={'p'}>
                                        Masz ochotę na małą przekąskę
                                        czy prawdziwą ucztę?
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.whitePageBox}>
                                    <div className={'iconWrapper'}>
                                        <span className={'icon'}>
                                            <img src={AddLocationIcon}/>
                                        </span>
                                    </div>

                                    <Typography component={'p'}>
                                        Masz ochotę na małą przekąskę
                                        czy prawdziwą ucztę?
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.whitePageBox}>
                                    <div className={'iconWrapper'}>
                                        <span className={'icon'}>
                                            <img src={AddLocationIcon}/>
                                        </span>
                                    </div>

                                    <Typography component={'p'}>
                                        Masz ochotę na małą przekąskę
                                        czy prawdziwą ucztę?
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div className={classes.blackPage}>
                    <Container component="main" maxWidth="lg">
                        <Grid container>

                            <Grid item xs={12} sm={6}>
                                <img src={OrderFasterImage} className={classes.orderFaster}/>
                                <img src={OrderFasterMobileImage} className={classes.orderFasterMobile}/>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <Footer/>
            </div>
        </div>
    );
}
