import React from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import {navigate} from "@reach/router";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > span': {
            margin: theme.spacing(2),
        },
    },
    catalog_menu_item__actions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    catalogDescription: {
        whiteSpace: 'pre-line'
    },
    box:{
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

function CatalogHeader({catalog, onClick}) {
    const classes = useStyles();

    return (
        <Card className={classes.card} onClick={onClick}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt={catalog.name}
                    height="140"
                    image={catalog.image_filename_url + "?h=140"}
                    title={catalog.name}
                />
                <CardContent className={classes.catalog__title_section}>
                    <Typography gutterBottom variant="h2" component="h1">
                        {catalog.name}
                    </Typography>
                    <Box className={classes.box}>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.catalogDescription}>
                        {catalog.description_short}
                    </Typography>
                    <Button variant="outlined" color="primary"
                            onClick={() => navigate(process.env.PUBLIC_URL + catalog.url)}>Podgląd profilu</Button>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default CatalogHeader;




/*

<Card className={classes.root} onClick={onClick}>
    <CardActionArea>
        <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image={catalog.image_filename_url}
            title="Contemplative Reptile"
        />
        <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
                {catalog.name}
            </Typography>
        </CardContent>
    </CardActionArea>
</Card>*/
