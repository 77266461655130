import React, {useState} from "react";
import {navigate, useParams} from "@reach/router";
import {useUser} from "../selector";
import HeaderBar from "../components/HeaderBar";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import ajaxClient from "../services/ajaxClient";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from '@material-ui/core/Link';
import OfferMenuItem from "../components/OfferMenuItem";
import Footer from "./Footer";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {loginCatalog} from "../services/userService";
import {showSuccessSnackbar} from "../actions/ui";
import HeaderOverlay from "../components/HeaderOverlay";
import RatingInfo from "../components/RatingInfo/RatingInfo";
import CommentDialog from "../components/Profile/Dialogs/CommentDialog";
import CommentItem from "../components/CommentItem";
import DayOpeningHours from "../components/DayOpeningHours";
import {LANGS} from "../Langs";
import GeoStaticMap from "../components/GeoStaticMap";
import CatalogAddress from "../components/CatalogAddress";
import {APP_SITE, COLORS, FONTS} from "../Theme";
import SendMessage from "../components/SendMessage";
import {assetsPath} from "../services/mainService";
import AdminInvite from "../components/AdminInvite";
import GalleryDialog from "../components/Profile/Dialogs/GalleryDialog";
import Loader from "../components/Loader";
import CatalogPremiumPhone from "../components/Catalogs/CatalogPremiumPhone";
import PremiumPhoneInfoDialog from "../components/Profile/Dialogs/PremiumPhoneInfoDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
    },
    fullButtons: {
        display: 'flex',
        justifyContent: 'space-between',

        '& > *': {
            minWidth: 180,

            [theme.breakpoints.down('md')]: {
                minWidth: 140,
                width: 140,
            },
        },
    },
    miniCart: {
        /*position: 'sticky',
        top: 90*/
    },
    card: {
        borderRadius: 6,
        marginBottom: theme.spacing(2),
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
    },
    breadcrumbLink: {
        fontSite: '16px',
        fontFamily: FONTS.fontFamily,
        letterSpacing: '0.15px',
        color: '#757575'
    },
    breadcrumbActiveLink: {
        fontSite: '16px',
        fontFamily: FONTS.fontFamily,
        letterSpacing: '0.15px',
        fontWeight: 'bold',
        color: '#757575'
    },
    img: {
        marginTop: '24px',
        marginBottom: '-4px',
    },

    bbox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '40px',
        marginBottom: '16px',
    },
    catalog__name: {
        font: 'normal normal normal 28px ' + FONTS.fontFamily,
        fontweight: 700,
        color: COLORS.secondary,
        marginTop: '30px'
    },
    catalog__description: {
        fontFamily: FONTS.fontFamily, fontStyle: "normal", fontWeight: "400",
        color: '#757575',
        marginTop: '15px',
        whiteSpace: 'pre-line'
    },
    catalog__header_image: {
        width: '100%',
        height: 'auto',
    },
    catalog__header_image_text: {
        position: 'absolute',
        marginBottom: 60,
        fontSize: 60,
        fontWeight: 'bold',
        color: 'white',
        letterSpacing: 2,

    },
    catalog__container: {
        zIndex: 2,
        position: 'relative',
        marginTop: -80,
        marginBottom: 30
    },
    catalog__title_section: {
        padding: theme.spacing(4)
    },
    order__title: {
        textAlign: 'center',
        font: 'normal normal bold 34px/41px ' + FONTS.fontFamily,
        color: COLORS.secondary,
        marginTop: '15px'
    },
    order__subtitle: {
        color: '#757575',
        font: 'normal normal normal 16px/29px ' + FONTS.fontFamily,
        marginTop: '10px'
    },
    order___subtitle: {
        color: '#757575',
        font: 'normal normal normal 18px/29px ' + FONTS.fontFamily,
        marginTop: '10px',
        display: "inline-flex",
        borderColor: "#B7B7B7"
    },
    order_button: {
        width: "100%",
        height: "50px",
        color: "white",
        backgroundColor: COLORS.secondary
    },
    order_box: {
        marginLeft: "auto",
        marginRight: "auto",
        fontFamily: FONTS.fontFamily,
        color: COLORS.default,
    },
    collection_name: {
        color: COLORS.secondary,
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "18px",
        lineHeight: "150%",
        marginTop: '8px',

    },
    box: {
        border: '1px solid #DDDDDD',
        borderRadius: '6px',
        marginTop: '16px',
    },
    typo: {
        marginTop: '24px',
        textAlign: "center",
        fontSize: '16px',
        color: '#757575',
    },
    button: {
        marginTop: '16px',
        marginBottom: '24px',
    },
    buttons:{
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: 0,
            marginTop: theme.spacing(1),
        },
    }
}));


function Catalog() {
    const params = useParams();
    const classes = useStyles();

    const user = useUser();
    const dispatch = useDispatch();

    const [catalog, setCatalog] = useState([]);

    //const [daysOff, setDaysOff] = useState([]);

    const [openDialog, setOpenDialog] = useState({
        'comment': {
            open: false,
            data: null
        },
        'gallery': {
            open: false,
            data: null
        },
        'premiumPhoneInfo': {
            open: false,
            data: null
        }
    });

    React.useEffect(() => {
        async function fetchCatalog() {
            const response = await ajaxClient().get("catalogs/" + params.slug);
            setCatalog(response.data.data);
            //console.log('catalog data: ', getDaysOff(response.data.data.opening_hours));
            //setDaysOff(getDaysOff(response.data.data.opening_hours));
        }

        fetchCatalog();
    }, []);

    function handleCatalogLogin(catalogId) {
        loginCatalog(catalogId).then(response => {
            console.log("login response: " + response.toString());
            let data = response.data;

            if (data && data.error) {
                //setErrors(data.error);
                dispatch(showSuccessSnackbar("Wystąpił błąd"));
            } else {
                console.log('dispatch actions', data);
                /*localStorage.session = data.token;
                dispatch({type: "LOGIN", payload: data});*/
                dispatch(showSuccessSnackbar("Zostałeś zalogowany!"));
            }
        });
    }

    function handleOpenDialog(name, data = null) {

        console.log('[CATALOG]', data);

        setOpenDialog({
            ...openDialog,
            [name]: {
                open: true,
                data: data
            }
        });

        console.log('openAddDialog', name, data);
    }

    function handleCloseDialog(name, result) {
        console.log('handleCloseDialog', name);

        //reloadUser();

        setOpenDialog({
            ...openDialog,
            [name]: {
                ...openDialog[name],
                open: false
            }
        });
    }

    let city = 'city',
        country = 'country';

    if (!catalog) {
        return <div>Ładowanie...</div>
    }

    return (
        <div className={classes.root}>
            <HeaderBar routePath="/catalogs" titleBar="Catalog"/>

            <HeaderOverlay title={catalog.name}></HeaderOverlay>

            <Container maxWidth="lg" className={classes.catalog__container}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="flex-start"
                >
                    {catalog && catalog.id ? (
                        <>
                            <Grid item md={8} sm={8} xs={12}>
                                <Card className={classes.card}>
                                    <CardActionArea>
                                        <CardContent className={classes.catalog__title_section}>
                                            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbLink}
                                                         separator={<NavigateNextIcon fontSize="small"/>}>
                                                <Link color="inherit" href="/" to={'/'}>
                                                    Strona główna
                                                </Link>
                                                <Link
                                                    color="inherit"
                                                    href={`/${country}/${city}/catalogs`}
                                                    onClick={() => navigate(process.env.PUBLIC_URL + `/${country}/${city}/catalogs`)}
                                                >
                                                    {LANGS.namelinkCatalog}
                                                </Link>

                                                <Link
                                                    color="textPrimary"
                                                    href={`${catalog.url}`}
                                                    onClick={() => navigate(`${catalog.url}`)}
                                                    aria-current="page"
                                                    className={classes.breadcrumbActiveLink}
                                                >
                                                    {catalog.name}
                                                </Link>

                                            </Breadcrumbs>

                                            <Typography className={classes.catalog__name}>
                                                {catalog.name}
                                            </Typography>
                                            <Typography className={classes.catalog__description}>
                                                {catalog.description}
                                            </Typography>

                                            <Box pt={2} pb={2}>
                                                {catalog && (
                                                    <RatingInfo rating={catalog.avg_rating} numVotes={catalog.num_rating_votes}
                                                                max={1}/>
                                                )}
                                            </Box>

                                            <CommentDialog open={openDialog.comment.open} data={openDialog.comment.data}
                                                           onClose={() => handleCloseDialog('comment')}/>

                                            <GalleryDialog open={openDialog.gallery.open} data={openDialog.gallery.data}
                                                           onClose={() => handleCloseDialog('gallery')}/>

                                            {catalog && catalog.premium_phone && catalog.premium_phone.active && !catalog.premium_phone.disabled && (
                                                <PremiumPhoneInfoDialog open={openDialog.premiumPhoneInfo.open} catalog={catalog}
                                                           onClose={() => handleCloseDialog('premiumPhoneInfo')}/>
                                            )}

                                            <Box pt={2} pb={2}>
                                                <Button className={classes.buttons} size={"large"} variant="outlined" color="default"
                                                        onClick={() => handleOpenDialog('comment', catalog)}>
                                                    Dodaj opinię
                                                </Button>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <Button className={classes.buttons} size={"large"} variant="outlined" color="default"
                                                        onClick={() => handleOpenDialog('gallery', catalog)}>
                                                    Zobacz galerię zdjęć
                                                </Button>
                                            </Box>

                                            {catalog && user && (user.type === 'admin') && catalog.id && (
                                                <Box className={classes.fullButtons}>
                                                    <Button color="primary" variant="contained" size="large" onClick={() => handleCatalogLogin(catalog.id)}>Zaloguj się jako ten użytkownik</Button>
                                                    <AdminInvite catalog={catalog}/>
                                                </Box>
                                            )}
                                        </CardContent>
                                    </CardActionArea>
                                </Card>

                                {catalog && catalog.premium_phone && catalog.premium_phone.active && !catalog.premium_phone.disabled && (
                                    <CatalogPremiumPhone catalog={catalog} szerokosc={500} wysokosc={300} onClickPhone={() => handleOpenDialog('premiumPhoneInfo', catalog)} showAvatar={true}/>
                                )}

                                <Grid container spacing={2} direction="row" alignItems="flex-start">
                                    {catalog.menu_items && catalog.menu_items.map(menuItem => {
                                        return (
                                            <Grid item md={12} sm={12} xs={12} key={"menuItem" + menuItem.id}>
                                                <OfferMenuItem menuItem={menuItem} catalog={catalog}/>
                                            </Grid>
                                        )
                                    })}
                                </Grid>

                                {(catalog.menu_items && (catalog.menu_items === null || catalog.menu_items.length === 0)) && (
                                    <div>
                                        <Typography gutterBottom variant="h5" component="h2" color="primary"
                                                    className={classes.collection_name}>
                                            Oferty
                                        </Typography>
                                        <Box className={classes.box} variant="p" textAlign={"center"}>

                                            <div>
                                                {APP_SITE == 'jakieoze' && (
                                                    <Typography component="p" className={classes.typo}>
                                                        Ten instalator nie ma jeszcze ofert. Skontaktuj się z nim.
                                                    </Typography>
                                                )}

                                                {APP_SITE == 'paramedycy' && (
                                                    <Typography component="p" className={classes.typo}>
                                                        Ta firma nie ma jeszcze ofert.
                                                    </Typography>
                                                )}
                                            </div>
                                            <img src={assetsPath('noOfert.png')} className={classes.img}/>

                                        </Box>
                                    </div>
                                )}

                                <Box className={classes.bbox}>
                                    <Typography gutterBottom variant="h5" component="h2" color="primary"
                                                className={classes.collection_name}>
                                        Opinie
                                    </Typography>
                                    <Button size={"large"} variant="outlined" color="default"
                                            onClick={() => handleOpenDialog('comment', catalog)}>
                                        Dodaj opinię
                                    </Button>
                                </Box>

                                {(catalog.comments && (catalog.comments === null || catalog.comments.length === 0)) && (
                                    <Box className={classes.box} variant="p" textAlign={"center"}>
                                        <div>
                                            <Typography component="p" className={classes.typo}>
                                                Ten wpis nie ma jeszcze opinii - bądź pierwszy/a.
                                            </Typography>
                                        </div>
                                        <Grid className={classes.button} container justify="center">
                                            <Button size={"large"} variant="outlined" color="default"
                                                    onClick={() => handleOpenDialog('comment', catalog)}>
                                                Dodaj opinię
                                            </Button>
                                        </Grid>
                                    </Box>
                                )}

                                {catalog.comments && catalog.comments.map(comment => {
                                    return (
                                        <CommentItem comment={comment}/>
                                    )
                                })}
                            </Grid>

                            <Grid item md={4} sm={4} xs={12} className={classes.miniCart}>

                                {catalog && catalog.premium_phone && catalog.premium_phone.active && !catalog.premium_phone.disabled && (
                                    <CatalogPremiumPhone catalog={catalog} szerokosc={500} wysokosc={300} onClickPhone={() => handleOpenDialog('premiumPhoneInfo', catalog)} showAvatar={false}/>
                                )}

                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography align="center" className={classes.order__title}>
                                            Kontakt
                                        </Typography>
                                        <br/>
                                        <DayOpeningHours catalog={catalog}/>

                                        <Box pt={2} pb={1}>
                                            <GeoStaticMap
                                                id="google-map"
                                                position={
                                                    {
                                                        lat: catalog.latitude,
                                                        lng: catalog.longitude
                                                    }
                                                }
                                                style={{width: '100%', height: '240px'}}/>
                                        </Box>

                                        <CatalogAddress catalog={catalog}/>
                                        <br/>
                                        <SendMessage catalog={catalog} menuItem={null} type={"catalog"}/>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>
                    ) : (
                        <Grid item md={12} sm={12} xs={12}>
                            <Box justifyContent={"center"} alignItems={"center"}>
                                <Loader/>
                            </Box>
                        </Grid>
                    )}

                </Grid>
            </Container>
            <Footer/>
        </div>
    );
}

export default Catalog;
