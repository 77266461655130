import React from "react";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {PhotoCamera} from "@material-ui/icons";
import {Typography} from "@material-ui/core";
import {COLORS, FONTS} from "../Theme";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {Paper} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {styled} from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    root: {
        marginTop: theme.spacing(1),
    },
    subtitle: {
        font: 'normal normal normal 16px/24px ' + FONTS.fontFamily,
        letterSpacing: '0.15px',
        opacity: '0.59',
        marginTop: '5px',
        marginBottom: '5px'
    },

    paper: {
        backgroundColor: "#F9FAFB",
        marginBottom: theme.spacing(3),
        border: "none"
    },
    box: {
        /*minWidth: "50%", width: "50%"*/
    },
}));

const FileUploader = props => {
    const classes = useStyles();
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        props.onChange(event);
    };

    const handleDelete = event => {
        event.stopPropagation();
        console.log('USUWAM ZDJECIE', props.catalog.id);
    };

    console.log('[FileUploader]', props.image);

    return (
        <div className={classes.root}>
            <Box
                className={classes.paper}
            >
                <Box
                    onClick={handleClick}
                    className={classes.box}
                    sx={{
                        mx: 'auto',
                        p: 2,
                    }}
                >
                    <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Box width={120} height={120} style={{backgroundColor: "#EAECF0"}} display={"flex"}
                                 justifyContent={"center"} alignItems={"center"}>
                                {props.image ? (
                                    <CardMedia
                                        style={{width: 120, height: 120}}
                                        image={(props.image && props.blob) ? props.image : props.image + "?w=120&h=120"}
                                        title={props.title}
                                        className={classes.media}
                                    />
                                ) : (
                                    <PhotoCamera style={{color: "white", width: 44, height: 44}}/>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Typography color="primary">Kliknij, aby dodać zdjęcie</Typography>
                            <Typography color="secondary" className={classes.subtitle}>{props.subtitle}</Typography>
                            {/*{props.image && (
                                <a href="#" onClick={handleDelete}>usuń</a>
                            )}*/}
                        </Grid>
                    </Grid>
                </Box>
            </Box>


            <input type="file"
                   name={props.name}
                   accept={props.accept}
                   ref={hiddenFileInput}
                   onChange={handleChange}
                   style={{display: 'none'}}
            />
        </div>
    );
};
export default FileUploader;
