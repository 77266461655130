import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {hasError} from "../../../../../services/validators";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ajaxClient from "../../../../../services/ajaxClient";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    file: {
        width: 200,
        height: 150
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formControl: {
        minWidth: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ProductFields({categoryType, productType, producer, product, onChange, errors}) {
    const classes = useStyles();

    console.log('[onChange product: prop]', producer, product);

    const [producers, setProducers] = useState([]);
    const [products, setProducts] = useState([]);
    const [producerId, setProducerId] = useState(producer ? producer.id : (product ? product.producer_id : ''));
    const [productId, setProductId] = useState((product && product.id) ? product.id : '');

    React.useEffect(() => {
        console.log('selected Producer Id', producerId);
        console.log('selected productId', productId);

        async function fetchProducers() {
            const response = await ajaxClient().get("producers?includes=products");
            setProducers(response.data.data);
        }

        fetchProducers();
    }, []);

    React.useEffect(() => {
        if (!producerId) {
            setProducerId((product && product.producer_id) ? product.producer_id : null);
        }
    }, [product]);

    React.useEffect(() => {
        setProducerId(producer ? producer.id : (product ? product.producer_id : ''));
    }, [producer]);

    React.useEffect(() => {
        refreshProducts();
    }, [producerId, producers]);

    const refreshProducts = () => {
        let producer2 = producers.find((element) => {
            //console.log('[producers cmp]', element.id, producerId);
            return element.id === producerId;
        });

        if (producer2 && producer2.products) {
            setProducts(producer2.products)
        } else {
            setProducts([]);
        }
    }

    const handleProducerChange = (e, index) => {
        console.log('handleProducerChange', e.target.value);
        setProducerId(e.target.value);
        setProductId(null);

        let producer = producers.find((element) => {
            return element.id === e.target.value;
        });

        onChange(producer, null);
    };

    const handleChange = (e) => {
        let product = products.find((element) => {
            return element.id === e.target.value;
        });

        console.log('onChange', product);
        setProductId(product ? product.id : '');
        onChange(null, product);
    }

    return (
        <Box>
            {categoryType == "pv" && (
            <Grid container spacing={3}>
                <Grid item xs={3}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            {/*<InputLabel id="demo-simple-select-outlined-label">Producent</InputLabel>*/}
                            <Select
                                id="producer_id"
                                name="producer_id"

                                value={producerId}
                                onChange={handleProducerChange}

                                error={hasError('producer_id', errors)}
                                helperText={
                                    hasError('producer_id', errors) ? errors.producer_id[0] : null
                                }
                            >
                                <MenuItem value="">
                                    Wybierz producenta
                                </MenuItem>

                                {producers && producers.map(producerItem => {
                                    return (
                                        <MenuItem value={producerItem.id} key={producerItem.id}>
                                            {producerItem.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                </Grid>
                {products && products.length > 0 && (
                    <Grid item xs={3}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Model</InputLabel>
                            <Select
                                id="product_id"
                                name="product_id"
                                label={"Produkt"}

                                value={productId}
                                onChange={handleChange}

                                error={hasError('product_id', errors)}
                                helperText={
                                    hasError('product_id', errors) ? errors.product_id[0] : null
                                }
                            >
                                <MenuItem value="">
                                    Wybierz kategorię
                                </MenuItem>

                                {products.map(productItem => {
                                    return (
                                        <MenuItem value={productItem.id} key={productItem.id}>
                                            {productItem.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            )}
        </Box>
    );
}

export default ProductFields;
