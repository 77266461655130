import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../actions/ui";
import {sendMessage} from "../../../services/catalogService";
import Box from "@material-ui/core/Box";
import {getProfile} from "../../../services/userService";
import Typography from "@material-ui/core/Typography";
import Price from "../../Price";
import Power from "../../Power";
import CheckIcon from "../../../assets/icons/check-24px.svg";
import {APP_SITE, COLORS, FONTS} from "../../../Theme";
import Calendar from "./Partial/Calendar";
import AvatarLetter from "../../AvatarLetter";
import Grid from "@material-ui/core/Grid";
import SendMessage from "../../SendMessage";
import MessageCalendarSelectedOffer from "./Partial/MessageCalendarSelectedOffer";
import Send from "./Partial/Send";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    label: {
        fontFamily: FONTS.fontFamily, fontStyle: "normal", fontWeight: "400", fontSize: "16px", lineHeight: "150%", display: "flex", alignItems: "center", color: "#06106D", paddingBottom: 8,
        '& > span': {
            color: "#757575",
            fontWeight: 600
        }
    },
    catalog_box: {
        background: COLORS.neutral,
    },
    user_name: {
        color: COLORS.secondary,
        fontFamily: FONTS.fontFamily,
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "150%",
        marginLeft: 6
    },
    city: {
        fontWeight: 500,
        color: COLORS.default,
        fontSize: "14px",
        fontFamily: FONTS.fontFamily,
    },


    truncate: {
        width: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",color:"#757575"
    },
}));

function MessageCalendarForm({catalog, menuItem, onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({
        'name': "",
        'phone': "",
        'email': "",
        'date': "",
        'menu_item_id': "",
    });
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const [user, setUser] = useState({
        'name': "",
        'phone': "",
        'email': "",
    });

    function reloadUser() {
        getProfile().then(response => {
            console.log('[Orders] setUser', menuItem);
            if (response.data) {
                setUser(response.data);
                setFormValues({
                    ...formValues,
                    'name': response.data.name,
                    'phone': response.data.phone,
                    'email': response.data.email,
                    'date': "",
                    "menu_item_id": menuItem.id
                })
            }
        })
    }

    React.useEffect(() => {
        reloadUser();
    }, []);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            setIsFormProcessed(true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    function handleInputChange(event) {
        const target = event.target;

        console.log('target', event, target);

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleDateChange(date) {
        const value = date;
        const name = 'date';

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        setIsFormProcessed(true);
        submit(formValues);
    }

    function submit(data) {
        sendMessage(catalog, data).then(response => {
            console.log('response', response);

            if (response && response.data && response.data.error) {
                //console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Wiadomość została wysłana!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    return (
        <div>
            {(user || 1==1) && (
                <form onSubmit={handleSubmit} className={classes.form}>

                    {catalog && (
                        <>
                            <Box p={2} display={"flex"} alignItems={"center"} className={classes.catalog_box}>
                                <AvatarLetter name={catalog.name}/>
                                &nbsp;
                                <Typography component="p" variant="body1" className={classes.user_name}>
                                    {catalog.name}<br/>
                                    <small className={classes.city}>{catalog.city.name}</small>
                                </Typography>
                                <Typography component="p" variant="body1">

                                </Typography>
                            </Box>
                        </>
                    )}

                    <Calendar openingHours={catalog.opening_hours} onSuccess={handleDateChange}/>

                    <MessageCalendarSelectedOffer menuItem={menuItem}/>

                    <div className={classes.separator}></div>

                    {/*<TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Imię i nazwisko"
                        name="name"
                        value={formValues.name || user.name}
                        onChange={handleInputChange}
                        error={hasError('name', errors)}
                        helperText={
                            hasError('name', errors) ? errors.name[0] : null
                        }
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Adres e-mail"
                        name="email"
                        value={formValues.email || user.email}
                        onChange={handleInputChange}
                        error={hasError('email', errors)}
                        helperText={
                            hasError('email', errors) ? errors.email[0] : null
                        }
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="phone"
                        label="Telefon"
                        name="phone"
                        type="number"
                        value={formValues.phone || user.phone}
                        onChange={handleInputChange}
                        error={hasError('phone', errors)}
                        helperText={
                            hasError('phone', errors) ? errors.phone[0] : null
                        }
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="body"
                        label="Treść wiadomości"
                        name="body"
                        autoFocus
                        rows="12"
                        multiline={true}
                        value={formValues.body || ''}
                        onChange={handleInputChange}
                        error={hasError('body', errors)}
                        helperText={
                            hasError('body', errors) ? errors.body[0] : null
                        }
                    />*/}

                    {!hideSubmitButton && (
                        <Send/>

                    )}
                </form>
            )}
        </div>
    );
}

export default MessageCalendarForm;
