import {combineReducers} from 'redux'
import cartReducer from "./cart";
import uiReducer from "./ui";
import userReducer from "./user";
import orderReducer from "./order";
import {addCatalogIdReducer, catalogsReducer} from "./catalog";


export const initialState = {
    cart: {},
    user: {
        currentUser: {},
    },
    order: {
        errors: {},
        payment: 'cash',
    },
    isLoggedIn: false,
    catalogId: ``,
    catalogs: {
        catalog: {}
    }
};

const reducer = combineReducers({
    cart: cartReducer,
    user: userReducer,
    ui: uiReducer,
    order: orderReducer,
    catalogId: addCatalogIdReducer,
    catalogs: catalogsReducer
});

export default reducer;
