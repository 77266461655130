import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import {Icon} from "@material-ui/core";
import {clearSnackbar} from "../actions/ui";
import Alert from "@material-ui/lab/Alert";

export default function SuccessSnackbar() {
    const dispatch = useDispatch();

    const {successSnackbarMessage, successSnackbarOpen} = useSelector(
        state => state.ui
    );

    function handleClose() {
        dispatch(clearSnackbar());
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={successSnackbarOpen}
            autoHideDuration={4000}
            onClose={handleClose}
            aria-describedby="client-snackbar"
        >
            <Alert onClose={handleClose} severity="success">
                {successSnackbarMessage}
            </Alert>
        </Snackbar>
    );
}