import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {COLORS} from "../../../../Theme";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Send({date}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    return (

        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isFormProcessed}
        >
            {!isFormProcessed ? <Box>Wyślij</Box> : <Box>Wysyłanie...</Box>}
        </Button>

    );
}

export default Send;
