import {useUser} from "../../../selector";
import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {showSuccessSnackbar} from "../../../actions/ui";
import {Redirect} from "@reach/router";
import {useFetchProfile} from "../../../actions";
import {hasError} from "../../../services/validators";
import Box from "@material-ui/core/Box";
import FileUploader from "../../FileUploader";
import Divider from "@material-ui/core/Divider";
import DialogContentText from "@material-ui/core/DialogContentText";
import {updatePremiumPhone} from "../../../services/premiumPhoneService";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TermsCheckbox from "../../TermsCheckbox";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    media: {
        height: '100px',
        '&:hover': {
            color: '#ed1212',
            cursor: 'pointer'
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    number:{
        backgroundColor: "#F2F4F7",
        borderRadius: "6px",
        maxWidth: "251px"
    },
    t1: {
        fontSize:"16px",
        marginLeft:"6%",
        marginTop:"12%",
    },
    t2: {
        marginLeft:"6%",
        fontSize: "24px",
        fontWeight:"700",

    },
    t3:{
        fontSize: "14px"
    }
}));

function PremiumPhoneForm({onSuccess, onError, hideSubmitButton = true, triggerSubmit = false}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errors, setErrors] = React.useState({});
    const [formValues, setFormValues] = React.useState({});
    const [isFormProcessed, setIsFormProcessed] = useState(false);

    const fetchProfile = useFetchProfile();
    const user = useUser();

    const fileInputRef = useRef(null);
    const [map, setMap] = React.useState(null);

    React.useEffect(() => {
        fetchProfile();
    }, []);

    React.useEffect(() => {
        if (user && user.catalog) {
            setFormValues(user.catalog.premium_phone);
        }
    }, [user]);

    React.useEffect(() => {
        if (triggerSubmit) {
            console.log('triggerSubmit', true);
            submit(formValues);
        }

    }, [triggerSubmit]);

    const handleAttacheInputFile = () => {
        fileInputRef.current.click();
    };

    function handleInputChange(event) {
        const target = event.target;

        console.log('target', event, target);

        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function handleInputAddressChange(event, data) {
        console.log('[AC]: addressChange', data);

        setFormValues({
            ...formValues,
            address: data.address,
            latitude: data.latitude,
            longitude: data.longitude,

            city: data.city,
            state: data.state,
            country: data.country
        });
    }

    const handleInputFileChange = (event) => {
        const target = event.target;
        const name = target.name;

        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);

        reader.onload = function () {
            setFormValues({
                ...formValues,
                [name]: reader.result
            });
        };
    };

    function handleSubmit(event) {
        console.log('SUBMIT', formValues);
        event.preventDefault();

        submit(formValues);
    }

    function submit(data) {
        setIsFormProcessed(true);

        updatePremiumPhone(data).then(response => {
            console.log('response', response);

            if (response.data.error) {
                console.log('response data error', response.data.error);
                setErrors(response.data.error);
                dispatch(showSuccessSnackbar("Wypełnij wszystkie wymagane pola"));
                onError(response.data.error);
            } else {
                dispatch(showSuccessSnackbar("Sukces!"));
                onSuccess(formValues);
            }

            setIsFormProcessed(false);
        });
    }

    function handleMapLoaded(map) {
        console.log('handleMapLoaded', map);
        setMap(map)
    }

    if (!user || !user.name) {
        return <Redirect to="/login" noThrow/>;
    }

    return (
        <Box>
            <form onSubmit={handleSubmit} className={classes.form}>

                <FileUploader
                    name="image"
                    accept={'.peg,.png,.jpg,.gif'}
                    type="file"
                    image={formValues.image && formValues.image !== '' ? formValues.image : formValues.avatar_filename_url}
                    blob={formValues.image && formValues.image !== '' ? true : false}
                    title={'Wgraj logo firmy'}
                    subtitle={'Maksymalna wielkość pliku do 1 MB'}
                    onChange={handleInputFileChange}/>


                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Imię i nazwisko lub pseudonim"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={formValues.name || ''}
                    onChange={handleInputChange}
                    error={hasError('name', errors)}
                    helperText={
                        hasError('name', errors) ? errors.name[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="description"
                    label="Krótki opis teleporady"
                    name="description"
                    rows="4"
                    multiline={true}
                    value={formValues.description || ''}
                    onChange={handleInputChange}
                    error={hasError('description', errors)}
                    helperText={
                        hasError('description', errors) ? errors.description[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="phone"
                    label="Twój numer telefonu"
                    name="phone"
                    value={formValues.phone || ''}
                    onChange={handleInputChange}
                    error={hasError('phone', errors)}
                    helperText={
                        hasError('phone', errors) ? errors.phone[0] : null
                    }
                />
                <Box mt={2}>
                <Grid container justifyContent={"space-between"}>
                    <Grid className={classes.number} item xs={12} sm={6}>
                        <Typography className={classes.t1}>Twój wirtualny telefon:</Typography>
                        <Typography color="primary" className={classes.t2}>599 555 555</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.t3}>Wirtualny telefon to numer wygenerowany
                            automatycznie dla Ciebie na bazie Twojego numeru i pojawi się on w Twoim profilu.</Typography>
                    </Grid>
                </Grid>
                </Box>
                <br/>
                <DialogContentText className={classes.text}>
                    Rozliczenie
                </DialogContentText>

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="bank"
                    label="Nazwa banku"
                    name="bank"
                    autoComplete="bank"
                    autoFocus
                    value={formValues.bank || ''}
                    onChange={handleInputChange}
                    error={hasError('bank', errors)}
                    helperText={
                        hasError('bank', errors) ? errors.bank[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="bank_nr"
                    label="Numer konta"
                    name="bank_nr"
                    autoComplete="bank_nr"
                    autoFocus
                    value={formValues.bank_nr || ''}
                    onChange={handleInputChange}
                    error={hasError('bank_nr', errors)}
                    helperText={
                        hasError('bank_nr', errors) ? errors.bank_nr[0] : null
                    }
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="bank_details"
                    label="Twoje dane adresowe"
                    name="bank_details"
                    rows={4}
                    value={formValues.bank_details || ''}
                    onChange={handleInputChange}
                    error={hasError('bank_details', errors)}
                    helperText={
                        hasError('bank_details', errors) ? errors.bank_details[0] : null
                    }
                />
                <TermsCheckbox className={classes.checkbox}/>

                <Box my={1}>
                    <Divider variant="fullWidth"/>
                </Box>

                {!hideSubmitButton && (
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isFormProcessed}
                    >
                        {!isFormProcessed ? <Box>Zapisz</Box> : <Box>Wysyłanie...</Box>}
                    </Button>
                )}
            </form>
        </Box>
    );
}

export default PremiumPhoneForm;
