import ajaxClient from "../services/ajaxClient";

const API_CATALOG_URL = "http://127.0.0.1:8000/api/catalogs";

function addCatalogId(id) {
    return {
        type: "ADD_CATALOG_ID",
        payload: id
    };
}

function fetchCatalog(id) {
    return async dispatch => {
        ajaxClient().get(`catalogs/${id}`)
            .then(response => {
                const payload = response.data.data;
                dispatch({ type: "FETCH_CATALOG", payload });
            })
            .catch(error => {
                dispatch({ type: "DEFAULT" });
            });
    };
}

/*
const addCatalogId = id => {
    return dispatch => {
        dispatch({ type: "ADD_CATALOG_ID", id });
    };
};

function setCatalogId(id) {
    return {
        type: "ADD_CATALOG_ID",
        payload: id
    };
}
*/

function listCatalogs() {
    return async dispatch => {
        ajaxClient().get(API_CATALOG_URL)
            .then(response => {
                const payload = response.data;
                dispatch({ type: "LIST_CATALOGS", payload });
            })
            .catch(error => {
                dispatch({ type: "DEFAULT" });
            });
    };
}

export {
    listCatalogs,
    addCatalogId,
    fetchCatalog
};
