import React, {useRef, useState} from "react";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {hasError} from "../../../../../services/validators";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FileUploader from "../../../../FileUploader";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    file: {
        width: 200,
        height: 150
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    h1:{
        fontSize:'20px',
    },
    formControl: {
        minWidth: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    card: {
        marginBottom: 15
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    b1:{
        borderRadius:'4px',
    }
}));

function MenuItemFields({category, formValues, onChange, errors}) {
    const classes = useStyles();

    const [inputList, setInputList] = useState((formValues.menu_items && formValues.menu_items.length > 0) ? formValues.menu_items : [{id: null, name: "", description: "", price: 0}]);
    const fileInputRef = useRef(null);

    React.useEffect(() => {
        setInputList((formValues.menu_items && formValues.menu_items.length > 0) ? formValues.menu_items : [{id: null, name: "", description: "", price: 0}]);
    }, [formValues]);

    const handleInputChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);

        onChange(list);
    };

    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const handleAddClick = () => {
        setInputList([...inputList, {name: "", description: "", price: 0, tags: []}]);
    };

    const handleInputFileChange = (e, index) => {
        const target = e.target;
        const name = target.name;

        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        const list = [...inputList];

        console.log('list', name, list);

        reader.onload = function () {
            list[index][name] = reader.result;
            setInputList(list);
            onChange(list);
        };
    };

    function getTags(categoryId) {
        if (!categoryId) {
            return [];
        }

        return category.tags.filter((tag) => {
            return tag.category_id == categoryId
        })
    }

    function handleTagChange(tags, event, index) {
        if (!tags) {
            return null;
        }

        const target = event.target;
        let tagId =  target.value;

        if (target.checked) {
            //console.log('find tag', tags.find(tag => tag.id == tagId), tags, tagId);

            let tag = tags.find(tag => tag.id == tagId);

            addTag(tag, index);
        } else {
            removeTag(tags.find(tag => tag.id == tagId), index);
        }
    }

    function removeTag(tagItem, index) {
        //console.log('remove tagItem 1', tagItem, index);
        //console.log('inputList[index]', inputList[index]);
        //console.log('new items', inputList[index].tags.filter(tag => tag.id != tagItem.id));

        let copyinputList = inputList;

        if (copyinputList[index].tags) {
            copyinputList[index].tags = inputList[index].tags.filter(tag => tag.id != tagItem.id);
        }

        setInputList(copyinputList);
        onChange(copyinputList);
    }

    function addTag(tagItem, index) {
        console.log('add tag: tagItem', tagItem);

        if (!tagItem) {
            return;
        }

        let copyinputList = inputList;

        if (copyinputList[index].tags) {
            copyinputList[index].tags = [...inputList[index].tags, tagItem];
        } else {
            copyinputList[index].tags = [tagItem];
        }

        setInputList(copyinputList);
        onChange(copyinputList);
    }

    function tagSelected(tagItem, index) {
        console.log('inputList', index, inputList);

        if (!inputList[index].tags) {
            console.log('input null tags');
            return false;
        }

        let item = inputList[index].tags.find((tag) => {
            return tagItem.id == tag.id
        });

        console.log('item', item);

        return !!item;
    }

    return (
        <Box>
            {inputList.map((x, i) => {
                    return (
                        <>
                            <Card className={classes.card}>
                                <CardContent className={classes.content}>
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} lg={6}>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="name"
                                                label="Nazwa oferty"
                                                name="name"
                                                autoComplete="name"
                                                autoFocus
                                                value={x.name}
                                                onChange={e => handleInputChange(e, i)}
                                                error={hasError('menu_items.' + i + '.name', errors)}
                                                helperText={
                                                    hasError('menu_items.' + i + '.name', errors) ? errors['menu_items.' + i + '.name'][0] : null
                                                }
                                            />
                                        </Grid>

                                        {category && (category.slug === 'fotowoltaika' || category.slug === 'wiatraki' || category.slug === 'pompy-ciepla') && (
                                            <Grid item xs={12} lg={3}>
                                                <TextField
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0.01, max: 100
                                                        }
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    id="power"
                                                    label="Moc zestawu"
                                                    name="power"
                                                    value={x.power}
                                                    onChange={e => handleInputChange(e, i)}
                                                    error={hasError('menu_items.' + i + '.power', errors)}
                                                    helperText={
                                                        hasError('menu_items.' + i + '.power', errors) ? errors['menu_items.' + i + '.power'][0] : null
                                                    }
                                                />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} lg={3}>
                                            <TextField
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0.01, max: 500000
                                                    }
                                                }}
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="price"
                                                label="Cena zestawu"
                                                name="price"
                                                value={x.price}
                                                onChange={e => handleInputChange(e, i)}
                                                error={hasError('menu_items.' + i + '.price', errors)}
                                                helperText={
                                                    hasError('menu_items.' + i + '.price', errors) ? errors['menu_items.' + i + '.price'][0] : null
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="description"
                                                label="Opis"
                                                multiline={true}
                                                name="description"
                                                autoComplete="description"
                                                rows="2"
                                                autoFocus
                                                value={x.description}
                                                onChange={e => handleInputChange(e, i)}
                                                error={hasError('menu_items.' + i + '.description', errors)}
                                                helperText={
                                                    hasError('menu_items.' + i + '.description', errors) ? errors['menu_items.' + i + '.description'][0] : null
                                                }
                                            />
                                        </Grid>

                                        {category && category.slug === 'fotowoltaika' && (
                                            <Grid item xs={12} lg={3}>
                                                <TextField
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0, max: 100
                                                        }
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    id="elements"
                                                    label="Ilość paneli"
                                                    name="elements"
                                                    value={x.elements}
                                                    onChange={e => handleInputChange(e, i)}
                                                    error={hasError('menu_items.' + i + '.elements', errors)}
                                                    helperText={
                                                        hasError('menu_items.' + i + '.elements', errors) ? errors['menu_items.' + i + '.elements'][0] : null
                                                    }
                                                />
                                            </Grid>
                                        )}

                                        {category && category.slug === 'fotowoltaika' && (
                                        <Grid item xs={12} lg={3}>
                                            <TextField
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0, max: 50
                                                    }
                                                }}
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="inverter_phase_count"
                                                label="Inwerter fazy (ilość)"
                                                name="inverter_phase_count"
                                                value={x.inverter_phase_count}
                                                onChange={e => handleInputChange(e, i)}
                                                error={hasError('menu_items.' + i + '.inverter_phase_count', errors)}
                                                helperText={
                                                    hasError('menu_items.' + i + '.inverter_phase_count', errors) ? errors['menu_items.' + i + '.inverter_phase_count'][0] : null
                                                }
                                            />
                                        </Grid>
                                        )}

                                        {category && category.slug === 'fotowoltaika' && (
                                            <Grid item xs={12} lg={3}>
                                                <TextField
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0, max: 50
                                                        }
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    id="inverter_mppt_count"
                                                    label="Inwerter MPPT (ilość)"
                                                    name="inverter_mppt_count"
                                                    value={x.inverter_mppt_count}
                                                    onChange={e => handleInputChange(e, i)}
                                                    error={hasError('menu_items.' + i + '.inverter_mppt_count', errors)}
                                                    helperText={
                                                        hasError('menu_items.' + i + '.inverter_mppt_count', errors) ? errors['menu_items.' + i + '.inverter_mppt_count'][0] : null
                                                    }
                                                />
                                            </Grid>
                                        )}

                                        <Box>
                                            <h1 className={classes.h1}>Tagi</h1>
                                            <p>Określ, w jakich kategoriach będzie widoczna Twoja oferta.</p>

                                        </Box>

                                        <Grid item xs={12} lg={12}>
                                            {category && getTags(category.id).map(tagItem => {
                                                return (
                                                    <Box>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={tagSelected(tagItem, i) ?? 'checked'}
                                                                               onChange={(event) => handleTagChange(getTags(category.id), event, i)} name="tags" value={parseInt(tagItem.id)}/>}
                                                            label={tagItem.name}
                                                        />
                                                    </Box>
                                                );
                                            })}
                                        </Grid>

                                        <Grid item xs={12} lg={12}>
                                            <FileUploader
                                                name="image"
                                                accept={'.peg,.png,.jpg,.gif'}
                                                type="file"
                                                image={x.image && x.image !== '' ? x.image : x.image_filename_url}
                                                blob={x.image && x.image !== '' ? true : false}
                                                title={'Wgraj zdjęcie'}
                                                onChange={e => handleInputFileChange(e, i)}/>
                                        </Grid>

                                        <Grid item xs={12} lg={12} className={classes.buttons}>
                                            <Box display={"flex"} justifyContent={"right"} width={"100%"}>
                                            {inputList.length !== 1 &&
                                                <Button className={classes.b1} size="small" variant="contained" onClick={() => handleRemoveClick(i)} startIcon={<DeleteOutlineIcon fontSize={"small"}/>}> Usuń ofertę</Button>
                                            }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Grid item xs={12} lg={12} className={classes.buttons}>
                                <Box display={"flex"} justifyContent={"right"} width={"100%"}>
                                    {inputList.length - 1 === i &&
                                        <Button className={classes.b1} size="small" variant="outlined" onClick={handleAddClick} startIcon={<AddIcon size={"small"} />}> Dodaj kolejną</Button>
                                    }
                                </Box>
                            </Grid>

                        </>
                    )
                }
            )}

        </Box>
    );
}

export default MenuItemFields;
