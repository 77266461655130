import React, {useState} from "react";
import CatalogListItem from "./CatalogListItem";
import ajaxClient from "../../services/ajaxClient";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {Paper} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import DayOpeningHours from "../DayOpeningHours";
import GeoStaticMap from "../GeoStaticMap";
import CatalogAddress from "../CatalogAddress";
import SendMessage from "../SendMessage";
import {COLORS, FONTS} from "../../Theme";
import CardMedia from "@material-ui/core/CardMedia";
import {assetsPath} from "../../services/mainService";
import Button from "@material-ui/core/Button";
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme) => ({
    root: {},
    miniCart: {
        /*        position: 'sticky',
                top: 90*/
    },
    order__title: {
        textAlign: 'start',
        font: 'normal normal bold 24px/41px ' + FONTS.fontFamily,
        color: COLORS.secondary,
    },
    card: {
        borderRadius: 6,
        marginBottom: theme.spacing(2),
        background: '#F2F4F7 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
    },
    cover: {
        height: 100,
        [theme.breakpoints.down('md')]: {
            width: "100%",
            maxHeight: 141,
            height: 141,
        },
        [theme.breakpoints.up('md')]: {
            width: 120,
            maxHeight: 120,
            height: 120,
        },
    },
    button: {
        minWidth: "367px"
    },
    img: {
        marginLeft: "50%"
    },
    title: {
        fontSize: "14px",
        color: "#101828",
        fontWeight: 500
    },
    p: {
        fontSize: "16px",
        color: "#667085"
    },
    t1: {
        color: COLORS.primary,
        fontSize: "24px",
        fontWeight: "700",
        marginTop: 12
    },
    span: {
        color: COLORS.default,
        fontSize: "16px",
        fontWeight: "400"
    },
    regulamin: {
        textAlign: 'center',
        marginTop: 16,
        fontSize: 16
    }

}));

function CatalogPremiumPhonePrice({catalog, showDescription = false}) {
    const classes = useStyles();

    if (!catalog && !catalog.premium_phone) {
        return <div>Ładowanie...</div>
    }

    return (
        <Box mt={2}>

            {showDescription && (
                <>
                    <Typography align="left" className={classes.p} component={"p"}>
                        {catalog.premium_phone.description}
                    </Typography>
                    <br/>
                </>
            )}
            <Typography align="left" className={classes.title} component={"p"}>
                Koszt porady telefonicznej:
            </Typography>
            
            <Grid container justifyContent={"space-between"}>
                <Grid className={classes.number} item xs={12} sm={6}>
                    <Typography className={classes.t1}>
                        {catalog.premium_phone.price} zł <span className={classes.span}> {catalog.premium_phone.price_info}</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} class={classes.regulamin}>
                    <Link className={classes.link} href="/regulamin">regulamin</Link>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CatalogPremiumPhonePrice;
